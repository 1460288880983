import { Button } from '@mui/material';
import { AddButtonProps } from './AddButtonProps';
import './AddButton.scss';

/**
 *
 * @todo: change css to scss and use colors styles
 */

const AddButton = (props: AddButtonProps) => {
  const onCLick = () => {
    if (props.parameter) {
      if (props.onClickHandleWithParameter) {
        props.onClickHandleWithParameter(props.parameter);
      }
    } else {
      if (props.onClickHandle) {
        props.onClickHandle();
      }
    }
  };

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={onCLick}
      size={props.size ? props.size : "medium"}
      fullWidth={props.fullWidth ? true : undefined}>
      <>
        {props.icon}
        {props.text}
      </>
    </Button>
  );
};

export default AddButton;


import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from "@mui/material";
import TextField from '@mui/material/TextField';
import { ChangeEvent, ReactElement } from 'react';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmationDialogProps } from './ConfirmationDialogProps';

export default function ConfirmationDialog ({ open, handleClose, handleConfirm, title, content, actionButtonName, width, hasInputField, inputFieldName, handleChange, disableConfirmButton }: ConfirmationDialogProps) {
   function onChange(e: ChangeEvent<HTMLInputElement>){
     if(handleChange){
       handleChange(e)
     }
   }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{color:"white !important"}}
                PaperProps={{
                    style: {
                      background: "white",
                      width: width
                    }
                  }}
            >
                <DialogTitle fontFamily={"Poppins"} id="alert-dialog-title">
                    {title}
                    {handleClose ? (
                    <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                        {content}
                  {hasInputField && <TextField variant='outlined' color = 'primary' label = {inputFieldName} onChange={onChange} fullWidth></TextField>}
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="contained" color="secondary" className={"modalCloseButton baseFontFamily"} onClick={handleClose}>Close</Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm} disabled={disableConfirmButton}>
                        {actionButtonName}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from '../../../components/AddButton/AddButton';
import ClientFilterData from '../../../components/ClientFilter/ClientFilterData';
import { TableState } from '../../../components/Table/interface/TableState';
import { ClientDto } from '../../../models/clients/ClientDto';
import { ClientFilter } from '../../../models/clients/ClientFilter';
import { ClientType } from '../../../models/clients/constants/ClientType';
import { ClientService } from '../../../repository/ClientService';
import './InviteClients.scss';
import ClientProcedureLinkDetailsModal from '../ClientProcedureLinkDetailsModal/ClientProcedureLinkDetailsModal';
import InviteClientModal from '../InviteClientModal/InviteClientModal';
import InviteClientsTable from '../InviteClientsTable/InviteClientsTable';

const InviteClients: React.FC = () => {

  const [clientsList, setClientsList] = useState<ClientDto[]>([]);
  const [isShowInviteClientModal, setIsShowInviteClientModal] = useState<boolean>(false);
  const [isShowProcedureDetailsLinkModal, setIsShowProcedureDetailsLinkModal] = useState<boolean>(false);
  const [selectedClientForDetails, setSelectedClientForDetails] = useState<ClientDto>();
  const { t } = useTranslation();
  const [tableState, setTableState] = useState<TableState>({ page: 0, size: 5, totalElement: 0 });
  const [clientFilterData, setClientFilterData] = useState<ClientFilter>({
    clientName: '',
    clientLastName: '',
    email: '',
    phoneNumber: '',
    clientType: ClientType.PRIMARY,
    kycProcedureType: ''
  });

  useEffect(() => {
    listAllClients();
  }, []);

  useEffect(() => {
    listAllClients();
  }, [tableState.size, tableState.page, clientFilterData]);

  const handleShowInviteClientModal = () => {
    setIsShowInviteClientModal(true);
  };

  const handleCloseModal = () => {
    setIsShowInviteClientModal(false);
  };

  const handleShowProcedureDetailsLinkModal = () => {
    setIsShowProcedureDetailsLinkModal(true);
  };

  const handleCloseProcedureDetailsLinkModal = () => {
    setIsShowProcedureDetailsLinkModal(false);
  };

  const handleSelectClientForDetails = (clientId: string | null) => {
    ClientService.getClientById(clientId!).then((client) => {
      setSelectedClientForDetails(client);
      handleShowProcedureDetailsLinkModal();
    });
  };

  const handleUserFilter = (
    clientName: string | undefined,
    clientLastName: string | undefined,
    email: string | undefined,
    phoneNumber: string | undefined,
    status: string | undefined,
    blocked: boolean | undefined,
    clientType: string,
    kycProcedureType: string | undefined
  ): void => {
    setClientFilterData({
      clientName,
      clientLastName,
      email,
      phoneNumber,
      status,
      blocked,
      clientType,
      kycProcedureType
    });
  };

  const listAllClients = () => {
    ClientService.listClients(clientFilterData, tableState.page, tableState.size)
      .then((data) => {
        setClientsList(data.content);
        setTableState({
          ...tableState,
          totalElement: data.totalElements
        });
      })
      .catch(() => {
      });
  };

  return (
    <Box className='tableContainer'>
      <Box className={'add-button-wrapper'}>
        <AddButton
          onClickHandle={handleShowInviteClientModal}
          text={t('INVITE_NEW_CLIENT')}
          icon={<AddIcon className='buttonIcon'/>}/>
      </Box>
      <ClientFilterData onFilterSubmit={handleUserFilter} status={clientFilterData.status}
                        blocked={clientFilterData.blocked} clientType={ClientType.PRIMARY}/>
      <InviteClientsTable listAllClients={listAllClients}
                          tableState={tableState}
                          setTableState={setTableState}
                          clientsList={clientsList}
                          handleSelectClientForDetails={handleSelectClientForDetails}/>
      <InviteClientModal handleCloseModal={handleCloseModal}
                         listAllClients={listAllClients}
                         clientsList={clientsList}
                         isShowModal={isShowInviteClientModal}
                         handleShowProcedureDetailsLinkModal={handleShowProcedureDetailsLinkModal}
                         setSelectedClientForDetails={setSelectedClientForDetails}/>
      <ClientProcedureLinkDetailsModal handleCloseModal={handleCloseProcedureDetailsLinkModal}
                                       isShowModal={isShowProcedureDetailsLinkModal}
                                       selectedClient={selectedClientForDetails}/>
    </Box>
  );
};
export default InviteClients;
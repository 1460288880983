import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Switch, TableBody, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SwalAlert from '../SwalAlert/SwalAlert';

export function PageTableBody(props: any) {
  const navigate = useNavigate();
  const [showAllItems, setShowAllItems] = React.useState(true);
  const { t } = useTranslation();

  function handleOpen(id: number) {
    props.handleOpen(id);
  }

  function handleShowAllItems(id: number) {
    //  showAllItems === id ? setShowAllItems("") : setShowAllItems(id);
  }

  const checkIfNotNullAndNavigate = (link: string) => {
    if (!link.includes('null')) {
      navigate(link);
    } else {
      SwalAlert.infoAlert(t('NO_DATA_FOR_ENTITY'), t('KYC_PROCEDURE_NOT_FILLED_YET'));
    }
  };
  return (
    <TableBody style={{ borderRadius: '20px' }}>
      {props?.rows?.length > 0 ? (
        props.rows?.map((row: any, index: number) => {
          return (
            <TableRow
              hover
              tabIndex={-1}
              key={index}
              style={
                index % 2
                  ? { background: 'white' }
                  : { background: 'secondary' }
              }
            >
              {row.values?.map((value: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    {value.type === 'text' && (
                      <TableCell
                        className='table-color tableCell'
                        component='th'
                        scope='row'
                        align='center'
                        onClick={() => (row.link ? checkIfNotNullAndNavigate(row.link) : props.handleOpen ? props.handleOpen(row.id) : null)}
                      >
                        {value.value}
                      </TableCell>
                    )}
                    {value.type === 'boolean' && (
                      <TableCell
                        className='tableCell'
                        component='th'
                        scope='row'
                        align='center'
                        onClick={() => (row.link ? checkIfNotNullAndNavigate(row.link) : props.handleOpen ? props.handleOpen(row.id) : null)}
                      >
                        {value.value ? (
                          <CheckCircleIcon className='success-color'/>
                        ) : (
                          <CancelIcon className={'decline-color'}/>
                        )}
                      </TableCell>
                    )}
                    {value.type === 'date' && (
                      <TableCell
                        className='table-color tableCell'
                        component='th'
                        scope='row'
                        align='center'
                        onClick={() => (row.link ? checkIfNotNullAndNavigate(row.link) : props.handleOpen ? props.handleOpen(row.id) : null)}
                      >
                        {value.value}
                      </TableCell>
                    )}
                    {value.type === 'list' && (
                      <TableCell
                        className='tableCell'
                        component='th'
                        scope='row'
                        align='center'
                        onClick={() => handleShowAllItems(row.id)}
                      >
                        <ol>
                          {value.value?.map((item: any, index: number) => {
                            return <li key={index}>{item}</li>;
                          })}
                        </ol>
                      </TableCell>
                    )}

                    {value.type === 'button' && (
                      <TableCell className='tableCell' align={value.align} component='th' scope='row'>
                        {value.buttons?.map((but: any) => {
                          return (
                            <Button
                              key={but.id}
                              variant='contained'
                              color={but.color || 'primary'}
                              onClick={() => but.fun(row.id)}
                              className='mr-20'
                              aria-label='button'
                            >
                              {but.value}
                            </Button>
                          );
                        })}
                      </TableCell>
                    )}
                    {value.type === 'switch' && (
                      <TableCell className='tableCell'>
                        {value.buttons.map((but: any) => {
                          return (
                            <>
                              {value.value + ' '}

                              <Switch
                                color='success'
                                checked={but.checked}
                                onChange={() => but.fun(row.id)}
                              />
                            </>
                          );
                        })}
                      </TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow content='center'>
          <TableCell
            className='emptyListCell'
            colSpan={props.headerRows.length}
            content='center'
            align='center'
          >
            <i>{t('EMPTY_LIST')}</i>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default PageTableBody;

import { Box } from '@mui/material';
import React from 'react';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import PageTable from '../../../components/Table/PageTable';
import KYCProcedure from '../../../models/procedure/KYCProcedure';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';
import { getProcedureStatusColor } from '../../../utils/ProcedureColorsUtils';
import { KYCProcedureHistoryTableProps } from './KYCProcedureHistoryTableProps';
import { useTranslation } from 'react-i18next';

const KYCProcedureHistoryTable = (props: KYCProcedureHistoryTableProps) => {
  const { t } = useTranslation();

  const headerRows = [
    {
      id: 'status',
      label: t('STATUS'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'validFrom',
      label: t('VALID_FROM'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'validTo',
      label: t('VALID_TO'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'dateCreated',
      label: t('DATE_CREATED'),
      search: true,
      type: 'text'
    },
    {
      id: 'dateModified',
      label: t('DATE_MODIFIED'),
      search: true,
      type: 'text'
    }
  ];

  const rows = props.allKYCProcedure.map((element: KYCProcedure) => {
    return {
      id: element.procedureId,
      link: `/kyc-procedure/details/${element.procedureId}`,
      values: [
        {
          type: 'text',
          name: 'status',
          value: (
            <LightTooltip className={'tableTooltip'} title={element.procedureStatus.label}>
              <Box component={'span'}
                   color={getProcedureStatusColor(element.procedureStatus.label)}><b>{element.procedureStatus.label}</b></Box>
            </LightTooltip>
          )
        },
        {
          type: 'date',
          name: 'validFrom',
          value: (
            <LightTooltip title={element?.validFrom}>
              <span>{DateTimeUtils.formatDate(element.validFrom)}</span>
            </LightTooltip>
          )
        },
        {
          type: 'date',
          name: 'validTo',
          value: (
            <LightTooltip title={element?.validTo}>
              <span>{DateTimeUtils.formatDate(element.validTo)}</span>
            </LightTooltip>
          )
        },
        {
          type: 'date',
          name: 'dateCreated',
          value: (
            <LightTooltip title={element?.dateCreated}>
              <span>{DateTimeUtils.formatDate(element?.dateCreated)}</span>
            </LightTooltip>
          )
        },
        {
          type: 'date',
          name: 'dateModified',
          value: (
            <LightTooltip title={element?.dateModified}>
              <span>{DateTimeUtils.formatDate(element.dateModified)}</span>
            </LightTooltip>
          )
        }
      ]
    };
  });
  return <PageTable headerRows={headerRows} rows={rows} clickable={true}/>;
};
export default KYCProcedureHistoryTable;

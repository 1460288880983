import { createSlice,  PayloadAction  } from '@reduxjs/toolkit';
import ClientQuestionnairesDTO from '../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import { Question } from './interfaces/Questions';
import { QuestionListProps } from './interfaces/QuestionsListProps';

const initialState: QuestionListProps = {
  questionsList: [],
};

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    addQuestion(state, action: PayloadAction<ClientQuestionnairesDTO[]>) {
      state.questionsList = action.payload;
    },
    editQuestion(state, action: PayloadAction<ClientQuestionnairesDTO>) {
      const index = state.questionsList.findIndex(question => question.amlQuestionDTO.id === action.payload.amlQuestionDTO.id);
      if (index !== -1) {
        state.questionsList[index] = action.payload;
      }
    },
  },
});

export const { addQuestion, editQuestion } = questionSlice.actions;
export default questionSlice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import { TableState } from '../../../components/Table/interface/TableState';
import PageTable from '../../../components/Table/PageTable';
import { ChangeRequestResponse } from '../../../models/changeRequests/ChangeRequestResponse';
import {DateTimeUtils} from "../../../utils/DateTimeUtils";

const ChangeRequestsTable = ({
   changeRequestsList, tableState, setTableState
}: { changeRequestsList: ChangeRequestResponse[],tableState: TableState, setTableState: (state: TableState) => void }) => {
  const { t } = useTranslation();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setTableState({
      ...tableState,
      page: page
    });
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      size: parseInt(event.target.value)
    });
  };

  const headerRows = [
    {
      id: 'changeRequestTypeLabel',
      label: t('CHANGE_REQUEST_TYPE'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'createdBy',
      label: t('CREATED_BY'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'dateCreated',
      label: t('DATE_CREATED'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'approvedBy',
      label: t('APPROVED_BY'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'rejectedBy',
      label: t('REJECTED_BY'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
  ];

  const rows = changeRequestsList.map((element) => {
    return {
      id: element.id,
      link: `/change-request-details/${element.id}`,
      values: [
        {
          type: 'text',
          name: 'changeRequestTypeLabel',
          value: (
            <LightTooltip title={element?.changeRequestTypeLabel}>
              <span>{element.changeRequestTypeLabel}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'createdBy',
          value: (
            <LightTooltip title={element?.changeRequestType}>
                <span>{element.createdBy.firstName} {element.createdBy.lastName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'dateCreated',
          value: (
              <LightTooltip title={DateTimeUtils.formatDateTime(element?.dateCreated)}>
                <span>{DateTimeUtils.formatDateTime(element?.dateCreated)}</span>
              </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'approvedBy',
          value: (
            <LightTooltip title={element?.approvedBy}>
              <span>{element.approvedBy}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'rejectedBy',
          value: (
            <LightTooltip title={element?.rejectedBy}>
              <span>{element.rejectedBy}</span>
            </LightTooltip>
          )
        },
      ]
    }
  });

  return (
    <PageTable
      headerRows={headerRows}
      rows={rows}
      page={tableState.page}
      totalElements={tableState.totalElement}
      rowsPerPage={tableState.size}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangePageSize}
      clickable={true}
    />
  );
};
export default ChangeRequestsTable;
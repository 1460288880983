import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Switch, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm} from "react-hook-form";
import { BootstrapDialog } from "shared/components/BootstrapDialog/BootstrapDialog";
import BootstrapDialogTitle from "shared/components/BootstrapDialog/BootstrapDialogTitle";
import NotMultipleSelectChip from "../../../components/NotMultipleSelectChip/NotMultipleSelectChip";
import AMLQuestionDTO from "../../../models/clientQuestionnaires/AMLQuestionDTO";
import FileQuestionModel from "../../../models/clientQuestionnaires/AMLQuestionFileDTO";
import AMLQuestionFileDTO from "../../../models/clientQuestionnaires/AMLQuestionFileDTO";
import ClientQuestionnairesDTO from '../../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import QuestionAnswer from "../../../models/clientQuestionnaires/QuestionAnswer";
import QuestionsType from "../../../models/clientQuestionnaires/QuestionsType";
import QuestionsService from "../../../repository/QuestionsService";
import { Transition } from "../../../shared/components/BootstrapDialog/BootstrapDialog";
import FileQuestionComponent from "./FileQuestion";
import PossibleAnswer from "./PossibleAnswer";
import { QuestionModalProps } from "./QuestionModalProps";
import { useTranslation } from 'react-i18next';

const QuestionModal = (props: QuestionModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<AMLQuestionDTO>();
  const { t } = useTranslation();
  const [questionTypesList, setQuestionTypeList] = useState<QuestionsType[]>([]);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState<string>();
  const [possibleAnswerList, setPossibleAnswerList] = useState<QuestionAnswer[]>([]);
  const [fileQuestionList, setFileQuestionList] = useState<AMLQuestionFileDTO[]>([]);
  const [checkActive, setCheckActive] = useState<boolean>(true);
  const [checkUseCountry, setCheckUseCountry] = useState<boolean>(true);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (props.showModal) {
      getAllQuestionType();
      if (props.selectedQuestionForEdit) {
        const active = props.selectedQuestionForEdit.amlQuestionDTO.active ?? false;
        const useCountryList = props.selectedQuestionForEdit.amlQuestionDTO.useCountryList ?? false;

        setValue("id", props.selectedQuestionForEdit.amlQuestionDTO.id);
        setValue("title", props.selectedQuestionForEdit.amlQuestionDTO.title);
        setValue("description", props.selectedQuestionForEdit.amlQuestionDTO.description);
        setValue("active", active);
        setValue("useCountryList", useCountryList);

        setSelectedQuestionTypes(props.selectedQuestionForEdit.amlQuestionDTO.amlQuestionType || "MULTIPLE_CHOICE");
        setCheckActive(active);
        setCheckUseCountry(useCountryList);

        if (props.selectedQuestionForEdit!.amlQuestionFileDtos) {
          setFileQuestionList((prevState) => {
            return [...props.selectedQuestionForEdit!.amlQuestionFileDtos];
          });
        }
        setPossibleAnswerList([...props.selectedQuestionForEdit.nomenclatureEntryDtoList]);
      } else {
        setCheckActive(false);
        setCheckUseCountry(false);
        setPossibleAnswerList([new QuestionAnswer({
          id: undefined,
          name: "",
          description: "",
          value: "",
          deleted: false,
          riskScore: undefined
        })]);
        setFileQuestionList([new FileQuestionModel({
          id: undefined,
          title: "",
          description: "",
          deleted: false,
        })]);
      }
    } else {
      setCheckActive(false);
      setCheckUseCountry(false);
      setFileQuestionList((prevState) => {
        return [...[], ...[]];
      });
      setPossibleAnswerList((prevState) => {
        return [...[], ...[]];
      });
      reset();
    }
  }, [props.showModal]);

  const getAllQuestionType = () => {
    QuestionsService.getAllQuestionsType()
      .then((data) => {
        setQuestionTypeList(data.data);
      })
      .catch(() => {});
  };

  const handleChangeQuestionType = (selectedOptions: string) => {
    setSelectedQuestionTypes(selectedOptions);
    setIsFormValid(true);

    if (selectedOptions === "TEXT" || selectedOptions === "NUMBER" ) {
      let updatedAnswerList = [...possibleAnswerList];

      for (let i = 0; i < updatedAnswerList.length; i++) {
        const answer = updatedAnswerList[i];
        if (answer.id === undefined) {
          updatedAnswerList.splice(i, 1);
          i--;
        } else {
          updatedAnswerList[i] = { ...answer, deleted: true };
        }
      }
      setPossibleAnswerList(updatedAnswerList);
      validateForm();
      setPossibleAnswerList(prevList => [
        ...prevList,
        new QuestionAnswer({
          id: undefined,
          name: "",
          description: "",
          value: "",
          deleted: false,
          riskScore: undefined,
        }),
      ]);
    }
  };

  const handleAddPossibleAnswer = () => {
    setPossibleAnswerList((prevState: QuestionAnswer[]) => {
      const newList = [
        ...prevState,
        new QuestionAnswer({
          id: undefined,
          name: "",
          description: "",
          value: "",
          deleted: false,
          riskScore: undefined,
        }),
      ];
      setIsFormValid(false);
      return newList;
    });
  };

  const handleAddFileQuestion = () => {
    setFileQuestionList((prevState: AMLQuestionFileDTO[]) => {
      const newList = [
        ...prevState,
        new AMLQuestionFileDTO({
          id: undefined,
          title: "",
          description: "",
          deleted: false
        }),
      ];
      setIsFormValid(false);
      return newList;
    });
  };

  const validateForm = () => {
    let areAnswersValid = true;
    if(selectedQuestionTypes !== "TEXT" && selectedQuestionTypes !== "NUMBER")
    {
      areAnswersValid = possibleAnswerList.every(answer => answer.name.trim() !== '');
    }
    const areFilesValid = fileQuestionList.every(file => file.title.trim() !== '');
    setIsFormValid(areAnswersValid && areFilesValid);
  };

  const handleChangeQuestion = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let tempArray = [];
    for (let i = 0; i < possibleAnswerList.length; i++) {
      if (i === index) {
        let selectedQuestion: any = possibleAnswerList.at(index);
        if (e.target.name == "name") {
          tempArray.push({ ...selectedQuestion, name: e.target.value });
        } else if(e.target.name =="description"){
          tempArray.push({ ...selectedQuestion, description: e.target.value });
        }
        else if (e.target.name === "riskScore") {
          tempArray.push({ ...selectedQuestion, riskScore: Number(e.target.value) });
        }
      } else {
        tempArray.push(possibleAnswerList[i]);
      }
    }
    setPossibleAnswerList(tempArray);
    validateForm();
  };

  const handleChangeFileQuestion = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let tempArray = [];
    for (let i = 0; i < fileQuestionList.length; i++) {
      if (i === index) {
        let selectedFileQuestion: any = fileQuestionList.at(index);
        if (e.target.name === "title") {
          tempArray.push({ ...selectedFileQuestion, title: e.target.value });
        } else {
          tempArray.push({
            ...selectedFileQuestion,
            description: e.target.value,
          });
        }
      } else {
        tempArray.push(fileQuestionList[i]);
      }
    }
    setFileQuestionList(tempArray);
    validateForm();
  };

  const handleRemoveAnswer = (index: number) => {
    const newAnswerList = [...possibleAnswerList];
    if (newAnswerList[index].id === undefined) {
      newAnswerList.splice(index, 1);
      setPossibleAnswerList(newAnswerList);
      validateForm();
      return newAnswerList;
    } else {
      newAnswerList[index].deleted = true;
      setPossibleAnswerList(newAnswerList);
      validateForm();
      return newAnswerList;
    }
  };

  const handleRemoveFileQuestion = (index: number) => {
    const newFileQuestionList = [...fileQuestionList];
    if(newFileQuestionList[index].id === undefined)
    {
      newFileQuestionList.splice(index, 1);
      setFileQuestionList(newFileQuestionList);
      validateForm();
      return newFileQuestionList;
    }
    else{
      newFileQuestionList[index].deleted = true;
      setFileQuestionList(newFileQuestionList);
      validateForm();
      return newFileQuestionList;
    }
  };

  const onChangeActive = (e: any) => {
    setValue("active", e.target.checked!);
    setIsFormValid(true);
    validateForm();
    setCheckActive(e.target.checked);
  };

  const onChangeUseCountry = (e: any) => {
    setValue("useCountryList", e.target.checked!);
    setIsFormValid(true);
    validateForm();
    setCheckUseCountry(e.target.checked);
  };

  const onFormSubmit: SubmitHandler<AMLQuestionDTO> = (data: AMLQuestionDTO) => {
    setIsFormValid(false);
    const newQuestion = {
      amlQuestionDTO: {
        ...data,
        amlQuestionType: selectedQuestionTypes,
      },
      amlQuestionFileDtos: fileQuestionList,
      nomenclatureEntryDtoList: possibleAnswerList,
    };

    if (props.selectedQuestionForEdit) {
      const updatedQuestion = {
        ...props.selectedQuestionForEdit,
        ...newQuestion
      };
      editQuestion(updatedQuestion);
      console.log(updatedQuestion);
    } else {
      createQuestion(newQuestion);
    }
  };

  const createQuestion = (newQuestion: any) => {
    props.handleAddQuestion(newQuestion);
    props.handleCloseModal();
  };

  const editQuestion = (updatedQuestion: ClientQuestionnairesDTO) => {
    props.handleEditQuestion(updatedQuestion);
    props.handleCloseModal();
  };


  return (
    <div>
      <BootstrapDialog
        style={{ fontFamily: "Poppins" }}
        onClose={props.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={props.showModal}
        className="modalWrapper"
        TransitionComponent={Transition}
        maxWidth={"md"}
        fullWidth
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.handleCloseModal}
          >
            {t('CREATE_NEW_QUESTION')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3} padding={2}>
              <Grid item xs={6}>
                <TextField
                  id="questionTitle"
                  label={t('TITLE')}
                  variant="standard"
                  {...register("title", { required: true })}
                  fullWidth={true}
                  color={'primary'}
                />
              </Grid>
              <Grid item xs={6}>
                <NotMultipleSelectChip
                  options={questionTypesList}
                  selectedOptions={selectedQuestionTypes}
                  label={t('TYPE')}
                  handleChangeSelect={handleChangeQuestionType}
                  color={'primary'}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="questionDescription"
                  label={t('DESCRIPTION')}
                  variant="standard"
                  {...register("description", { required: false })}
                  fullWidth={true}
                  color={'primary'}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ marginRight: "10px", color: "gray" }}>{t('ACTIVE')}:</Box>
                <Switch
                  name="active"
                  color={'primary'}
                  onChange={(event) => onChangeActive(event)}
                  checked={checkActive}
                />
              </Grid>
              {(selectedQuestionTypes == "MULTIPLE_CHOICE")  && (
                <Grid item xs={4} sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{marginRight: "10px", color: "gray"}}>
              {t('USE_COUNTRY_LIST')}:
                </Box>
                <Switch
                name="useCountryList"
                color={'primary'}
                onChange={(event) => onChangeUseCountry(event)}
                checked={checkUseCountry}
                />
                </Grid>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
                width={"100%"}
              >
                <Typography sx={{ marginLeft: "24px" }} variant={"h6"}>
                  {t('POSSIBLE_ANSWERS')}
                </Typography>

                {(selectedQuestionTypes !== "TEXT" && selectedQuestionTypes !== "NUMBER")  && (
                  <AddIcon
                    sx={{
                      marginLeft: "8px",
                      backgroundColor: "#0d9d8d",
                      color: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={handleAddPossibleAnswer}
                  />
                )}
              </Box>
              {possibleAnswerList.map((e, index) => {
                return (
                  <PossibleAnswer
                    key={index}
                    id={index}
                    handleRemoveAnswer={handleRemoveAnswer}
                    handleChangeQuestion={handleChangeQuestion}
                    possibleAnswer={possibleAnswerList.at(index)}
                    selectedQuestionType={selectedQuestionTypes}
                  />
                );
              })}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
                width={"100%"}
              >
                <Typography sx={{ marginLeft: "24px" }} variant={"h6"}>
                  {t('FILE')}
                </Typography>

                <AddIcon
                  sx={{
                    marginLeft: "8px",
                    backgroundColor: "#0d9d8d",
                    color: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={handleAddFileQuestion}
                />
              </Box>
              {fileQuestionList.map((e, index) => {
                return (
                  <FileQuestionComponent
                    key={index}
                    id={index}
                    handleRemoveAnswer={handleRemoveFileQuestion}
                    handleChangeFileQuestion={handleChangeFileQuestion}
                    fileQuestion={fileQuestionList.at(index)}
                  />
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="inherit"
              className={"modalCloseButton baseFontFamily"}
              onClick={props.handleCloseModal}
            >
              {t('CLOSE')}
            </Button>
            {!props.selectedQuestionForEdit ? (
              <Button
                type="submit"
                variant="contained"
                color="success"
                className={"modalSuccesButton baseFontFamily"}
                disabled={!isFormValid}
              >
                {t("CREATE_QUESTION")}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="success"
                className={"modalSuccesButton baseFontFamily"}
                disabled={!isFormValid}
              >
                {t('EDIT_QUESTION')}
              </Button>
            )}
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};
export default QuestionModal;

import axios from 'axios';
import { AUTH_TOKEN } from '../shared/utility';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_ENV,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (!config.url?.includes('public') && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error', error);
    if (error.response.status === 401) {
      window.location.href = '/logout';
    }
    return Promise.reject(error);
  }
);
export default instance;
import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import MultipleSelectChip from "../MultipleSelectChip/MultipleSelectChip";
import RolesService from "../../repository/RolesService";
import { UserRoleDto } from "../../models/userroles/roles/UserRoleDto";
import { UserFilterData } from "../../models/userroles/users/UserFilterData";
import { SubmitHandler, useForm } from "react-hook-form";
import "./UserFilter.scss";
import FilterButton from "../FilterButton/FilterButton";
import { UserFilterProps } from "./UserFilterProps";
import { useTranslation } from 'react-i18next';

/**
 *
 * @todo: change css to scss and use colors styles
 */

const UserFilter = (props: UserFilterProps) => {
  const [rolesList, setRolesList] = useState<UserRoleDto[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<UserFilterData>();

  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = () => {
    RolesService.getAllRoles().then((data) => {
      setRolesList(data.data);
    });
  };

  const handleChangeRoles = (selectedOptions: number[]) => {
    setSelectedRoles(selectedOptions);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
    }
  };

  const onSubmit: SubmitHandler<UserFilterData> = (
    data: UserFilterData
  ): void => {
    data.role_id = selectedRoles;
    props.onFilterSubmit(data.firstName, data.lastName, data.email, data.role_id);
  };

  return (
    <div className='filter-card'>
      <form className='w-100' onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          display={'flex'}
          justifyContent={'space-around'}>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            justifyContent={'center'}
            marginBottom={'auto'}
            marginTop={'auto'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_EMAIL')}
                color='primary'
                focused
                onKeyPress={handleKeyPress}
                {...register('email', { required: false })}
                size='small'/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={2} marginBottom={'auto'} marginTop={'auto'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_FIRST_NAME')}
                color='primary'
                focused
                onKeyPress={handleKeyPress}
                {...register('firstName', { required: false })}
                size='small'/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={2} marginBottom={'auto'} marginTop={'auto'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_LAST_NAME')}
                color='primary'
                focused
                onKeyPress={handleKeyPress}
                {...register('lastName', { required: false })}
                size='small'/>
            </div>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            marginTop={'auto'}
            display={'flex'}
            marginBottom={'auto'}
            justifyContent={'center'}>
            <MultipleSelectChip
              customWidth={'250px'}
              handdleChangeSelect={handleChangeRoles}
              titleName={t('ROLE')}
              focused={true}
              required={false}
              elements={rolesList}
              onKeyPress={handleKeyPress}
              customMinWith={'250px'}
              size='small'
              selectedValues={selectedRoles}/>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            justifyContent={'center'}
            display={'flex'}
            alignContent={'center'}>
            <FilterButton/>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default UserFilter;

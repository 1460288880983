export default class AMLQuestionFileDTO {
    id: undefined;
    title:string;
    description:string;
    deleted: boolean;
    constructor(json: AMLQuestionFileDTO ) {
        this.id = json.id
        this.title = json.title;
        this.description = json.description;
        this.deleted= json.deleted
    }
}
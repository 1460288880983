import { createSlice, Draft } from '@reduxjs/toolkit';
import { TableState } from './interfaces/TableState';

const initialState: TableState = {
  tableId: ''
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    reloadTable: (state: Draft<TableState>, action) => {
      state.tableId = `${action.payload}[${Math.random()}]`;
    }
  }
});

export const { reloadTable } = tableSlice.actions;
export default tableSlice.reducer;
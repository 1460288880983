import { Box, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClientFilterData from '../../../components/ClientFilter/ClientFilterData';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import PageTable from '../../../components/Table/PageTable';
import { ClientDto } from '../../../models/clients/ClientDto';
import { useTranslation } from 'react-i18next';
import { ClientFilter } from '../../../models/clients/ClientFilter';
import { ClientType } from '../../../models/clients/constants/ClientType';
import { ClientService } from '../../../repository/ClientService';

const AllocateTokens = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(200);
  const [clientsList, setClientsList] = useState<ClientDto[]>([]);
  const { t } = useTranslation();

  const [clientFilterData, setClientFilterData] = useState<ClientFilter>({
    clientName: '',
    email: '',
    phoneNumber: '',
    status: undefined,
    blocked: false,
    clientType: ClientType.PRIMARY,
    kycProcedureType: ''
  });

  const handleUserFilter = (
    clientName: string | undefined,
    clientLastName: string | undefined,
    email: string | undefined,
    phoneNumber: string | undefined,
    status: string | undefined,
    blocked: boolean | undefined,
    clientType: string,
    kycProcedureType: string | undefined
  ): void => {
    setClientFilterData({
      clientName,
      clientLastName,
      email,
      phoneNumber,
      status,
      blocked,
      clientType,
      kycProcedureType
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const getClassesForColorForProcedureStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'procedure-pending';
      case 'ACCEPTED':
        return 'procedure-accepted';
      case 'DECLINED':
        return 'procedure-rejected';
      default:
        return '';
    }
  };

  const headerRows = [
    {
      id: 'email',
      label: t('EMAIL'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'firstName',
      label: t('FIRST_NAME'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'lastName',
      label: t('LAST_NAME'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'phoneNumber',
      label: t('PHONE_NUMBER'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'kycStatus',
      label: t('KYC_STATUS'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'amlStatus',
      label: t('AML_STATUS'),
      search: true,
      type: 'text'
    },
    {
      id: 'clientType',
      label: t('KYC_PROCEDURE_TYPE'),
      search: true,
      type: 'text'
    }
  ];

  const rows = clientsList?.map((element) => {
    return {
      id: element.id,
      link: `/ico-token-details/${element.id}`,
      values: [
        {
          type: 'text',
          name: 'Email',
          value: (
            <LightTooltip title={element?.email}>
              <span>{element.email}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'First name',
          value: (
            <LightTooltip title={element?.firstName}>
              <span>{element.firstName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Last name',
          value: (
            <LightTooltip title={element?.lastName}>
              <span>{element.lastName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Phone number',
          value: (
            <LightTooltip title={element?.phoneNumber}>
              <span>{element.phoneNumber}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'KYC Status',
          value: (
            <LightTooltip title={element?.KYCStatus}>
              <span className={`${getClassesForColorForProcedureStatus(element?.KYCStatus!)}`}>{element.KYCStatus}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'AML Status',
          value: (
            <LightTooltip title={element?.AMLStatus}>
              <span className={`${getClassesForColorForProcedureStatus(element?.AMLStatus!)}`}>{element.AMLStatus}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Client type',
          value: (
            <LightTooltip title={element?.kycProcedureType}>
              <span>{element?.kycProcedureType}</span>
            </LightTooltip>
          )
        }
      ]
    };
  });

  useEffect(() => {
    listAllClients();
  }, [clientFilterData, page, rowsPerPage]);

  function listAllClients() {
    ClientService.listClients(clientFilterData, page, rowsPerPage)
      .then((data) => {
        setClientsList(data.content);
        setTotalElements(data.totalElements);
      })
      .catch(() => {
      });
  }

  return (
    <>

      <Box className={'terms-content'}>
        <div>
          <ClientFilterData onFilterSubmit={handleUserFilter} status={clientFilterData.status} blocked={clientFilterData.blocked}
                            clientType={ClientType.PRIMARY}/>
          <div className='tableContainer'>
            <PageTable
              headerRows={headerRows}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              clickable={true}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default AllocateTokens;
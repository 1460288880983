import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './config/i18n/en.json';

const resources = {
  en: { translation: en }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i']
    }
  });

export default i18n;

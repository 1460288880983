import { UserDto } from '../userroles/users/UserDto';
import { ChangeRequestApproversDto } from './ChangeRequestApproversDto';
import { ChangeRequestApproversGroupDto } from './ChangeRequestApproversGroupDto';

export class ChangeRequestResponse {
  id: number;
  changeRequestType: string;
  changeRequestTypeLabel: string;
  createdBy: UserDto;
  approvedBy: number;
  rejectedBy: number;
  notAnswered: number;
  changedFields: string;
  hasVoted: boolean;
  approvers: ChangeRequestApproversDto[];
  decliners: ChangeRequestApproversDto[];
  changeRequestApproversForRole: ChangeRequestApproversGroupDto[];
  changeRequestDeclinersForRole: ChangeRequestApproversGroupDto[];
  requiredRequestApprovers: ChangeRequestApproversGroupDto[];
  dateCreated: string;


  constructor(json: ChangeRequestResponse) {
    this.id = json.id;
    this.changeRequestType = json.changeRequestType;
    this.changeRequestTypeLabel = json.changeRequestTypeLabel;
    this.createdBy = json.createdBy;
    this.approvedBy = json.approvedBy;
    this.rejectedBy = json.rejectedBy;
    this.notAnswered = json.notAnswered;
    this.changedFields = json.changedFields;
    this.hasVoted = json.hasVoted;
    this.approvers = json.approvers;
    this.decliners = json.decliners;
    this.changeRequestApproversForRole = json.changeRequestApproversForRole;
    this.changeRequestDeclinersForRole = json.changeRequestDeclinersForRole;
    this.requiredRequestApprovers = json.requiredRequestApprovers;
    this.dateCreated = json.dateCreated;
  }
}
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Permission } from '../auth/Permission';
import { TermsAndConditionsComponent } from '../components/TermsAndConditions/TermsAndConditionsComponent';
import Sidebar from '../layouts/Sidebar/Sidebar';
import { ClientType } from '../models/clients/constants/ClientType';
import AccessDenied from '../pages/AccessDenied/AccessDenied';

import { AMLProcedure } from '../pages/AMLProcedure/AMLProcedure';
import ChangeRequestDetails from '../pages/ChangeRequests/ChangeRequestDetailsPage/ChangeRequestDetails';
import ChangeRequests from '../pages/ChangeRequests/ChangeRequestPage/ChangeRequests';
import ClientQuestionnaires from '../pages/ClientQuestionaires/ClientQuestionnaires';
import ClientMlroConversation from '../pages/Clients/active-procedure/client-conversation/ClientMlroConversation';
import AllocateTokens from '../pages/Clients/allocate-tokens/AllocateTokens';
import IcoTokenDetails from '../pages/Clients/allocate-tokens/IcoTokenDetails';
import ClientDetailsPage from '../pages/Clients/ClientDetailsPage';
import Clients from '../pages/Clients/Clients';
import { ProcedureType } from '../pages/Clients/enums/ProcedureType';
import ProcedureDetailsPage from '../pages/Clients/history-procedure/ProcedureDetailsPage';
import Countries from '../pages/Countries/Countries';
import Home from '../pages/Home/Home';
import InviteClients from '../pages/InviteClients/InviteClientsPage/InviteClients';
import LandingPage from '../pages/LandingPage/LandingPage';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import NotificationList from '../pages/Notification/NotificationList';
import Profile from '../pages/Profile/Profile';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import SetPassword from '../pages/SetPassword/SetPassword';
import SuccessfulDocumentUpload from '../pages/SuccessfulDocumentUpload/SuccessfulDocumentUpload';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import SystemApprovers from '../pages/Users/SystemApprovers';
import Users from '../pages/Users/Users';
import PdfViewer from '../shared/components/Pdf-Viewer/PdfViewer';
import ScrollOnTop from '../utils/ScrollOnTop';
import ProtectedRoutes from './ProtectedRoute';
import ChangePassword from '../pages/Profile/ChangePassword/ChangePassword';
import AMLQuestionnairesEditDraft from '../pages/ClientQuestionaires/AMLQuestionnaires/AMLQuestionnairesEditDraft';
import React from 'react';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';

const RoutesComponents = () => {
  return (
    <BrowserRouter>
      <ScrollOnTop/>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/logout' element={<Logout/>}/>
        <Route path='/setPassword' element={<SetPassword/>}/>
        <Route path='/forgot-password' element={<ForgotPassword/>}/>
        <Route path='/reset-password' element={<ResetPassword/>}/>
        <Route path='landing/:token' element={<LandingPage/>}/>
        <Route path='/terms-conditions' element={<TermsAndConditionsComponent/>}/>
        <Route path='/accessDenied' element={<AccessDenied/>}/>
        <Route path='aml/:token' element={<AMLProcedure/>}/>
        <Route path='/view-pdf/:fileName/view' element={<PdfViewer/>}/>
        <Route path='procedure-communication' element={<ClientMlroConversation/>}/>
        <Route path='/document-upload-success' element={<SuccessfulDocumentUpload/>}/>
        <Route element={<ProtectedRoutes/>}>
          <Route path='/' element={<Sidebar><Home/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_USERS}/>}>
          <Route path='/users' element={<Sidebar><Users/></Sidebar>}/>
        </Route><Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_COUNTRIES}/>}>
        <Route path='/countries' element={<Sidebar><Countries/></Sidebar>}/>
      </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_TERMS_AND_CONDITIONS}/>}>
          <Route path='/terms' element={<Sidebar><TermsAndConditions/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CLIENTS}/>}>
          <Route path='/clients' element={<Sidebar><Clients clientType={ClientType.PRIMARY}/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CLIENTS}/>}>
          <Route path='/clients-shareholders' element={<Sidebar><Clients clientType={ClientType.SECONDARY}/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_SYSTEM_APPROVERS}/>}>
          <Route path='/system-approvers' element={<Sidebar><SystemApprovers/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_PROFILE}/>}>
          <Route path='/profile' element={<Sidebar><Profile/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CLIENT_QUESTIONNAIRES}/>}>
          <Route path='/client-questionnaires' element={<Sidebar><ClientQuestionnaires/></Sidebar>}/>
        </Route>
        {/*     <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CLIENT_QUESTIONNAIRES}/>}>
          <Route path='/client-questionnaires/:id' element={<Sidebar><CopyQuestionnairesGroup/></Sidebar>}/>
        </Route> */}
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CLIENT_QUESTIONNAIRES}/>}>
          <Route path='/client-questionnaires/:id' element={<Sidebar><AMLQuestionnairesEditDraft/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CLIENTS}/>}>
          <Route path='/clients/:id' element={<Sidebar><ClientDetailsPage/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_KYC_PROCEDURES}/>}>
          <Route path='/kyc-procedure/details/:id' element={<Sidebar><ProcedureDetailsPage key='kyc'
                                                                                           procedureType={ProcedureType.KYC_PROCEDURE}/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_AML_PROCEDURES}/>}>
          <Route path='/aml-procedure/details/:id' element={<Sidebar><ProcedureDetailsPage key='aml'
                                                                                           procedureType={ProcedureType.AML_PROCEDURE}/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_NOTIFICATIONS}/>}>
          <Route path='/notifications' element={<Sidebar><NotificationList/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_ALLOCATE_TOKENS}/>}>
          <Route path='/allocate-tokens' element={<Sidebar><AllocateTokens/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_ALLOCATE_TOKENS}/>}>
          <Route path='/ico-token-details/:id' element={<Sidebar><IcoTokenDetails/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_INVITE_CLIENTS}/>}>
          <Route path='/invite-clients' element={<Sidebar><InviteClients/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CHANGE_REQUESTS}/>}>
          <Route path='/change-requests' element={<Sidebar><ChangeRequests/></Sidebar>}/>
        </Route>
        <Route element={<ProtectedRoutes permission={Permission.FE_CAN_ACCESS_CHANGE_REQUESTS}/>}>
          <Route path='/change-request-details/:id' element={<Sidebar><ChangeRequestDetails/></Sidebar>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RoutesComponents;

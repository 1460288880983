import { Grid, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProcedureTypeDto } from '../../models/procedure/ProcedureTypeDto';
import { ProcedureTypeService } from '../../repository/ProcedureTypeService';
// import "./css/UserFilter.scss";
import FilterButton from '../FilterButton/FilterButton';
import { ClientFilter } from '../../models/clients/ClientFilter';
import { ClientFilterDataProps } from './ClientFilterDataProps';
import { useTranslation } from 'react-i18next';
import './ClientFilterData.scss';

/**
 *
 * @todo: change css to scss and use colors styles
 */

const ClientFilterData = (props: ClientFilterDataProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<ClientFilter>();
  const { t } = useTranslation();
  const handleKeyPress = (event: React.KeyboardEvent) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
    }
  };
  const [procedureTypes, setProcedureTypes] = useState<ProcedureTypeDto[]>();

  const onSubmit: SubmitHandler<ClientFilter> = (data: ClientFilter): void => {
    props.onFilterSubmit(data.clientName, data.clientLastName, data.email, data.phoneNumber, props.status, props.blocked!, props.clientType,
      data.kycProcedureType);
  };
  useEffect(() => {
    ProcedureTypeService.allProcedureTypes().then((res) => {
      setProcedureTypes(res);
    });
    reset();
  }, [props.status, props.blocked]);
  return (
    <div className='filter-card'>
      <form className='w-100' onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          display={'flex'}
          justifyContent={'space-around'}>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            justifyContent={'center'}
            marginBottom={'auto'}
            marginTop={'auto'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_EMAIL')}
                color='primary'
                focused
                fullWidth
                onKeyPress={handleKeyPress}
                {...register('email', { required: false })}
                size='small'/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={2} marginBottom={'auto'} marginTop={'auto'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_FIRST_NAME')}
                color='primary'
                focused
                fullWidth
                onKeyPress={handleKeyPress}
                {...register('clientName', { required: false })}
                size='small'/>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={2} marginBottom={'auto'} marginTop={'auto'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_LAST_NAME')}
                color='primary'
                focused
                fullWidth
                onKeyPress={handleKeyPress}
                {...register('clientLastName', { required: false })}
                size='small'
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            marginTop={'auto'}
            marginBottom={'auto'}
            justifyContent={'center'}>
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <TextField
                label={t('SEARCH_PHONE_NUMBER')}
                color='primary'
                focused
                fullWidth
                onKeyPress={handleKeyPress}
                {...register('phoneNumber', { required: false })}
                size='small'
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            marginTop={'auto'}
            display={'flex'}
            marginBottom={'auto'}
            justifyContent={'center'}>
            <FormControl
              fullWidth size={'small'}
              className='kyc-type-form-control'>
              <InputLabel className='kyc-type-label'>
                {t('CHOOSE_KYC_TYPE')}
              </InputLabel>
              <Select
                className='kyc-type-select'
                color={'primary'}
                label={t('CHOOSE_KYC_TYPE')}
                defaultValue=''
                inputProps={register('kycProcedureType', {
                  required: false
                })}
              >
                {procedureTypes?.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6} lg={6} xl={2}
            justifyContent={'center'}
            display={'flex'}
            alignContent={'center'}>
            <FilterButton/>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default ClientFilterData;
import { Box, Checkbox, Radio, TextField } from '@mui/material';
import React from 'react';
import { QuestionAnswerProps } from './QuestionAnswerProps';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const QuestionAnswerComponents = (props: QuestionAnswerProps) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box>
        {props.questionType === 'MULTIPLE_CHOICE' &&
          <Checkbox {...label} color='secondary' checked={false} disabled={true}/>}

        {props.questionType === 'SINGLE_CHOICE' && <Radio disabled={true} color='secondary'/>}
        {(props.questionType !== 'SINGLE_CHOICE' && props.questionType !== 'MULTIPLE_CHOICE') || props.questionType === undefined &&
          <TextField disabled={true} color={'secondary'} label='Outlined' variant='outlined'/>}
      </Box>
      <Box alignItems={'centar'}>{props.answer.name}</Box>
    </Box>
  );
};
export default QuestionAnswerComponents;

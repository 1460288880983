import { SxProps } from '@mui/system';
import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTable from '../../../components/Table/PageTable';
import { ChangeRequestDetailsResponse } from '../../../models/changeRequests/ChangeRequestDetailsResponse';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const UpdateCountryRiskExcelChangeRequestDetails = ({
  changeRequestDetails,
  changedFields
}: { changeRequestDetails: ChangeRequestDetailsResponse | undefined; changedFields: any }) => {
  const { t } = useTranslation();
  const [showAllFromRows, setShowAllFromRows] = useState(false);
  const [showAllToRows, setShowAllToRows] = useState(false);
  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)'
  };
  const headers = [
    { id: 'country', label: t('Country') },
    { id: 'score', label: t('Score') },
  ];
  let fromRows = Object.values(changedFields).map((field: any) => ({
    id: field.countryCode,
    values:[
      { type: 'text', name: 'country', value: field.country },
      { type: 'text', name: 'score', value: field.oldScore || "0" }
    ]
  }));
  let toRows = Object.values(changedFields).map((field: any) => ({
    id: field.countryCode,
    values:[
      { type: 'text', name: 'country', value: field.country },
      { type: 'text', name: 'score', value: field.newScore }
    ]
  }));

  if (!showAllFromRows) {
    fromRows = fromRows.slice(0, 10);
  }

  if (!showAllToRows) {
    toRows = toRows.slice(0, 10);
  }

  const handleShowMoreFrom = () => {
    setShowAllFromRows(!showAllFromRows);
  };

  const handleShowMoreTo = () => {
    setShowAllToRows(!showAllToRows);
  };
  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes From</Typography>
            <PageTable headerRows={headers}
                       rows={fromRows}
                       clickable={false} />
            {Object.keys(changedFields).length > 10 && (
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleShowMoreFrom} color="primary" variant="outlined" sx={{ mt: 2 }}>
                  {showAllFromRows ? t('Show Less') : t('Show More')}
                  {showAllFromRows ? <ArrowDropDownIcon sx={{ fontSize: "18px", ml: 1 }} /> : <ArrowRightIcon sx={{ fontSize: "18px", ml: 1 }} />}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes To</Typography>
            <PageTable
              headerRows={headers}
              rows={toRows}
              clickable={false}/>
            {Object.keys(changedFields).length > 10 && (
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleShowMoreTo} color="primary" variant="outlined" sx={{ mt: 2 }}>
                  {showAllToRows ? t('Show Less') : t('Show More')}
                  {showAllToRows ? <ArrowDropDownIcon sx={{ fontSize: "18px", ml: 1 }} /> : <ArrowRightIcon sx={{ fontSize: "18px", ml: 1 }} />}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UpdateCountryRiskExcelChangeRequestDetails;
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BadgeIcon from '@mui/icons-material/Badge';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FlagIcon from '@mui/icons-material/Flag';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TokenIcon from '@mui/icons-material/Token';
import { Box, CssBaseline, Drawer, IconButton, styled, Toolbar, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Permission } from '../../auth/Permission';
import AccountMenu from '../../components/AccountMenu/AccountMenu';
import NotificationPopper from '../../pages/Notification/NotificationPopper';
import { AuthUtils } from '../../utils/AuthUtils';
import { MenuItem } from './MenuItem';
import './Sidebar.scss';

const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

interface SidebarProps {
  children: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const location = useLocation();
  const { t } = useTranslation();

  const handleOpenMenu = (index: number) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index);
      setOpenMenu(true);
    } else {
      setSelectedIndex(undefined);
      setOpenMenu(!openMenu);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
   @todo: sidebar list items should be in a list and get displayed by mapping the list dynamically.
   Create an object that describes a list item and implement a list of those objects to
   store the sidebar items. The list can be stored statically here and get filtered based on the user
   roles on sidebar load
   **/

  const currentPath = location.pathname;

  const pathToPageMap: { [key: string]: string } = {
    '/': 'Dashboard',
    '/clients': 'Clients onboarding',
    '/clients-shareholders': 'Clients shareholders onboarding',
    '/users': 'Staff roles',
    '/system-approvers': 'System Approvers',
    '/countries': 'Countries',
    '/terms': 'Terms and conditions',
    '/client-questionnaires': 'Client Questionnaires',
    '/notifications': 'Notifications',
    '/invite-clients': 'Invite clients',
    '/change-requests': 'Change Staff roles',
    '/allocate-tokens': 'Allocate tokens'
  };

  const activePage = pathToPageMap[currentPath] || 'Dashboard';

  const sideBarItems: MenuItem[] = [
    {
      id: 1,
      path: '/',
      icon: <DashboardIcon className='sidebar-icon' fontSize='large'/>,
      label: t('DASHBOARD'),
      permission: Permission.FE_CAN_ACCESS_DASHBOARD
    },
    {
      id: 2,
      path: '/clients',
      icon: <BadgeIcon className='sidebar-icon' fontSize='large'/>,
      label: t('CLIENTS'),
      permission: Permission.FE_CAN_ACCESS_CLIENTS
    },
    {
      id: 3,
      path: '/clients-shareholders',
      icon: <BadgeIcon className='sidebar-icon' fontSize='large'/>,
      label: t('CLIENTS_SHAREHOLDERS'),
      permission: Permission.FE_CAN_ACCESS_CLIENTS
    },
    {
      id: 4,
      path: '/invite-clients',
      icon: <GroupAddIcon className='sidebar-icon' fontSize='large'/>,
      label: t('INVITE_CLIENT'),
      permission: Permission.FE_CAN_ACCESS_INVITE_CLIENTS
    },
    {
      id: 5,
      path: '',
      icon: <AdminPanelSettingsIcon className='sidebar-icon' fontSize='large'/>,
      label: t('ADMINISTRATION'),
      permission: Permission.FE_CAN_ACCESS_ADMINISTRATION,
      subItems: [
        {
          path: '/users',
          icon: <GroupIcon className='sidebar-icon' fontSize='medium'/>,
          label: t('USERS'),
          permission: Permission.FE_CAN_ACCESS_USERS
        },
        // {
        //   path: '/system-approvers',
        //   icon: <ManageAccountsIcon className='sidebar-icon' fontSize='medium'/>,
        //   label: 'System Approvers'
        // },
        {
          path: '/countries',
          icon: <FlagIcon className='sidebar-icon' fontSize='medium'/>,
          label: 'Countries',
          permission: Permission.FE_CAN_ACCESS_COUNTRIES
        },
        // {
        //   path: '/terms',
        //   icon: <ReceiptLongIcon className='sidebar-icon' fontSize='medium'/>,
        //   label: 'Terms And Conditions'
        // },
        {
          path: '/client-questionnaires',
          icon: <ListAltIcon className='sidebar-icon' fontSize='medium'/>,
          label: 'Client questionnaires',
          permission: Permission.FE_CAN_ACCESS_CLIENT_QUESTIONNAIRES
        }
      ]
    },
    {
      id: 6,
      path: '/notifications',
      icon: <NotificationsIcon className='sidebar-icon' fontSize='large'/>,
      label: t('NOTIFICATIONS'),
      permission: Permission.FE_CAN_ACCESS_NOTIFICATIONS
    },
    {
      id: 7,
      path: '/allocate-tokens',
      icon: <TokenIcon className='sidebar-icon' fontSize='large'/>,
      label: t('ALLOCATE_TOKENS'),
      permission: Permission.FE_CAN_ACCESS_ALLOCATE_TOKENS
    },
    {
      id: 8,
      path: '/change-requests',
      icon: <ChangeCircleOutlinedIcon className='sidebar-icon' fontSize='large'/>,
      label: t('CHANGE_REQUESTS'),
      permission: Permission.FE_CAN_ACCESS_CHANGE_REQUESTS
    }
  ];

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline/>
        <AppBar position='fixed' open={open} className='navigationbar'>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}>
              <MenuIcon/>
            </IconButton>
            <Typography variant='h6' noWrap component='div'>
              {activePage}
            </Typography>
            <Box className={'notification-icon-link'}>
              <NotificationPopper/>
            </Box>
            <AccountMenu/>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant='persistent'
          anchor='left'
          open={open}
          className='navigation-drawer'>
          <DrawerHeader className='sidebar-header'>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon className='sidebar-header-icon'/>
              ) : (
                <ChevronRightIcon className='sidebar-header-icon'/>
              )}
            </IconButton>
          </DrawerHeader>
          <Divider/>
          <List className='sideabar-list'>
            {sideBarItems.map((item, index: number) => {
              return (
                AuthUtils.hasPermission(item.permission) ?
                  (<div key={index}>
                    <ListItem disablePadding key={index} onClick={() => item.subItems && handleOpenMenu(item.id!)}>
                      <Link to={item.path} className='sidebar-link'>
                        <ListItemButton selected={location.pathname === item.path}>
                          <ListItemIcon>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText>{item.label}</ListItemText>
                          {item.subItems && selectedIndex !== item.id && (
                            <KeyboardArrowRightIcon
                              className='sidebar-icon'
                              fontSize='medium'/>
                          )}
                          {item.subItems && selectedIndex === item.id && (
                            <KeyboardArrowDownIcon
                              className='sidebar-icon'
                              fontSize='medium'/>
                          )}
                        </ListItemButton>
                      </Link>
                    </ListItem>
                    {openMenu && selectedIndex === item.id && (
                      <List>
                        {item?.subItems?.map((subItem: MenuItem, index: number) => (
                          AuthUtils.hasPermission(subItem.permission!) ?
                            <ListItem key={index}>
                              <Link to={subItem.path} className='sidebar-link'>
                                <ListItemButton selected={location.pathname === subItem.path}>
                                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                                  <ListItemText>{subItem.label}</ListItemText>
                                </ListItemButton>
                              </Link>
                            </ListItem> : <React.Fragment key={index}/>
                        ))}
                      </List>
                    )}
                  </div>) : <React.Fragment key={index}/>
              );
            })}
          </List>
        </Drawer>
        <Main open={open} style={{ height: '100vh' }}>
          <DrawerHeader/>
          {children}
        </Main>
      </Box>
    </>
  );
};
export default Sidebar;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import { TableState } from '../../../components/Table/interface/TableState';
import PageTable from '../../../components/Table/PageTable';
import { ClientDto } from '../../../models/clients/ClientDto';

const InviteClientsTable = ({
  listAllClients, clientsList, handleSelectClientForDetails, setTableState, tableState
}: { listAllClients: () => void, clientsList: ClientDto[], handleSelectClientForDetails: (clientId: string | null) => void, tableState: TableState, setTableState: (state: TableState) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirectToProcedureDetails = (id: number) => {
    navigate(`/clients/${id}`);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setTableState({
      ...tableState,
      page: page
    });
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      size: parseInt(event.target.value)
    });
  };

  const getClassesForColorForProcedureStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'procedure-pending';
      case 'ACCEPTED':
        return 'procedure-accepted';
      case 'DECLINED':
        return 'procedure-rejected';
      default:
        return '';
    }
  };

  const headerRows = [
    {
      id: 'email',
      label: t('EMAIL'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'firstName',
      label: t('FIRST_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'lastName',
      label: t('LAST_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'phoneNumber',
      label: t('PHONE_NUMBER'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'kycStatus',
      label: t('KYC_STATUS'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'amlStatus',
      label: t('AML_STATUS'),
      search: false,
      type: 'text'
    },
    {
      id: 'clientType',
      label: t('KYC_PROCEDURE_TYPE'),
      search: true,
      type: 'text'
    },
    {
      id: 'action',
      label: t('ACTION'),
      search: false,
      type: 'text'
    }
  ];

  const rows = clientsList.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: 'text',
          name: 'Email',
          value: (
            <LightTooltip title={element?.email}>
              <span>{element.email}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'First name',
          value: (
            <LightTooltip title={element?.firstName}>
              <span>{element.firstName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Last name',
          value: (
            <LightTooltip title={element?.lastName}>
              <span>{element.lastName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Phone number',
          value: (
            <LightTooltip title={element?.phoneNumber}>
              <span>{element.phoneNumber}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'KYC Status',
          value: (
            <LightTooltip title={element?.KYCStatus}>
              <span
                className={`${getClassesForColorForProcedureStatus(element?.KYCStatus!)}`}>{element.KYCStatus}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'AML Status',
          value: (
            <LightTooltip title={element?.AMLStatus}>
              <span
                className={`${getClassesForColorForProcedureStatus(element?.AMLStatus!)}`}>{element.AMLStatus}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Client type',
          value: (
            <LightTooltip title={element?.kycProcedureType}>
              <span>{element?.kycProcedureType}</span>
            </LightTooltip>
          )
        },
        {
          type: element?.KYCStatus ? 'button' : 'text',
          name: 'Action',
          align: 'center',
          value: (
            <LightTooltip title={element?.AMLStatus}>
              <span></span>
            </LightTooltip>
          ),
          buttons: [
            {
              id: '1',
              value: t('PROCEDURE_DETAILS'),
              fun: handleRedirectToProcedureDetails
            }
          ]
        }
      ]
    };
  });

  return (
    <PageTable
      headerRows={headerRows}
      rows={rows}
      page={tableState.page}
      totalElements={tableState.totalElement}
      rowsPerPage={tableState.size}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangePageSize}
      handleOpen={handleSelectClientForDetails}
      clickable={true}/>
  );
};
export default InviteClientsTable;
import { ProcedureStatusLabel } from '../models/clientQuestionnaires/ProcedureStatusLabel';
import { CountryRisk } from '../models/country/CountryRisk';

export function getProcedureStatusColor(status: string) {
  if (status) {
    if (status === ProcedureStatusLabel.NEW) {
      return '#0e387a';
    } else if (status === ProcedureStatusLabel.DECLINED) {
      return '#cc001a';
    } else if (status === ProcedureStatusLabel.PENDING) {
      return '#F28C28';
    } else if (status === ProcedureStatusLabel.ACCEPTED) {
      return 'green';
    } else if(status === ProcedureStatusLabel.AUTO_DECLINED.replace("_", " ")){
      return 'darkred';
    }
  }
  return 'black';
}

export function getProcedureCountryStatusColor(status: string | undefined) {
  if (status) {
    if (status === CountryRisk.PROHIBITED) {
      return 'purple';
    } else if (status === CountryRisk.HIGH_RISK) {
      return '#cc001a';
    } else if (status === CountryRisk.MEDIUM_RISK) {
      return '#F28C28';
    } else if (status === CountryRisk.LOW_RISK) {
      return 'green';
    }
  }
  return 'black';
}

export const isPendingProcedure = (status: string) => {
  return status === ProcedureStatusLabel.PENDING;
};

import AutorenewIcon from "@mui/icons-material/Autorenew";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as React from "react";
import "./FilterButton.scss";
import { useTranslation } from 'react-i18next';

/**
 *
 * @todo: change css to scss and use colors styles
 */
export default function IconLabelButtons() {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ marginTop: "auto", marginBottom: "auto" }}
    >
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        endIcon={<AutorenewIcon />}
      >
        {t('FILTER_RESULTS')}
      </Button>
    </Stack>
  );
}

import React, { useEffect, useState } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import FileDTO from '../../models/clientQuestionnaires/FileDTO';
import { FileUploader } from 'react-drag-drop-files';
import './FileUploadInput.scss';
import { useTranslation } from 'react-i18next';
import { FileConfigService } from '../../repository/FileConfigService';
import SwalAlert from '../SwalAlert/SwalAlert';

interface FileUploadInputProps {
  label: string;
  name?: string; // Name of the input for react-hook-form
  selectedFiles: File[] | FileDTO[];
  onFileChange: (files: File[], index: number) => void;
  index: number;
  register?: UseFormRegister<any>;
  watch?: UseFormWatch<any>;
  classes?: string;
  onSizeErrorModal?: (errorMessage: string) => void;
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({
  label,
  name,
  selectedFiles,
  onFileChange,
  index,
  classes,
  register,// Destructure the register function from props
  onSizeErrorModal
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [supportedTypes, setSupportedTypes] = useState<string[]>();

  const handleFileChange = (files: any) => {
    onFileChange(Array.from(files), index);
  };

  const popUpTypeError = () => {
    handleFileChange([]);
    SwalAlert.errorAlert(t('ERROR'), t('NOT_SUPPORTED_FILE'));
  }
  const fetchSupportedTypes = () => {
    FileConfigService.supportedTypes().then((res) => {
      setSupportedTypes(res);
    })
  }
  useEffect(() => {
    fetchSupportedTypes();
  }, [])

  return (
    <div className={classes ? classes : 'file-uploader'}>
      <div> {label}</div>
      <FileUploader
        handleChange={handleFileChange}
        multiple={true}
        width={'100%'}
        name='files'
        maxSize={10}
        onSizeError={onSizeErrorModal}
        types={supportedTypes}
        onTypeError={popUpTypeError}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
        dropMessageStyle={{ backgroundColor: 'primary' }}/>
      <div className='selected-files-list'>
        {selectedFiles?.length > 0 &&
          selectedFiles.map((e) => e.name).join(', ')}
      </div>
    </div>
  );
};

export default FileUploadInput;

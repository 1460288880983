export class CountryDto {
  countryId: number;
  countryName: string;
  continentName: string;
  disabled: boolean;
  countryRisk: string | undefined;
  countryRiskLabel: string | null;
  risk_score: number | null;

  constructor(json: CountryDto) {
    this.countryId = json.countryId;
    this.countryName = json.countryName;
    this.continentName = json.continentName;
    this.disabled = json.disabled;
    this.risk_score = json.risk_score;
    this.countryRisk=json.countryRisk;
    this.countryRiskLabel=json.countryRiskLabel;
  }
}

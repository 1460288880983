import React, { ChangeEvent } from "react";
import { TextField } from "@mui/material";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

interface FormTextFieldProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  rules?: RegisterOptions;
  variant?: "standard" | "outlined" | "filled";
  color?: "primary" | "secondary";
  fullWidth?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputTextField: React.FC<FormTextFieldProps> = ({
  label,
  name,
  register,
  rules,
  variant = "outlined",
  color = "primary",
  fullWidth = true,
  onChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };
  return (
    <TextField
      id={name}
      {...register(name, rules)}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      onChange={handleChange}
      InputLabelProps={{ shrink: false }}
    />
  );
};

export default InputTextField;

import axios from '../axios/axios';
import { NoteCreationRequest } from '../models/notes/NoteCreationRequest';
import { NoteDto } from '../models/notes/NoteDto';
import { NoteTopicDto } from '../models/notes/NoteTopicDto';

const NoteService = {
  allByKycProcedure: async (id: number): Promise<NoteDto[]> => {
    const response = await axios.get(`/note/all-by-kyc-procedure/` + id);
    return response.data;
  },
  allByAmlProcedure: async (id: number): Promise<NoteDto[]> => {
    const response = await axios.get(`/note/all-by-aml-procedure/` + id);
    return response.data;
  },
  create: async (noteCreationRequest: NoteCreationRequest) => {
    await axios.post('/note/create', noteCreationRequest);
  },
  noteTopics: async (): Promise<NoteTopicDto[]> => {
    const response = await axios.get('/note/note-topics');
    return response.data;
  }
};
export default NoteService;
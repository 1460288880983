import axios from "../axios/axios";

const CountryGroupService = {
    getAllCountryGroups: () => {
        return axios.get(
            "/countryGroup/list-all"
        );
    },
};
export default CountryGroupService;

import { Grid, Select, SelectChangeEvent, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { UserRoleDto } from '../../../models/userroles/roles/UserRoleDto';
import { UserData } from '../../../models/userroles/users/UserData';
import RolesService from '../../../repository/RolesService';
import UserService from '../../../repository/UserService';
import {
  BootstrapDialog,
  Transition
} from '../../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import '../Users.scss';
import { useTranslation } from 'react-i18next';
import { ErrorHandler } from '../../../utils/ErrorHandler';

const UserAddModal = (props: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UserData>();

  const [rolesList, setRolesList] = useState<UserRoleDto[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    if (props.open) {
      getAllRoles();
      if (props.selectedUserForEdit) {
        setValue('address', props.selectedUserForEdit.address);
        setValue('email', props.selectedUserForEdit.email);
        setValue('firstName', props.selectedUserForEdit.firstName);
        setValue('lastName', props.selectedUserForEdit.lastName);
        setValue('birthDate', props.selectedUserForEdit.birthDate);
        setValue('phoneNumber', props.selectedUserForEdit.phoneNumber);
        setValue('roleId', props.selectedUserForEdit.roleId);

        if (props.selectedUserForEdit.userRoles) {
          setSelectedRole(props.selectedUserForEdit.userRoles[0].id); //TODO: To be changed in future
          setValue('roleId', props.selectedUserForEdit.userRoles[0].id);
        }

      }
    } else {
      resetFormData();
    }
  }, [props.open]);

  const getAllRoles = () => {
    RolesService.getAllRoles().then((data) => {
      setRolesList(data.data);
    });
  };

  const handleChangeRole = (event: SelectChangeEvent) => {
    setSelectedRole(event.target.value);
  };

  const resetFormData = () => {
    reset();
    setSelectedRole(undefined);
  };

  const addUser = (data: UserData): void => {
    UserService.addUser(data)
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
        props.getAllUserFiltred();
        props.handleClose();
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t(errorMessage));
      });
  };

  const editUser = (id: number, data: UserData): void => {
    UserService.editUser(id, data)
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
        props.getAllUserFiltred();
        props.handleClose();
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  const onSubmit: SubmitHandler<UserData> = (data: UserData): void => {
    if (props.selectedUserForEdit) {
      editUser(props.selectedUserForEdit.id, data);
    } else {
      addUser(data);
    }
  };

  const renderSelectRole = () => {
    return (
      <FormControl fullWidth>
        <InputLabel>{t('ROLES')}</InputLabel>
        <Select
          id='roleId'
          {...register('roleId', { required: true })}
          value={selectedRole}
          label={t('ROLES')}
          onChange={handleChangeRole}
        >
          {
            rolesList.map((e, i) => {
              return (
                <MenuItem key={i} value={e.id}>{e.label}</MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    );
  };

  return (
    <div>
      <BootstrapDialog
        style={{ fontFamily: 'Poppins' }}
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        className='modalWrapper'
        TransitionComponent={Transition}
        maxWidth={'md'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id='customized-dialog-title'
            onClose={props.handleClose}
          >
            {props.selectedUserForEdit ? t('EDIT_USER') : t('CREATE_NEW_USER')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4} padding={2}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6} pr={1}>
                    <TextField
                      id='firstName'
                      color={'primary'}
                      label={t('FIRST_NAME')}
                      {...register('firstName', { required: true })}
                      variant='standard'
                      fullWidth={true}
                    />
                    {errors.firstName && <span>{t('REQUIRED_FIELD')}</span>}
                  </Grid>
                  <Grid item xs={6} pl={1}>
                    <TextField
                      id='lastName'
                      color={'primary'}
                      label={t('LAST_NAME')}
                      {...register('lastName', { required: true })}
                      variant='standard'
                      fullWidth={true}
                    />
                    {errors.lastName && <span>{t('REQUIRED_FIELD')}</span>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='email'
                  color={'primary'}
                  label={t('EMAIL')}
                  type='email'
                  {...register('email', { required: true })}
                  variant='standard'
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='address'
                  color={'primary'}
                  label={t('ADDRESS')}
                  {...register('address', { required: true })}
                  variant='standard'
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='phoneNumber'
                  color={'primary'}
                  label={t('PHONE_NUMBER')}
                  {...register('phoneNumber', { required: true })}
                  variant='standard'
                  fullWidth={true}
                  onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.phoneNumber && <span>{t('PHONE_NUMBER_ERROR')}</span>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='birthDate'
                  color={'primary'}
                  label={t('BIRTH_DATE')}
                  {...register('birthDate', { required: true })}
                  type='date'
                  variant='standard'
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                {props.selectedUserForEdit ? (selectedRole && renderSelectRole()) : renderSelectRole()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='inherit'
              className={'modalCloseButton baseFontFamily'}
              onClick={props.handleClose}
            >
              {t('CLOSE')}
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              {props.selectedUserForEdit ? t('EDIT_USER') : t('CREATE_NEW_USER')}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};
export default UserAddModal;

import React from 'react'
import accessDeniedPhoto from "../../assets/images/access_denied.png"
import {Box, Typography} from "@mui/material";
import "./AccessDenied.scss";
import {useNavigate} from "react-router-dom";
import AddButton from "../../components/AddButton/AddButton";
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return(
        <Box className="access_denied_back_container"  >
            <img src={accessDeniedPhoto} className={'access_denied_background_img'} alt=''/>
            <Box sx={{textAlign:'center', justifyContent:'center'}} >
                <Typography  variant={"h2"}  sx={{width:'100%'}}><strong className={'access_denied_header'}> {t('WE_ARE_SORRY')}</strong></Typography> <br/>
                <Box className={'access_denied_text_info'} sx={{marginBottom:'15px'}}>
                    {t('RESTRICTED_ACCESS_PAGE')} <br/>
                    {t('REFER_TO_ADMIN')}
                </Box>
                <AddButton onClickHandle={() => navigate(-2  )} text={t('Back')}/>
            </Box>
        </Box>
    )
}
export default AccessDenied;

import { Grid } from "@mui/material";
import "./AMLProcedureFooterContent.scss";

type FooterProps = {
  nextButton?: React.ReactNode;
  prevButton?: React.ReactNode;
};
export function AMLProcedureFooterContent(props: FooterProps) {
  return (
    <Grid className="footer-content">
      {props.prevButton && (
        <div className="prev-button">{props.prevButton}</div>
      )}
      {props.nextButton && (
        <div className="next-button">{props.nextButton}</div>
      )}
    </Grid>
  );
}

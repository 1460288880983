import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileDTO } from '../../../models/aml/FileDTO';
import { AMLProcedure } from '../../../models/procedure/AMLProcedure';
import KYCProcedure from '../../../models/procedure/KYCProcedure';
import { ShareholderDto } from '../../../models/procedure/ShareholderDto';
import { ProcedureService } from '../../../repository/ProcedureService';
import ShareholderService from '../../../repository/ShareholderService';
import AMLProcedureView from '../active-procedure/AMLProcedureView';
import KYCProcedureView from '../active-procedure/KYCProcedureView';
import PDFViewer from '../active-procedure/PDFViewer';
import { GbgFilesType } from '../enums/GbgFilesType';
import { ProcedureType } from '../enums/ProcedureType';
import { useTranslation } from 'react-i18next';

const ProcedureDetailsPage = (props: { procedureType: ProcedureType }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [amlProcedure, setAMlProcedure] = useState<AMLProcedure>();
  const [kycProcedure, setKYCProcedure] = useState<KYCProcedure>();
  const { t } = useTranslation();
  const [isKycProcedureView, setIsKycProcedureView] = useState<boolean>(false);
  const [fileForReview, setFileForReview] = useState<FileDTO>();
  const bottomRef = useRef<HTMLDivElement>(null);
  const base64Text = 'data:image/*;base64';
  const [fileForGbgProcedure, setFileFormGbgProcedure] = useState<FileDTO[]>();
  const [shareholders, setShareholders] = useState<ShareholderDto[]>([]);

  useEffect(() => {
    if (props.procedureType === ProcedureType.AML_PROCEDURE) {
      if (params.id) {
        ProcedureService.getAMLProcedureById(params.id).then((data) => setAMlProcedure(data));
      }
    } else {
      if (params.id) {
        ProcedureService.getKYCProcedureById(params.id).then((data) => {
          setKYCProcedure(data);
          ShareholderService.allShareholdersByKycProcedure(data.procedureId).then((res) => {
            setShareholders(res);
          });
        });
        setIsKycProcedureView(true);
        getGbgDocuments(parseInt(params.id));
      }
    }
  }, [params]);

  useEffect(() => {
    if (fileForReview) {
      if (window.innerWidth >= 1200) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [fileForReview]);

  const handleChangeSelectedFileForEdit = (file: FileDTO) => {
    setFileForReview(file);
  };

  const getGbgDocuments = (id: number) => {
    ProcedureService.getGbgDocuments(id).then((data: FileDTO[]) => setFileFormGbgProcedure(data));
  };

  return (
    <Grid container>
      <Grid item width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%' }}>
        <Typography variant='h4' sx={{ paddingBottom: 2 }}>{amlProcedure ? amlProcedure.clientName : (kycProcedure?.clientName ?? '')}</Typography>
        <Card>
          <CardContent>
            {amlProcedure &&
              <>
                <Box display={'flex'} alignItems={'center'} marginBottom={2} padding={0}>
                  <Button color={'secondary'}
                          onClick={() => navigate(`/clients/${amlProcedure.clientId}`, { state: { isHistory: true } })}>
                    <ArrowBackIcon/>
                  </Button>
                  <Typography variant='h5'><b>{t('AML_PROCEDURE')}</b></Typography>
                </Box>
                <AMLProcedureView amlProcedure={amlProcedure} handleChangeSelectedFileForEdit={handleChangeSelectedFileForEdit}/>
              </>}
            {kycProcedure &&
              <>
                <Grid container>
                  <Grid item xs={10} sm={12} md={12} lg={12} xl={12} marginTop={2} textAlign={'center'}>
                    <img id='output' className='procedure-user-selfie' width='200px' height={'200px'}
                         src={`${base64Text},${fileForGbgProcedure?.filter(
                           (file: FileDTO) => file.name.includes(GbgFilesType.SELFIE_PHOTO))[0]?.base64Content}`}/>
                  </Grid>
                  {fileForGbgProcedure?.filter((file) => !file.name.includes(GbgFilesType.SELFIE_PHOTO) && !file.name.includes(GbgFilesType.FACE_PORTRAIT))
                    .map((file) => {
                      return (
                        <Grid item xs={10} sm={12} md={12} lg={6} xl={6} marginTop={2} textAlign={'center'}>
                          <img id='output' className='procedure-user-card' height={'200px'}
                               src={`${base64Text},${file?.base64Content}`}/>
                        </Grid>
                      );
                    })}
                </Grid>
                <Box display={'flex'} alignItems={'center'} marginBottom={2} padding={0}>
                  <Button color={'secondary'}
                          onClick={() => navigate(`/clients/${kycProcedure.clientId}`, { state: { isHistory: true } })}>
                    <ArrowBackIcon/>
                  </Button>
                  <Typography variant='h5'><b>{t('KYC_PROCEDURE')}</b></Typography>
                </Box>
                {kycProcedure &&
                  <KYCProcedureView shareholders={shareholders} kycProcedure={kycProcedure} handleChangeSelectedFileForEdit={handleChangeSelectedFileForEdit}/>}
              </>
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} marginY={2}>
        <Box sx={{ border: '1px solid whitesmoke', height: '100%', padding: '10px' }}>
          {fileForReview ?
            (<Box ref={bottomRef}>
              {fileForReview.mimeType.includes('application/pdf') ?
                (<>
                  <PDFViewer fileForReview={fileForReview.base64Content}/>
                  <Box ref={bottomRef}> </Box>
                </>)
                :
                (
                  <>
                    <img id='output' className='procedure-image-file'
                         src={`${base64Text},${fileForReview.base64Content}`}/>
                    <Box ref={bottomRef}> </Box>
                  </>)
              }
            </Box>) : (
              <Box textAlign={'center'} color={'secondary'} ref={bottomRef}>
                <i>{t('NO_SELECTED_FILE')}</i>
              </Box>
            )
          }
        </Box>
      </Grid>
    </Grid>);
};
export default ProcedureDetailsPage;

import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import SwalAlert from "../../../components/SwalAlert/SwalAlert";
import { ActiveTCTermGroupWithSectionResponseDto } from "../../../models/terms_conditions/ActiveTCTermGroupWithSectionResponseDto";
import { TCTermGroupDto } from "../../../models/terms_conditions/TCTermGroupDto";
import { TermsConditionService } from "../../../repository/TermsConditionService";
import {
  BootstrapDialog,
  Transition,
} from "../../../shared/components/BootstrapDialog/BootstrapDialog";
import { useTranslation } from 'react-i18next';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  mmEntityId?: number | string;
  isAddMode?: boolean;
  termGroupData?: ActiveTCTermGroupWithSectionResponseDto;
  termGroupId?: number | string;
  updateAll?: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const TermGroupAddModal = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TCTermGroupDto>();
  const { t } = useTranslation();
  const addTermGroup = (data: TCTermGroupDto): void => {
    TermsConditionService.createTermGroup(data)
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
        props.handleClose();
        props.updateAll();
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  const editTermGroup = (data: TCTermGroupDto): void => {
    TermsConditionService.editTermGroup(props.termGroupId, data)
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
        props.handleClose();
        props.updateAll();
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  const resetFormData = () => {
    reset();
  };
  const onSubmit: SubmitHandler<TCTermGroupDto> = (
    data: TCTermGroupDto
  ): void => {
    data.mmEntityId = props.mmEntityId;
    props.isAddMode ? addTermGroup(data) : editTermGroup(data);
  };
  useEffect(() => {
    if (props.open && props.isAddMode) {
      resetFormData();
    } else {
      if (props.termGroupData) {
        setValue("name", props.termGroupData.termGroupName);
        setValue("description", props.termGroupData.description);
        setValue("active", props.termGroupData.active);
      }
    }
  }, [props.open, props.termGroupData]);
  return (
    <div>
      <BootstrapDialog
        style={{ fontFamily: "Poppins" }}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modalWrapper"
        TransitionComponent={Transition}
        maxWidth={"md"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.handleClose}
          >
            {props.isAddMode ? t('CREATE_NEW_TERM_GROUP') : t('EDIT_TERM_GROUP')}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4} padding={2}>
              <Grid item xs={12}>
                <TextField
                  id="termGroupName"
                  label={t('NAME')}
                  {...register("name", { required: true })}
                  variant="standard"
                  color="primary"
                  fullWidth={true}
                />
                {errors.name && (
                  <span style={{ color: "red" }}>{t('REQUIRED_FIELD')}</span>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="termGroupDescription"
                  label={t('DESCRIPTION')}
                  {...register("description", { required: false })}
                  variant="standard"
                  color="primary"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="inherit"
              className={"modalCloseButton baseFontFamily"}
              onClick={props.handleClose}
            >
              {t('CLOSE')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {props.isAddMode ? t('CREATE_NEW_TERM_GROUP') : t('EDIT_TERM_GROUP')}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};
export default TermGroupAddModal;

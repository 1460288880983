import { TCSectionResponseDto } from "./TCSectionResponseDto";

export class ActiveTCTermGroupWithSectionResponseDto {
    id:number;
    MMEntityId?: number;
    termGroupName?: string;
    description?: string;
    active?: boolean;
    activeFrom?: Date | null;
    sections?: TCSectionResponseDto[];

  
    constructor(json: ActiveTCTermGroupWithSectionResponseDto) {
      this.id = json.id;
      this.MMEntityId = json.MMEntityId;
      this.termGroupName = json.termGroupName;
      this.description = json.description;
      this.active = json.active;
      this.activeFrom = json.activeFrom ? new Date(json.activeFrom) : undefined;
      this.sections = json.sections ? json.sections.map((section) => new TCSectionResponseDto(section)) : undefined;
    }
  }
  
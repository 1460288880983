import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { AMLProcedureWrapper } from './AMLProcedureWrapper/AMLProcedureWrapper';
import { AMLProcedureHeaderContent } from './AMLProcedureHeaderContent/AMLProcedureHeaderContent';
import { AMLProcedureMiddleContent } from './AMLProcedureMiddleContent/AMLProcedureMiddleContent';
import { AMLProcedureFooterContent } from './AMLProcedureFooterContent/AMLProcedureFooterContent';
import { useTranslation } from 'react-i18next';

interface AMLVerification {
  onNext: () => void;
}

export function AMLVerification({ onNext }: AMLVerification) {
  const { t } = useTranslation();

  return (
    <>
      <AMLProcedureHeaderContent>
        <Typography>{t('AML_VERIFICATION')}</Typography>
      </AMLProcedureHeaderContent>
      <AMLProcedureMiddleContent>
        <Box>
          <Typography variant={'h6'} marginBottom={3}>
            <b>{t('AML_VERIFICATION_DEAR_CUSTOMER')}</b>
          </Typography>
          <Typography marginY={3}>
          {t('AML_VERIFICATION_FIRST_PARAGRAPH')}
          </Typography>
          <Typography marginY={3}>
          {t('AML_VERIFICATION_SECOND_PARAGRAPH')}
            <a href={'https://safe2transact.com/'} className={'link privacy-link'}
               target={'_blank'}> www.safe2transact.com.</a>&nbsp;
          </Typography>
          <Typography marginY={3}>
          {t('AML_VERIFICATION_THIRD_PARAGRAPH')}
          </Typography>
        </Box>
      </AMLProcedureMiddleContent>
      <AMLProcedureFooterContent
        nextButton={
          <Button variant='contained' onClick={onNext}>
            {t('NEXT')}
          </Button>
        }/>
    </>
  );
}

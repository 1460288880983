import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { Box, Button, Grid, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import FileUploadInput from '../../../components/FormFIelds/FIleUploadInput';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../../models/aml/FileDTO';
import { CryptoCurrencyDto } from '../../../models/icoToken/CryptoCurrencyDto';
import { FiatCurrencyDto } from '../../../models/icoToken/FiatCurrencyDto';
import { IcoTokenRequest } from '../../../models/icoToken/IcoTokenRequest';
import { FilePrivateService } from '../../../repository/FilePrivateService';
import { FileService } from '../../../repository/FileService';
import IcoTokenService from '../../../repository/IcoTokenService';
import ApplicationService from '../../../utils/ApplicationService';
import { Currency } from '../constants/Currency';
import { IcoTokenProps } from './IcoTokenProps';
import { useTranslation } from 'react-i18next';
import './AddUpdateToken.scss';
import ClearIcon from '@mui/icons-material/Clear';
import Swal from 'sweetalert2';

const AddUpdateToken = (props: IcoTokenProps) => {
  const [currency, setCurrency] = useState<Currency>(Currency.FIAT);
  const [allFiat, setAllFiat] = useState<FiatCurrencyDto[]>([]);
  const [allCrypto, setAllCrypto] = useState<CryptoCurrencyDto[]>([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [request, setRequest] = useState<IcoTokenRequest>({
    id: props?.id,
    fiatCurrency: '',
    cryptoCurrency: '',
    amountPaid: '',
    tokenPrice: '',
    tokenAmount: '',
    files: []
  });
  const disableButton = (request.fiatCurrency === '' && request.cryptoCurrency === '') || request.amountPaid === '' || request.tokenPrice === '' || request.tokenAmount === '';
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    setRequest(prevState => ({ ...prevState, fiatCurrency: '', cryptoCurrency: '' }));
    setCurrency(event.target.value);
  };
  const handleChangeFiatCurrency = (event: SelectChangeEvent) => {
    setRequest(prevState => ({ ...prevState, fiatCurrency: event.target.value, cryptoCurrency: '' }));
  };
  const handleChangeCryptoCurrency = (event: SelectChangeEvent) => {
    setRequest(prevState => ({ ...prevState, cryptoCurrency: event.target.value, fiatCurrency: '' }));
  };
  const saveChanges = () => {
    IcoTokenService.createOrUpdate(request, props.clientId).then((res) => {
      SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
      props.refreshTable();
      props.closeDialog();
    }).catch((e) => {
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
  };

  const downloadDocument = (file: FileDTO) => {
    FilePrivateService.downloadDocuments(Number(file.id), file.name);
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024;

  const handleFileChange = (files: File[]) => {
    const validFiles = [];
    for (const file of files) {
      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        SwalAlert.errorAlert(t('FILE_TOO_LARGE'), t('FILE_SIZE_LIMIT', { size: '10 MB' }));
      }
    }
    if (validFiles.length > 0) {
      ApplicationService.filesToFilesDto(validFiles).then((res) => {
        setRequest(prevState => ({ ...prevState, files: [...prevState.files, ...res] }));
      });
    }
  };

  const removeItem = (id: number) => {
    setRequest(prevState => ({ ...prevState, files: [...prevState.files.filter(f => f.id !== id)] }));
  };

  const handleDelete = () => {
    IcoTokenService.delete(props.id!).then((res) => {
      props.refreshTable();
      props.closeDialog();
      setOpenConfirmDelete(false);
      SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
    }).catch((e) => {
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
  };
  useEffect(() => {
    IcoTokenService.allFiat().then((res) => {
      setAllFiat(res);
    });
    IcoTokenService.allCrypto().then((res) => {
      setAllCrypto(res);
    });
    if (props.id) {
      IcoTokenService.findById(props.id).then((res) => {
        setRequest(prevState => ({
          ...prevState,
          fiatCurrency: res.fiatCurrency === null ? '' : res.fiatCurrency?.name,
          cryptoCurrency: res.cryptoCurrency === null ? '' : res.cryptoCurrency?.name,
          amountPaid: res.amountPaid.toString(),
          tokenPrice: res.tokenPrice.toString(),
          tokenAmount: res.tokenAmount.toString(),
          files: res.files
        }));
      });
    }
  }, []);


  return (
    <Grid container spacing={2} padding={4}>
      <Grid item sm={12}>
        <Typography className='add-edit-token'>{props.id ? t('EDIT_TOKEN') : t('ADD_TOKEN')}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormControl fullWidth>
          <InputLabel>{t('CURRENCY')}</InputLabel>
          <Select
            id='crypto'
            value={currency.toString()}
            label={t('CURRENCY')}
            onChange={handleChange}
          >
            <MenuItem key={1} value={Currency.FIAT}>{Currency.FIAT}</MenuItem>
            <MenuItem key={2} value={Currency.CRYPTO}>{Currency.CRYPTO}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {currency === Currency.FIAT ? (
        <Grid item xs={12} sm={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel>{t('FIAT')}</InputLabel>
            <Select
              id='fiat'
              value={request.fiatCurrency}
              label={t('FIAT')}
              onChange={handleChangeFiatCurrency}
            >
              {
                allFiat.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel>{t('CRYPTO')}</InputLabel>
            <Select
              id='crypto'
              value={request.cryptoCurrency}
              label={t('CRYPTO')}
              onChange={handleChangeCryptoCurrency}
            >
              {
                allCrypto.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={12} lg={4}>
        <TextField
          type='number'
          label={t('AMOUNT_PAID')}
          fullWidth={true}
          value={request.amountPaid}
          onChange={(event) => setRequest(prevState => ({ ...prevState, amountPaid: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <TextField
          type='number'
          label={t('TOKEN_PRICE')}
          fullWidth={true}
          value={request.tokenPrice}
          onChange={(event) => setRequest(prevState => ({ ...prevState, tokenPrice: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <TextField
          type='number'
          label={t('TOKEN_AMOUNT')}
          fullWidth={true}
          value={request.tokenAmount}
          onChange={(event) => setRequest(prevState => ({ ...prevState, tokenAmount: event.target.value }))}/>
      </Grid>
      <Grid item xs={12}>
        {request.files && request.files.length > 0 &&
          <Box>
            <Typography>
              <b>{t('FILES')}:</b>
            </Typography>
            {request.files.map(
              (file: FileDTO, index) => {
                return (
                  <Box className={'files-list-item'} key={index}>
                    <Box
                      marginX={1}
                      className={'file'}
                      key={index}>
                      <SimCardDownloadOutlinedIcon className={'file-icon'}/>
                      <div onClick={() => downloadDocument(file)} className='action-pointer'>{file.name}</div>
                      {file?.id && <ClearIcon className={'action-pointer remove-icon'} onClick={() => removeItem(Number(file.id))}></ClearIcon>}
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>}
      </Grid>
      <Grid item xs={12}>
        <FileUploadInput
          label={t('PROOF_OF_PAYMENT')}
          selectedFiles={request.files}
          onFileChange={handleFileChange}
          index={0}
          onSizeErrorModal={(errorMessage: string) => {
            Swal.fire({
              icon: 'error',
              title: t('FILE_TOO_LARGE'),
            });
          }}
          name='ico-token-files'/>
      </Grid>
      <Grid item xs={12}>
        <Box className='token-buttons'>
          {props?.id &&
            <Button variant='contained' className='token-delete' onClick={() => setOpenConfirmDelete(true)}>
              {t('DELETE')}
            </Button>
          }
          <Button disabled={disableButton} variant='contained' color='primary' className='token-save' onClick={saveChanges}>
            {t('SAVE')}
          </Button>
        </Box>
      </Grid>
      <ConfirmationDialog
        open={openConfirmDelete}
        title={t('DELETE_TOKEN')}
        handleClose={() => setOpenConfirmDelete(false)}
        handleConfirm={() => handleDelete()}
        content={
          t('DELETE_TOKEN_MESSAGE')
        }
        actionButtonName={t('DELETE')}
      ></ConfirmationDialog>
    </Grid>
  );
};
export default AddUpdateToken;
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import {
    Card,
    CardContent,
    Checkbox,
    Grid,
    List,
    ListSubheader,
    Paper,
    Typography
} from '@mui/material';
import './Terms.scss'
import {EntityService} from '../../repository/EntityService';
import {MMEntityResponseDto} from '../../models/terms_conditions/MMEntityResponseDto';
import {TermsConditionService} from '../../repository/TermsConditionService';
import {
    ActiveTCTermGroupWithSectionResponseDto
} from '../../models/terms_conditions/ActiveTCTermGroupWithSectionResponseDto';
import EditIcon from '@mui/icons-material/Edit';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import {SectionService} from '../../repository/SectionService';
import AddButton from '../../components/AddButton/AddButton';
import AddIcon from '@mui/icons-material/Add';
import TermGroupAddModal from './modals/TermGroupAddModal';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import TermGroupSectionAddModal from './modals/TermGroupSectionAddModal';
import {TCSectionResponseDto} from '../../models/terms_conditions/TCSectionResponseDto';
import StyledTextRenderer from './modals/StyledTextRenderer';
import ActionButton from "../../components/ActionButton/ActionButton";
import { useTranslation } from 'react-i18next';


function TermsAndConditions() {

    const [value, setValue] = useState<number>(0)
    const [mmEntities, setMMEntities] = useState<MMEntityResponseDto[]>([])
    const [entityGroups, setEntityGroups] = useState<ActiveTCTermGroupWithSectionResponseDto>()
    const [deleteSection, setDeleteSection] = useState<boolean>(false)
    const [sectionId, setSectionId] = useState<number>(0)
    const [openAddTermGroupModal, setOpenTermGroupModal] = useState<boolean>(false)
    const [openAddTermGroupSectionModal, setOpenTermGroupSectionModal] = useState<boolean>(false)
    const [isAddMode, setIsAddMode] = useState<boolean>(false)
    const [isTermGroupSectionAddMode, setIsTermGroupSectionAddMode] = useState<boolean>(false)
    const [termGroupId, setTermGroupId] = useState<number | undefined>()
    const [termGroupSectionId, setTermGroupSectionId] = useState<number | undefined>()
    const [termGroupForSectionId, setTermGroupForSectionId] = useState<number | undefined>()
    const [termGroupData, setTermGroupData] = useState<ActiveTCTermGroupWithSectionResponseDto>();
    const [termGroupSectionData, setTermGroupSectionData] = useState<TCSectionResponseDto>();
    const { t } = useTranslation();

    async function getAllTcEntities(): Promise<void> {
        await EntityService.getAllTcEntities().then((response) => {
            setMMEntities(response)
            const firstEntityId = response[0].id
            getEntityGroups(firstEntityId!)
        })
    }

    async function getEntityGroups(id: number | undefined) {
        await TermsConditionService.findAllByEntityId(id).then((response) => {
            setEntityGroups(response)
        })
    };

    function handleChange(event: React.SyntheticEvent, value: any) {
        setValue(value)
        const clickedEntityId = mmEntities[value].id
        console.log(clickedEntityId)
        getEntityGroups(clickedEntityId)
    };

    function setSectionIdForDelete(id: number) {
        setDeleteSection(true)
        setSectionId(id)
    }

    function deleteSelectedSession() {
        if (sectionId) {
            setDeleteSection(false)
            SectionService.deleteActiveGroupSection(sectionId).then(() => {
                SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFULLY_DELETED_SECTION'));
                const clickedEntityId = mmEntities[value]?.id;
                if (clickedEntityId) {
                    getEntityGroups(clickedEntityId);
                }
            })
                .catch((error) => {
                    if (error) {
                        SwalAlert.errorAlert(t('ERROR'), t('SOMETHING_WENT_WRONG'));
                    }
                });
        }
        setSectionId(0)
    }

    function cancelDelete() {
        setDeleteSection(false)
        setSectionId(0)
    }

    function handleClickOpenAddTermGroupModal() {
        setOpenTermGroupModal(true);
        setIsAddMode(true);
    }

    function handleCloseAddTermGroupModal() {
        setOpenTermGroupModal(false);
        setIsAddMode(false);
    }

    function handleClickOpenAddTermGroupSectionModal() {
        setOpenTermGroupSectionModal(true);
        setIsTermGroupSectionAddMode(true);
        setTermGroupForSectionId(entityGroups?.id);
    }

    function handleCloseAddTermGroupSectionModal() {
        setOpenTermGroupSectionModal(false);
        setIsTermGroupSectionAddMode(false);
        setTermGroupForSectionId(undefined);
    }

    function handleClickOpenEditTermGroupModal(id: number | undefined) {
        setOpenTermGroupModal(true);
        setIsAddMode(false);
        setTermGroupId(id);
        setTermGroupData(entityGroups)
    }

    function handleClickOpenEditTermGroupSectionModal(id: number) {
        setOpenTermGroupSectionModal(true);
        setIsTermGroupSectionAddMode(false);
        setTermGroupSectionId(id);
        setTermGroupForSectionId(entityGroups?.id)
        setTermGroupSectionData(entityGroups?.sections?.find((section) => section.id === id));
    }


    useEffect(() => {
        getAllTcEntities().then((_) => {
        })
    }, []);


    return (
        <>
            <Box className={'terms-content'}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable terms and conditions entities"
                >
                    {mmEntities.map(entity => {
                        return <Tab key={entity.id} label={entity.label}/>
                    })}
                </Tabs>
                <Card variant='outlined'>
                    <CardContent>
                             <React.Fragment key={entityGroups?.id}>
                                <Grid container alignItems={"flex-start"} alignContent={"flex-start"} spacing={0}
                                      rowGap={0}>
                                    {entityGroups?.id &&
                                    <Grid item lg={12} md={12} sm={12} xs={12} marginBottom={3}>
                                        <Box key={entityGroups?.id} component="span">
                                            <Box sx={{display: 'flex', width: '100%'}}>
                                                <Typography variant="h5" sx={{textAlign: 'center'}}> <b
                                                    style={{fontWeight: 600}}>{entityGroups?.termGroupName}</b>  </Typography>
                                                <Box sx={{marginLeft: 'auto'}}>
                                                    <AddButton
                                                        onClickHandle={() => handleClickOpenEditTermGroupModal(entityGroups?.id)}
                                                        text={"Edit term group"}
                                                        icon={<EditIcon className="buttonIcon"/>}/>
                                                </Box>
                                            </Box>
                                            <Typography variant="h6">{entityGroups?.description}</Typography>
                                        </Box>
                                    </Grid>
                                    }
                                </Grid>
                                <Grid container spacing={0} rowGap={0}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"}
                                          justifyContent={"center"}>
                                        <Box key={entityGroups?.id} component="div" sx={{width: '90%', overflow: 'auto'}}
                                             display={"flex"} justifyContent={"end"}>
                                        </Box>
                                    </Grid>
                                </Grid>
                                 {entityGroups?.id &&
                                <Grid container spacing={0} rowGap={0}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} display={"flex"}
                                          justifyContent={"center"}>
                                        <Box key={entityGroups?.id} component="div" sx={{width: '100%'}}>
                                            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                                                <Box sx={{width: 'auto',}}>
                                                    <ListSubheader sx={{
                                                        fontSize: "18px",
                                                        display: "flex",
                                                    }}>
                                                        {t('ACTIVE_GROUP_SECTIONS')}
                                                        <Box sx={{marginLeft: 'auto'}}>
                                                            <AddButton text={"Add section"}
                                                                       onClickHandle={handleClickOpenAddTermGroupSectionModal}
                                                                       icon={<AddIcon className="buttonIcon"/>}/>
                                                        </Box>
                                                    </ListSubheader>
                                                </Box>
                                                <Box key={entityGroups?.id} component="div"
                                                     sx={{overflow: 'auto', height: '520px'}}>

                                                    {entityGroups?.sections?.map((section, index) => {
                                                        const labelId = `checkbox-list-label-${section.id}`;
                                                        return (
                                                            <Paper elevation={1} key={section.id}
                                                                   sx={{
                                                                       padding: "10px",
                                                                       margin: "20px",
                                                                       display: 'flex',
                                                                   }}
                                                            className={'border-light'}>

                                                                <Checkbox
                                                                    checked={false}
                                                                    edge="start"
                                                                    tabIndex={-1}
                                                                    sx={{
                                                                        marginRight: '10px',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                    disableRipple
                                                                    inputProps={{'aria-labelledby': labelId}}
                                                                    disabled
                                                                />

                                                                <Box sx={{
                                                                    width: '100%',
                                                                }}>

                                                                    <StyledTextRenderer html={section.content}/>
                                                                </Box>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    justifyContent: "end",
                                                                    marginBottom: '0px',
                                                                    marginLeft: 'auto'
                                                                }}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        marginRight: "10px"
                                                                    }}>
                                                                        <Box display={'flex'} alignItems={'start'} justifyContent={'center'} marginRight={'5px'}>
                                                                            <ActionButton type={'edit'} action={handleClickOpenEditTermGroupSectionModal} element={section.id}/>
                                                                            <ActionButton type={'delete'} action={setSectionIdForDelete} element={section.id} />
                                                                        </Box>

                                                                        {/*<IconButton edge="end" aria-label="edit" sx={{marginRight:'0px', backgroundColor:'purple'}}>*/}
                                                                        {/*    <EditIcon sx={{color:'white'}}*/}
                                                                        {/*              onClick={() => handleClickOpenEditTermGroupSectionModal(section.id)}/>*/}
                                                                        {/*</IconButton>*/}
                                                                        {/*<IconButton edge="end" aria-label="delete"*/}
                                                                        {/*            sx={{justifyContent: "end"}}*/}
                                                                        {/*            onClick={() => setSectionIdForDelete(section.id)}>*/}
                                                                        {/*    <DeleteIcon style={{color: '#d23862'}}/>*/}
                                                                        {/*</IconButton>*/}
                                                                    </Box>
                                                                </Box>

                                                            </Paper>

                                                        );
                                                    })}
                                                </Box>
                                            </List>
                                        </Box>
                                    </Grid>
                                </Grid>
                                 }
                            </React.Fragment>
                        {!entityGroups?.id  && <Box component="div">
                            <Box component="span" display={"flex"} justifyContent={'center'} color={'gray'}
                                 fontSize={'large'}>
                                <i>{t('NOT_ACTIVE_GROUP_SECTIONS')}</i>
                            </Box>
                            <Grid container display={"flex"} justifyContent={"end"} spacing={0} rowGap={0}
                                  marginTop={3}>
                                <Grid item lg={2} md={4} sm={12} xs={12}>
                                    <Box component="div" display={"flex"} justifyContent={"end"}>
                                        <AddButton onClickHandle={handleClickOpenAddTermGroupModal} text={"Add group"}
                                                   icon={<AddIcon className="buttonIcon"/>}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        }

                    </CardContent>
                    {entityGroups?.id &&
                    <Grid item lg={2} md={4} sm={12} xs={12}>
                        <Box sx={{ margin: '10px 15px 20px 0px' }} display="flex" justifyContent="flex-end">
                            <AddButton text={t('REPLACE_GROUP')}
                                       icon={<FindReplaceIcon className="buttonIcon"/>}/>
                        </Box>
                    </Grid>
                    }
                </Card>
            </Box>

            <ConfirmationDialog open={deleteSection} title={t('DELETE_SECTION_FROM_ACTIVE_GROUP')}
                                handleClose={() => cancelDelete()}
                                handleConfirm={() => deleteSelectedSession()}
                                content={t('SURE_TO_DELETE_SECTION')}
                                actionButtonName={t('CONFIRM')}></ConfirmationDialog>
            <TermGroupAddModal
                open={openAddTermGroupModal}
                handleClose={handleCloseAddTermGroupModal}
                mmEntityId={mmEntities[value]?.id}
                isAddMode={isAddMode}
                termGroupData={termGroupData}
                termGroupId={termGroupId}
                updateAll={() => {
                    getEntityGroups(mmEntities[value]?.id)
                }}
            />
            <TermGroupSectionAddModal
                open={openAddTermGroupSectionModal}
                handleClose={handleCloseAddTermGroupSectionModal}
                isAddMode={isTermGroupSectionAddMode}
                termGroupSectionId={termGroupSectionId}
                termGroupForSectionId={termGroupForSectionId}
                termGroupSectionData={termGroupSectionData}
                updateAll={() => {
                    getEntityGroups(mmEntities[value]?.id)
                }}
            />
        </>
    )
}

export default TermsAndConditions

import { Button, Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LightTooltip from '../../../components/LightTooltip/LightTooltip';
import PageTable from '../../../components/Table/PageTable';
import { IcoTokenResponse } from '../../../models/icoToken/IcoTokenResponse';
import IcoTokenService from '../../../repository/IcoTokenService';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';
import './IcoTokenDetails.scss';
import CloseIcon from '@mui/icons-material/Close';
import AddUpdateToken from './AddUpdateToken';

const IcoTokenDetails = () => {
  const params = useParams();
  const [icoTokenResponse, setIcoTokenResponse] = useState<IcoTokenResponse[]>();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [clickedId, setClickedId] = useState<number>();

  const getClientDetails = () => {
    IcoTokenService.findAllByClient(params.id!).then((res) => {
      setIcoTokenResponse(res);
    });
  };

  const openDialog = (id: number | undefined) => {
    setClickedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackdropClick = (event: any) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (params.id) {
      getClientDetails();
    }
  }, []);

  const headerRows = [
    {
      id: 'dateCreated',
      label: t('DATE_CREATED'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'currency',
      label: t('CURRENCY'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'amountPaid',
      label: t('AMOUNT_PAID'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'tokenPrice',
      label: t('TOKEN_PRICE'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'tokenAmount',
      label: t('TOKEN_AMOUNT'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'hasFile',
      label: t('PROOF_OF_PAYMENT'),
      search: false,
      type: 'boolean',
      variant: 'standard'
    }
  ];

  const rows = icoTokenResponse?.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: 'text',
          name: 'dateCreated',
          value: (
            <LightTooltip title={DateTimeUtils.formatDate(element?.dateCreated.toString())}>
              <span>{DateTimeUtils.formatDate(element?.dateCreated.toString())}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'currency',
          value: (
            <LightTooltip title={element?.cryptoCurrency ? element?.cryptoCurrency.label : element?.fiatCurrency.label}>
              <span>{element?.cryptoCurrency ? element?.cryptoCurrency.label : element?.fiatCurrency.label}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'amountPaid',
          value: (
            <LightTooltip title={element?.amountPaid}>
              <span>{element.amountPaid}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'tokenPrice',
          value: (
            <LightTooltip title={element?.tokenPrice}>
              <span>{element.tokenPrice}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'tokenAmount',
          value: (
            <LightTooltip title={element?.tokenAmount}>
              <span>{element.tokenAmount}</span>
            </LightTooltip>
          )
        },
        {
          type: 'boolean',
          name: 'tokenAmount',
          value: element.files.length > 0
        }
      ]
    };
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button variant='contained' color='primary' className='new-token-button' onClick={() => openDialog(undefined)}>
          {t('ADD_NEW_TOKEN')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <PageTable
          headerRows={headerRows}
          rows={rows}
          handleOpen={openDialog}
        />
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        onClick={handleBackdropClick}
        fullWidth
      >
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <AddUpdateToken id={clickedId} clientId={Number(params.id!)} refreshTable={getClientDetails} closeDialog={handleClose}/>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default IcoTokenDetails;
import axios from "../axios/axios";
import { MMEntityResponseDto } from "../models/terms_conditions/MMEntityResponseDto";

export class EntityService {

    static async getAllTcEntities(): Promise<Array<MMEntityResponseDto>> {
        const response = await axios.get<Array<MMEntityResponseDto>>(`/metamodel/mm-entity/list`)

        const data = response.data

        return data.map(entity => new MMEntityResponseDto(entity))
    }

};

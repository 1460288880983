import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Paper, Grid, Switch } from "@mui/material";
import React, { useState } from "react";
import ActionButton from "../../../components/ActionButton/ActionButton";
import QuestionAnswerComponents from "../../../components/QuestionAnswerComoponents/QuestionAnswerComponents";
import QuestionFileComponents from "../../../components/QuestionFileComponents/QuestionFilesComponents";
import ClientQuestionnairesDTO from "../../../models/clientQuestionnaires/ClientQuestionnairesDTO";
import { AmlQuestionProps } from "./AmlQuestionProps";
import { useTranslation } from 'react-i18next';

const AMLQuestion = (props: AmlQuestionProps) => {
  const { t } = useTranslation();

  const [showMoreDetails, setShowMoreDetails] = useState(
    props.isCopy ? true : false
  );

  const handleDeleteQuestion = (id : number) => {
    if (props.handleDeleteQuestion) {
      props.handleDeleteQuestion(id);
    }
  };

  const handleEditQuestion = (question: ClientQuestionnairesDTO) => {
    if (props.handleClickOpenEditQuestion) {
      props.handleClickOpenEditQuestion(question);
    }
  };

  return (
    <Paper
      elevation={1}
      //key={props.question.amlQuestionDTO.id}
      className={"border-light"}
      sx={{
        padding: "10px",
        margin: "20px",
        marginX: "auto",
        width: "100%",
        minHeight: "100px",
      }}
    >
      <Box sx={{ display: "flex", width: "100%" }} alignItems={"start"}>
        <Box
          sx={
            !showMoreDetails
              ? {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }
              : {}
          }
          width={props.isCopy ? "100%" : "80%"}
          marginTop={"9px"}
        >
          {props.question.amlQuestionDTO?.order
            ? props.question.amlQuestionDTO?.order + "."
            : ""}{" "}
          {props.question.amlQuestionDTO.title}
        </Box>

        {props.isChangeRequest && (
          <Grid container item xs={12} sm={6} sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginRight: "10px", color: "gray" }}>
              <Box sx={{ marginRight: "5px" }}>{t('ACTIVE')}:</Box>
              <Box>{props.question.amlQuestionDTO.active ? 'true' : 'false'}</Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginRight: "10px", color: "gray" }}>
              <Box sx={{ marginRight: "5px" }}>{t('USE_COUNTRY_LIST')}:</Box>
              <Box>{props.question.amlQuestionDTO.useCountryList ? 'true' : 'false'}</Box>
            </Box>
          </Grid>
        )}

        {!props.isCopy ? (
          !showMoreDetails ? (
            <Box
              style={{
                marginLeft: "auto",
                display: "flex",
                color: "primary",
                cursor: "pointer",
                fontSize: "14px",
              }}
              marginRight={"10px"}
              onClick={() => setShowMoreDetails(true)}
            >
              {" "}
              {t('SHOW_MORE_DETAILS')} <ArrowRightIcon sx={{ fontSize: "21px" }} />
            </Box>
          ) : (
            <Box
              style={{
                marginLeft: "auto",
                display: "flex",
                color: "primary",
                cursor: "pointer",
                fontSize: "14px",
              }}
              marginRight={"10px"}
              onClick={() => setShowMoreDetails(false)}
            >
              {" "}
              {t('SHOW_MORE_DETAILS')} <ArrowDropDownIcon sx={{ fontSize: "21px" }} />
            </Box>
          )
        ) : null}
        {props.isCopy && props.canEdit && (
          <Box>
            <Box
              display={"flex"}
              alignItems={"start"}
              sx={{
                marginLeft: "auto",
                marginRight: "10px",
              }}
            >
              <ActionButton
                type={"edit"}
                action={handleEditQuestion}
                element={props.question}
              />
              <ActionButton
                type={"delete"}
                action={handleDeleteQuestion}
                element={props.question.amlQuestionDTO.id}
              />
            </Box>
          </Box>
        )}
      </Box>
      {(showMoreDetails || props.isCopy) && (
        <Box
          sx={{
            display: "flex",
            marginBottom: "0px",
          }}
        >
          <Box marginTop={"10px"}>
            {props.question.nomenclatureEntryDtoList?.length > 0 && (
              <span style={{ color: "gray" }}>
                <i>{t('ANSWERS')}:</i>
              </span>
            )}

            {props.question.nomenclatureEntryDtoList && props.question.nomenclatureEntryDtoList.map((answer, index) => {
              if (!answer.deleted) {
                const questionType = props.questionTypesList.find(
                  (type) => type.label === props.question.amlQuestionDTO.amlQuestionType
                )?.type;
                return (
                  <Box key={answer.id || `answer-${index}`} paddingLeft={"10px"}>
                    <QuestionAnswerComponents
                      answer={answer}
                      questionType={questionType}
                    />
                  </Box>
                );
              }
            })}

            {props.question.amlQuestionFileDtos?.length > 0 && (
              <span style={{ color: "gray" }}>
                <i>{t('FILES')}:</i>
              </span>
            )}
            {props.question.amlQuestionFileDtos?.map((file, index) => {
              if (!file.deleted) {
                return (
                  <Box key={index} paddingLeft={"20px"}>
                    <QuestionFileComponents file={file}/>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>
      )}
    </Paper>
  );
};
export default AMLQuestion;

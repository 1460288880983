import { Box, Card, Checkbox, DialogActions, FormControlLabel, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageLoadingCircle } from '../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { ProcedureTokenResponse } from '../../../models/procedure/ProcedureTokenResponse';
import { ProcedureTypeShortName } from '../../../models/procedure/ProcedureTypeShortName';
import { ClientService } from '../../../repository/ClientService';
import { ClientTokenService } from '../../../repository/ClientTokenService';
import KYCProcedureService from '../../../repository/KYCProcedureService';
import { ProcedureService } from '../../../repository/ProcedureService';
import { BootstrapDialog, Transition } from '../../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import ProcedureLink from '../AmlProcedureLink/ProcedureLink';
import './ClientProcedureLinkDetailsModal.scss';
import { ClientProcedureLinkDetailsModalProps } from './ClientProcedureLinkDetailsModalProps';

const ClientProcedureLinkDetailsModal = ({
  handleCloseModal, isShowModal, selectedClient
}: ClientProcedureLinkDetailsModalProps) => {
  const { t } = useTranslation();
  const [AMLProcedureTokenDetails, setAMLProcedureTokenDetails] = useState<ProcedureTokenResponse>();
  const [KYCProcedureTokenDetails, setKYCProcedureTokenDetails] = useState<ProcedureTokenResponse>();
  const [hasKycProcedure, setHasKycProcedure] = useState<boolean>(false);
  const [kycLinkExpired, setKycLinkExpired] = useState<boolean>(true);
  const [amlLinkExpired, setAmlLinkExpired] = useState<boolean>(true);
  const [autoGenerateAml, setAutoGenerateAml] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!isShowModal) {
      setHasKycProcedure(false);
      setAmlLinkExpired(true);
      setKycLinkExpired(true);
    }
  }, [isShowModal]);

  useEffect(() => {
    if (selectedClient) {
      getDetailsForAMLToken(selectedClient.id!);
      getDetailsForKYCToken(selectedClient.id!);
      setAutoGenerateAml(selectedClient?.autoGenerateAml!);
    }
  }, [selectedClient]);

  const getDetailsForAMLToken = (id: number) => {
    ProcedureService.getDetailsForAMLToken(id).then(async (data: ProcedureTokenResponse) => {
      setAMLProcedureTokenDetails(data);
      if (data.token) {
        setAmlLinkExpired(await isLinkExpired(data.token));
      }
    }).catch((error) => {
    });
  };

  const getDetailsForKYCToken = (id: number) => {
    ProcedureService.getDetailsForKYCToken(id).then(async (data: ProcedureTokenResponse) => {
      setKYCProcedureTokenDetails(data);
      setKycLinkExpired(await isLinkExpired(data.token));
      getIsExistsProcedureForToken(data.token);
    }).catch((error) => {
    });
  };

  const isLinkExpired = (link: string) => {
    return ClientTokenService.isTokenExpired(link).then(res => {
      return res;
    });
  };

  const renderEmptyProcedureInfo = () => {
    return <Box className={'no-initiate-procedure-info'}>
      <i>{t('NOT_INITIATED_PROCEDURE')}!</i>
    </Box>;
  };

  const handleInitialNewProcedure = (procedureType: ProcedureTypeShortName) => {
    setLoading(true);
    ProcedureService.reInitializeProcedure(selectedClient!, procedureType).then((data) => {
      if (procedureType === ProcedureTypeShortName.KYC) {
        // getDetailsForKYCToken(selectedClient?.id!);
        // ProcedureService.sendEmailToClientForProcedureToken(selectedClient?.email!, ProcedureTypeShortName.KYC);
        SwalAlert.successfulAlert(t('SUCCESSFULLY_INITIATED_KYC_LINK'), t('SUCCESSFULLY_INITIATED_KYC_LINK_MESSAGE'));
      } else {
        // getDetailsForAMLToken(selectedClient?.id!);
        // ProcedureService.sendEmailToClientForProcedureToken(selectedClient?.email!, ProcedureTypeShortName.AML);
        SwalAlert.successfulAlert(t('SUCCESSFULLY_INITIATED_AML_LINK'), t('SUCCESSFULLY_INITIATED_AML_LINK_MESSAGE'));
      }
      setLoading(false);
      handleCloseModal();
    }).catch((e) => {
      setLoading(false);
      handleCloseModal();
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
    });
  };

  const getIsExistsProcedureForToken = (token: string) => {
    KYCProcedureService.existsByClient(selectedClient?.id!).then((data) => {
      setHasKycProcedure(data);
    });
  };

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoGenerateAml(e.target.checked);
  };

  const handleUpdateAutoGenerateAml = () => {
    ClientService.updateAutoGenerateAml(selectedClient?.id!, autoGenerateAml).then(() => {
      SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
    }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
    });
  };
  return (
    <BootstrapDialog
      style={{ fontFamily: 'Poppins' }}
      onClose={handleCloseModal}
      aria-labelledby='customized-dialog-title'
      open={isShowModal}
      className='modalWrapper'
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth>
      <FullPageLoadingCircle loading={loading}/>
      <BootstrapDialogTitle
        id='customized-dialog-title'
        onClose={handleCloseModal}>
        {t('INVITE_NEW_CLIENT')}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              id='first-name-input'
              name={'firstName'}
              InputProps={{
                readOnly: true
              }}
              value={selectedClient?.firstName}
              fullWidth={true}
              focused={true}
              label={t('FIRST_NAME')}
              variant='standard'/>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              id='last-name-input'
              fullWidth={true}
              name={'lastName'}
              InputProps={{
                readOnly: true
              }}
              value={selectedClient?.lastName}
              focused={true}
              label={t('LAST_NAME')}
              variant='standard'/>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              id='email-input'
              fullWidth={true}
              InputProps={{
                readOnly: true
              }}
              name={'email'}
              value={selectedClient?.email}
              focused={true}
              type={'email'}
              label={t('EMAIL')}
              variant='standard'/>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              id='number-input'
              fullWidth={true}
              InputProps={{
                readOnly: true
              }}
              focused={true}
              value={selectedClient?.phoneNumber}
              name={'phoneNumber'}
              label={t('PHONE_NUMBER')}
              variant='standard'/>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <FormControlLabel control={<Checkbox name={'autoGenerateAml'} checked={autoGenerateAml} onChange={handleChangeCheckBox}/>}
                              label={t('AUTO_GENERATE_AML')}/>
            <Button variant='contained' color='primary' onClick={handleUpdateAutoGenerateAml}>
              {t('UPDATE')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Card sx={{ padding: '10px 10px 10px 10px' }}>
              <ProcedureLink selectedClient={selectedClient!} procedureType={ProcedureTypeShortName.KYC} tokenDetails={KYCProcedureTokenDetails!}
                             isLinkExpired={kycLinkExpired} initiateProcedure={handleInitialNewProcedure}/>
              <ProcedureLink selectedClient={selectedClient!} procedureType={ProcedureTypeShortName.AML} tokenDetails={AMLProcedureTokenDetails!}
                             isLinkExpired={amlLinkExpired} initiateProcedure={handleInitialNewProcedure} hasKycProcedure={hasKycProcedure}/>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          className={'modalCloseButton baseFontFamily'}
          onClick={handleCloseModal}>
          {t('CLOSE')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
export default ClientProcedureLinkDetailsModal;
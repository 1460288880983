import { Box, Card, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../../models/notifications/Notification';
import { NotificationService } from '../../repository/NotificationService';
import './Notification.scss';
import { DateTimeUtils } from '../../utils/DateTimeUtils';
import { useTranslation } from 'react-i18next';

const NotificationList = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    NotificationService.getAllNotifications()
      .then((data) => {
        setNotifications(data)
      });
  };

  const onHandleNotificationClick = (notification:Notification) => {
    NotificationService.markNotificationAsSeen(notification.id).then(()=>{
      if (notification.link) {
        navigate(notification.link);
      }
      getAllNotifications();
    })
  };


  const renderNotification = (notification: Notification) => {
    return <React.Fragment key={notification.id}>
      <ListItem onClick={() => onHandleNotificationClick(notification)} className={`notification ${notification.isSeen ? '' : 'new-notification'}`}>
        <Typography variant='body1'>{notification.content}</Typography>
        <Typography variant='caption'>{DateTimeUtils.formatDateTime(notification.dateCreated)}</Typography>
      </ListItem>
      <Divider/>
    </React.Fragment>
  };

  const renderNotifications = () => {
    return <List sx={{ paddingTop: 0 }}>
      {
        notifications.map(notification => renderNotification(notification))
      }
    </List>
  }

  return (
    <Box>
      <h1>{t('NOTIFICATIONS')}</h1>
      <Card>
        {notifications?.length > 0 ? (
         renderNotifications()
        ) : (
          <Box padding={3} textAlign={'center'}>
            <Typography>{t('EMPTY_NOTIFICATION_LIST')}</Typography>
          </Box>)
        }
      </Card>
    </Box>
  );
};
export default NotificationList;

import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import { SetPasswordData } from '../../models/userroles/users/SetPasswordData';
import userService from '../../repository/UserService';
import './SetPassword.scss';
import { useTranslation } from 'react-i18next';
import { ErrorHandler } from '../../utils/ErrorHandler';

/**
 *
 * @todo: change css to scss and use colors styles
 */

const SetPassword = () => {

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SetPasswordData>();
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);

  const TOKEN = queryParams.get('token');

  const handlePassword: SubmitHandler<SetPasswordData> = (data) => {
    userService.createPassword(TOKEN, data.password, data.confirmPassword).then((res) => {
      SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'), '<a href="/login">Go to Login</a>');
    }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
    });
  };

  return (

    <Grid container sx={{
      background: 'linear-gradient(120deg, #e3e3e3, #e3e3e3);',
      height: '100vh'
    }} justifyContent='center' alignItems='center'
          className='grid'>
      <Grid xs={7} xl={3} md={5} lg={4} sm={7} container className='form' direction={'column'}
            sx={{
              borderRadius: '1%',
              borderWidth: '3px',
              borderStyle: 'solid',
              borderColor: 'primary'
            }} justifyContent='center' alignItems='center'>
        <Grid item width='200px' justifyContent='center' sx={{
          mt: '40px',
          mb: '10px'
        }}>
          <Box className='text' justifyContent='center'>
            <Typography textAlign='center' variant='h6' sx={{ fontWeight: 'italic' }}>
              {t('SET_PASSWORD')}
            </Typography>
          </Box>
        </Grid>

        <form style={{ width: '100%' }} onSubmit={handleSubmit(handlePassword)}>
          <Grid item width='100%'>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-end',
              mb: '15px',
              ml: '35px',
              mr: '10px'
            }}>
              <EnhancedEncryptionIcon className='login-icons' sx={{
                color: 'primary',
                mr: 1,
                my: 0.5
              }}/>
              <TextField {...register('password', { required: true })} sx={{
                width: '75%',
                ml: '10px'
              }} type='password' id='input-with-sx-newPassword'
                         label={t('NEW_PASSWORD')} variant='standard' color='primary'/>
            </Box>
          </Grid>
          <Grid item width='100%'>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-end',
              my: '10px',
              ml: '35px',
              mr: '10px'
            }}>
              <LockResetIcon className='login-icons' sx={{
                color: 'primary',
                mr: 1,
                my: 0.5
              }}/>
              <TextField {...register('confirmPassword', { required: true })} sx={{
                width: '75%',
                ml: '10px'
              }} type='password'
                         id='input-with-sx-confirm-password' label={t('CONFIRM_PASSWORD')} variant='standard'
                         color='primary'/>
            </Box>
          </Grid>
          <Grid item width='100%'>
            <Box sx={{
              display: 'flex',
              alignItems: 'flex-end',
              ml: '70px',
              mr: '15px'
            }}>
              <Button type='submit' variant='contained'
                      sx={{
                        width: '85%',
                        mb: '50px',
                        mt: '35px',
                        background: 'primary',
                        '&:hover': { background: 'primary' }
                      }}>{t('LOGIN')}</Button>
            </Box>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SetPassword;

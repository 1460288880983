import {
  Card, Grid,
  IconButton,
  MenuItem,
  Popover,
  StandardTextFieldProps,
  TablePagination,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { Page } from '../../pagination/Page';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useDebounce from '../../utils/UseDebounce';
import './PaginatedMultiSelect.scss';
import AddIcon from '@mui/icons-material/Add';

type OnValueChangedFunction<T> = {
  (value: T[]): void;
};

type ValueMapper<T> = {
  (value: T): string;
};

type KeyMapper<T> = {
  (item: T): string;
};

type ItemMapper<T> = {
  (value: T): React.ReactElement;
};

type Fetcher<T> = {
  (page: number, size: number, filter: string | undefined): Promise<Page<T>>;
};

type LabelMapper<T> = {
  (items: T): string;
};

interface PaginatedSelectProps<T> {
  label?: string;
  filterLabel?: string;
  value: T[];
  valueMapper: ValueMapper<T>;
  keyMapper: KeyMapper<T>;
  itemMapper: ItemMapper<T>;
  labelMapper: LabelMapper<T>;
  dataFetcher: Fetcher<T>;
  onChange: OnValueChangedFunction<T>;
  closeOnSelect?: boolean;
  maxSelectedItems?: number;
  inputProps?: StandardTextFieldProps;
  placeholder?: string;
}

export function PaginatedMultiSelect<T>(props: PaginatedSelectProps<T>) {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [data, setData] = useState<Page<T>>(new Page([], 0));
  const [filter, setFilter] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    async function fetchAndSetData(): Promise<void> {
      const newData = await props.dataFetcher(
        page,
        size,
        filter ? filter : undefined
      );
      setData(newData);
    }

    if (open) {
      fetchAndSetData().then((_) => {});
    }
  }, [page, size, filter, open]);

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void {
    setPage(newPage);
  }

  function handleChangeSize(event: React.ChangeEvent<HTMLInputElement>): void {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  const setFilterDebounced = useDebounce(
    (filter: string) => {
      setFilter(filter);
      setPage(0);
    },
    500,
    null
  );

  function handleFilterChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setFilterValue(event.target.value);
    setFilterDebounced(event.target.value);
  }

  function openDropdown(event: React.MouseEvent<HTMLElement>): void {
    setAnchorEl(document.getElementById('paginated-select-text-field'));
  }

  function closeDropdown(): void {
    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  }

  function handleValueSelected(item: T): void {
    const updatedItems = props.value ? [...props.value] : [];
    const index = updatedItems.findIndex(
      (presentItem) =>
        props.valueMapper(presentItem) === props.valueMapper(item)
    );

    if (index === -1) {
      updatedItems.push(item);
    } else {
      updatedItems.splice(index, 1);
    }

    if (
      props.maxSelectedItems !== undefined &&
      updatedItems.length > props.maxSelectedItems
    ) {
      updatedItems.splice(0, updatedItems.length - props.maxSelectedItems);
    }

    props.onChange(updatedItems);

    if (props.closeOnSelect) {
      closeDropdown();
    }
  }

  function clearValue(): void {
    props.onChange([]);
  }

  function valueContains(item: T): boolean {
    const foundItem = props.value?.find(
      (presentItem) =>
        props.valueMapper(presentItem) === props.valueMapper(item)
    );
    return foundItem !== undefined;
  }

  function buildLabel(): string {
    return props.value.map((item) => props.labelMapper(item)).join(', ');
  }

  function isSelected(item: T): boolean {
    return valueContains(item);
  }

  return (
    <>
      <TextField
        id='paginated-select-text-field'
        variant='outlined'
        fullWidth
        className='paginated-select-text-field'
        style={{ marginTop: '20px' }}
        label={props.label}
        placeholder={props.placeholder}
        value={buildLabel()}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              {props.value.length > 0 && (
                <IconButton onClick={clearValue}>
                  <ClearIcon/>
                </IconButton>
              )}
            </>
          )
        }}
        {...props?.inputProps}
      />
      <Button className='add-button' onClick={openDropdown}><AddIcon/>ADD</Button>
      <Popover open={open} anchorEl={anchorEl} onClose={closeDropdown}>
        <Card
          sx={{
            minWidth: anchorEl?.offsetWidth
          }}
        >
          <div className='mx-3 mb-3 mt-3' id='filter'>
            <TextField
              autoFocus={true}
              variant='standard'
              fullWidth
              label={props.filterLabel ?? 'Filter'}
              value={filterValue}
              className='popover-text-field'
              onChange={handleFilterChanged}
            />
          </div>

          {data.content.map((item) => (
            <MenuItem
              key={props.keyMapper(item)}
              value={props.valueMapper(item)}
              onClick={() => handleValueSelected(item)}
              selected={isSelected(item)}
            >
              <div className={isSelected(item) ? 'me-3' : ''}>
                {props.itemMapper(item)}
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%'
                }}
              >
                {isSelected(item) && (
                  <CheckCircleIcon
                    className='check-circle-icon'
                    color='error'
                    style={{
                      marginLeft: 'auto'
                    }}
                  />
                )}
              </div>
            </MenuItem>
          ))}

          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component='div'
            count={data.totalElements}
            rowsPerPage={size}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeSize}
          />
        </Card>
      </Popover>
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { NotificationProps } from './interfaces/NotificationProps';

const initialState: NotificationProps = {
  state: false,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    autoHideDuration: 6000,
    message: '',
    variant: null
  }
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        ...action.payload
      };
    },
    showErrorMessage: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        message: action.payload,
        variant: 'error'
      };
    },
    showInfoMessage: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        message: action.payload,
        variant: 'info'
      };
    },
    showSuccessMessage: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        message: action.payload,
        variant: 'success'
      };
    },
    hideMessage: (state) => {
      state.state = false;
    }
  }
});

export const { hideMessage, showMessage, showSuccessMessage, showInfoMessage, showErrorMessage } = notificationSlice.actions;

export default notificationSlice.reducer;

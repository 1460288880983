import axios from '../axios/axios';
import { BlockingActionRequest } from '../models/clients/BlockingActionRequest';
import { BlockingActionResponse } from '../models/clients/BlockingActionResponse';
import { ClientDto } from '../models/clients/ClientDto';
import { ClientFilter } from '../models/clients/ClientFilter';
import { InviteClientRequest } from '../models/clients/InviteClientRequest';
import { ProcedureConversationMessage } from '../models/procedure/ProcedureConversationMessage';
import { ProcedureTypeShortName } from '../models/procedure/ProcedureTypeShortName';
import { ProcedureType } from '../pages/Clients/enums/ProcedureType';
import { Page } from '../pagination/Page';

export class ClientService {
  static async listClients(
    clientFilter: ClientFilter,
    pageNo: number,
    pageSize: number
  ): Promise<Page<ClientDto>> {
    const response = await axios.post<Page<ClientDto>>(
      '/client/list-all',
      clientFilter,
      {
        params: {
          pageNo,
          pageSize
        }
      }
    );
    const data = response.data;
    return new Page<ClientDto>(
      data.content.map((client) => new ClientDto(client)),
      data.totalElements
    );
  }

  static async getClientById(id: string): Promise<ClientDto> {
    const response = await axios.get(`/client/get/${id}`);
    return response.data;
  }

  static async getByEmail(email: string): Promise<ClientDto> {
    const response = await axios.get(`/client/get-by-email?email=` + email);
    return response.data;
  }

  static async initiateCommunication(kycProcedureId: number | string | null, amlProcedureId: number | string | null, message: string): Promise<any> {
    const response = await axios.post(`/client/initiate-communication`, {
      kycProcedureId,
      amlProcedureId,
      message
    });
    return response.data;
  }

  static async getConversationForProcedure(id: number | string, procedureType: string | ProcedureType): Promise<ProcedureConversationMessage[]> {
    const response = await axios.get(`/client/public/retrieve-chat/${id}`, {
      params: {
        procedureType: procedureType
      }
    });
    return response.data;
  }

  static async getMessage(token: string): Promise<any> {
    const response = await axios.get('/communication-message/public/message', { params: { token } });
    return response.data;
  }

  static async answerToMRLO(data: any) {
    return await axios.post('/client/public/answer', data);
  }

  static async getBlockingActionHistory(id: number): Promise<BlockingActionResponse[]> {
    const response = await axios.get('/client/blocking-action-history/' + id);
    return response.data;
  }

  static async blockClient(data: BlockingActionRequest) {
    return await axios.post('/client/block', data);
  }

  static async updateAutoGenerateAml(id: number, autoGenerateAml: boolean) {
    return await axios.put('/client/update-auto-generate-aml/' + id, null, { params: { autoGenerateAml } });
  }

  static async unblockClient(data: BlockingActionRequest) {
    return await axios.post('/client/unblock', data);
  }

  static async InviteNewClient(clientCreationRequest: InviteClientRequest, procedure: ProcedureTypeShortName): Promise<string> {
    const response = await axios.post('/client/public/initiate-procedure', clientCreationRequest, { headers: { procedure } });
    return response.data;
  }

  static async existsByEmail(email: string): Promise<boolean> {
    const response = await axios.get('/client/public/exists-by-email?email=' + email);

    return response.data;
  }

  static async totalClients(dateFrom?: string | null, dateTo?: string | null) {
    const response = await axios.get('/client/total', {
      params: {
        dateFrom,
        dateTo
      }
    });
    return response.data;
  }

  static async clientsByCountry(dateFrom?: string | null, dateTo?: string | null) {
    const response = await axios.get('/client/country', {
      params: {
        dateFrom,
        dateTo
      }
    });
    return response.data;
  }

  static async clientsByProcedureStatusNew(dateFrom?: string | null, dateTo?: string | null) {
    const response = await axios.get('/client/new', {
      params: {
        dateFrom,
        dateTo
      }
    });
    return response.data;
  }

  static async clientsByProcedureStatusNotNew(dateFrom?: string | null, dateTo?: string | null) {
    const response = await axios.get('/client/not-new', {
      params: {
        dateFrom,
        dateTo
      }
    });
    return response.data;
  }

  static async monthlyOnboardStats(month?: string | null, dateFrom?: string | null, dateTo?: string | null) {
    const response = await axios.get('/client/monthly-onboard-stats', {
      params: {
        month,
        dateFrom,
        dateTo
      }
    });
    return response.data;
  }

  static async clientTypeStats(dateFrom?: string | null, dateTo?: string | null) {
    const response = await axios.get('/client/client-type-stats', {
      params: {
        dateFrom,
        dateTo
      }
    });
    return response.data;
  }
}
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import React from "react";
import { LightTooltipProps } from './LightTooltipProps';

const LightTooltip = ({ title, children, className }: LightTooltipProps) => {
  const LightTooltip = styled(({ className, ...props }: LightTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 12,
    },
  }));

  return (
    <LightTooltip
      title={title}
      followCursor={true}
      arrow={true}
      className={className}
    >
      {children}
    </LightTooltip>
  );
};

export default LightTooltip;

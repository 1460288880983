import { Box, Card, Grid, Select, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { slice } from 'lodash';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import ShowMore from 'react-show-more';
import FileUploadInput from '../../../../components/FormFIelds/FIleUploadInput';
import { FullPageLoadingCircle } from '../../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import { NoteConstants } from '../../../../models/notes/NoteConstants';
import { NoteCreationRequest } from '../../../../models/notes/NoteCreationRequest';
import { NoteDto } from '../../../../models/notes/NoteDto';
import { NoteTopicDto } from '../../../../models/notes/NoteTopicDto';
import { FilePrivateService } from '../../../../repository/FilePrivateService';
import { FileService } from '../../../../repository/FileService';
import NoteService from '../../../../repository/NoteService';
import { DateTimeUtils } from '../../../../utils/DateTimeUtils';
import { ProcedureType } from '../../enums/ProcedureType';
import './Note.scss';
import { NoteProps } from './NoteProps';
import { useTranslation } from 'react-i18next';
import SwalAlert from 'components/SwalAlert/SwalAlert';

export function Note(props: NoteProps) {
  const [notes, setNotes] = useState<NoteDto[]>([]);
  const [note, setNote] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [showSinner, setShowSpinner] = useState<boolean>(false);
  const [index, setIndex] = useState(3);
  const initialNotes = slice(notes, 0, index);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [noteTopics, setNoteTopics] = useState<NoteTopicDto[]>();
  const [chosenNoteTopic, setChosenNoteTopic] = useState<string>('');
  const [topic, setTopic] = useState<string>('');
  const { t } = useTranslation();
  const disableButton = chosenNoteTopic === '' || note === '' || (chosenNoteTopic === NoteConstants.OTHER && topic === '');

  const writeNote = (e: FormEvent) => {
    e.preventDefault();
    const filePromises = files.map((file: File) => getFileDto(file));
    Promise.all(filePromises).then((fileDtos: any) => {
      const noteCreationRequest: NoteCreationRequest = {
        amlProcedureId: props.procedureType === ProcedureType.AML_PROCEDURE ? props.procedureId : null,
        kycProcedureId: props.procedureType === ProcedureType.KYC_PROCEDURE ? props.procedureId : null,
        note: note,
        files: fileDtos,
        topic: chosenNoteTopic === NoteConstants.OTHER ? topic : null,
        noteTopic: chosenNoteTopic!
      };
      setShowSpinner(true);
      NoteService.create(noteCreationRequest).then(() => {
        setNote('');
        setTopic('');
        setChosenNoteTopic('');
        setShowSpinner(false);
        setFiles([]);
        getProcedureNotes();
      }).catch(() => setShowSpinner(false));
    });
  };

  const getFileDto = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          name: file.name,
          mimeType: file.type,
          base64Content: reader.result
        });
    });
  };

  const handleFileChange = (files: File[]) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; 
    const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);
  
    if (oversizedFiles.length > 0) {
      SwalAlert.errorAlert(t('FILE_TOO_LARGE'), t('FILE_SIZE_LIMIT', { size: '10 MB' }));
      return;
    }
    setFiles(files);
  };

  const handleCloseSpinner = () => {
    setShowSpinner(false);
  };

  const getProcedureNotes = () => {
    if (props.procedureType === ProcedureType.KYC_PROCEDURE) {
      NoteService.allByKycProcedure(props.procedureId).then((res) => {
        setNotes(res);

        if (res.length < index) {
          setIsCompleted(true);
        }
      });
    } else if (props.procedureType === ProcedureType.AML_PROCEDURE) {
      NoteService.allByAmlProcedure(props.procedureId).then((res) => {
        setNotes(res);

        if (res.length < index) {
          setIsCompleted(true);
        }
      });
    }
  };

  const loadMore = () => {
    const count = index + 3;
    setIndex(count);
    if (count >= notes.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  useEffect(() => {
    getProcedureNotes();
    NoteService.noteTopics().then((res) => {
      setNoteTopics(res!);
    });
  }, []);

  return (
    <>
      <Card className='post-note-card'>
        <form onSubmit={writeNote} className='note-form'>
          <Box>
            <FormControl fullWidth className='note-topic'>
              <InputLabel>{t('TOPIC_DESC')}</InputLabel>
              <Select
                id='topic'
                value={chosenNoteTopic}
                label={t('TOPIC_DESC')}
                onChange={(event) => setChosenNoteTopic(event.target.value)}
              >
                {
                  noteTopics?.map((e, i) => {
                    return (
                      <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
            {chosenNoteTopic === NoteConstants.OTHER &&
              <TextField
                id='topic'
                label={t('Topic')}
                className='note-topic'
                multiline
                fullWidth={true}
                color={'primary'}
                value={topic}
                maxRows={4}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTopic(e.target.value)}>
              </TextField>
            }
            <TextField
              id='noteField'
              label={t('NEW_NOTE')}
              multiline
              fullWidth={true}
              color={'primary'}
              value={note}
              maxRows={4}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}>
            </TextField>
            <Box alignItems={'center'} marginTop={3} marginBottom={2}>
              <FileUploadInput
                label={t('UPLOAD_FILES')}
                classes={'file-input-wrapper'}
                selectedFiles={files}
                onFileChange={handleFileChange}
                index={0}
                name={'files'}/>
            </Box>
            <Box textAlign={'end'} marginTop={3}>
              <Button variant='contained' color='primary' className='addButton' type={'submit'} disabled={disableButton}>
                {t('POST')}
              </Button>
            </Box>
          </Box>
        </form>
      </Card>
      {notes?.length === 0 && <div className='empty-notes-text'>{t('PROCEDURE_DOES_NOT_COINTAIN_NOTES')}</div>}
      {initialNotes?.map((note, index: number) => {
        return (
          <Card className='note' key={index}>
            <Grid container>
              <Grid item xs={10}>
                <div className='note-smaller-font'>{t('CREATED_BY')}: {note?.createdByUser?.email}</div>
                {/* <div className='note-smaller-font'>Modified by: {note?.modifiedByUser?.email}</div> */}
                <br/>
                <Typography><b>{t('TOPIC_DESC')}:</b> {note?.noteTopic?.label}</Typography>
                {note.topic && <Typography><b>{t('TOPIC')}:</b> {note?.topic}</Typography>}
                <br/>
                <ShowMore
                  lines={2}
                  more={t('SHOW')}
                  less={t('HIDE')}
                  anchorClass=''>
                  {note.note}
                </ShowMore>
              </Grid>
              <Grid item xs={2}>
                <div className='note-date note-smaller-font'>
                  {DateTimeUtils.formatDate(note?.dateCreated.toString())}
                </div>
              </Grid>
            </Grid>
            {note?.attachments.map((attach, index: number) => {
              return (
                <Chip key={index} label={attach?.fileDto?.name} className='note-attach-chip'
                      onClick={() => FilePrivateService.downloadDocuments(+attach?.fileDto?.id!, attach?.fileDto?.name)}/>
              );
            })}
          </Card>
        );
      })}
      {notes.length > 0 && !isCompleted && <Button color='primary' onClick={loadMore} className='note-load-more-button'>{t('LOAD_MORE')}</Button>}
      <FullPageLoadingCircle loading={showSinner} handleClose={handleCloseSpinner}/>
    </>
  );
}
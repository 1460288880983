import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessfulDocumentUpload = () => {
  return(
    <>
      <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
        <Grid container>
          <Grid item xs={12} marginX={3}>
            <Typography variant='h6' fontWeight='bold' textAlign='center'>SUCCESSFULLY UPLOADED DOCUMENTS</Typography>
          </Grid>
          <Grid item xs={12} textAlign='center' marginY={5}>
            <CheckCircleIcon sx={{fontSize: '300px', color: '#0e387a'}}/>
          </Grid>
          <Grid item xs={12} marginX={3}>
            <Typography variant='h6' fontWeight='bold' textAlign='center'>PLEASE CONTINUE FILLING OUT YOUR APPLICATION ON A DESKTOP DEVICE</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default SuccessfulDocumentUpload
import React from 'react'
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NotMultipleSelectChipProps } from './NotMultipleSelectChipProps';

const NotMultipleSelectChip = (props: NotMultipleSelectChipProps) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    props.handleChangeSelect(value);
  };

  const isOptionAvailable = props.options.some(option => option.label === props.selectedOptions);

  return (
    <FormControl fullWidth>
      <InputLabel sx={{}} color={props.color ? props.color : 'secondary'} id="demo-simple-select-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={isOptionAvailable ? props.selectedOptions : ''}
        label={props.label}
        color={props.color ? props.color : 'secondary'}
        onChange={handleChange}
        required={true}
      >
        {props.options.map((option) => (
          <MenuItem key={option.label} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NotMultipleSelectChip;

import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import { Option } from '../../models/shared/Option';
import CountryService from '../../repository/CountryService';
import { BootstrapDialog, Transition } from '../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import SwalAlert from '../SwalAlert/SwalAlert';
import { CountriesDetailsProps } from './CountriesDetailsProps';
import { useTranslation } from 'react-i18next';

const CountriesDetails = ({ country, onChange, countriesRiskList }: CountriesDetailsProps) => {
  const [selectedCountriesRisk, setSelectedCountriesRisk] = useState<string>();
  const [open, setOpen] = React.useState(false);
  const [riskScore, setRiskScore] = useState<number | null>(country.risk_score);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedCountriesRisk(country.countryRisk)
  }, [country]);


  const handleChange = (e: SelectChangeEvent) => {
    setSelectedCountriesRisk(e.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onChange();
  };

  const submitRiskScoreChange = () => {
    country.countryRisk = selectedCountriesRisk;
    country.risk_score = riskScore;
    CountryService.changeRiskScore(country).then(() => {
      handleClose();
      SwalAlert.successfulAlert(t('SUCCESS'), t('CHANGE_REQUEST_CREATED'));
    }).catch(() => {
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
  };

  return (
    <Box className='box-style back'>
      <Box component='div'
           className={`form-control ${country.countryRisk === 'PROHIBITED' ? 'prohibited-country-wrapper' : ''}`}>
        <div>{country.countryName}</div>
        <EditIcon
          sx={{ marginLeft: '10px', cursor: 'pointer' }}
          onClick={handleOpen}/>
      </Box>
      <BootstrapDialog
        style={{ fontFamily: 'Poppins' }}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        className='modalWrapper'
        TransitionComponent={Transition}
        maxWidth={'md'}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}>
          Edit risk score
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1} padding={3}>
            <Grid item style={{ marginTop: '5px' }}>
              <span>{country.countryName + ' '} {t('HAS_A_RISK_SCORE_OF')}</span>
            </Grid>
            <Grid item>
              <TextField
                type='number'
                color={'primary'}
                value={riskScore}
                onChange={(e) => {
                  setRiskScore(+e.target.value);
                }}
                variant='standard'
                sx={{ ml: '15px', width: '130px' }}
                InputProps={{ inputProps: { min: 0 } }}/>
            </Grid>
          </Grid>
          <Grid container spacing={1} padding={3} justifyContent={'end'} alignItems={'center'}>
            <Grid item style={{ marginTop: '5px' }}>
              <span>{country.countryName + ' '} {t('HAS_A_RISK')}</span>
            </Grid>
            <Grid item>
              <Select
                variant='standard'
                value={selectedCountriesRisk}
                sx={{ width: '200px' }}
                onChange={handleChange}
                label='Age'>
                {countriesRiskList.map((countryRisk: Option) =>
                  <MenuItem value={countryRisk.id} key={countryRisk.id}>
                    {countryRisk.label}
                  </MenuItem>
                )}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='inherit'
            className={'modalCloseButton baseFontFamily'}
            onClick={handleClose}>
            {t('CLOSE')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={submitRiskScoreChange}>
            {t('SUBMIT')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};
export default CountriesDetails;

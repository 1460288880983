import { Dayjs } from 'dayjs';

export class ClientDto {
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  KYCStatus: string | null;
  AMLStatus: string | null;
  KYCStatusLabel: string;
  AMLStatusLabel: string;
  phoneNumber: string;
  address: string | undefined;
  birthDate: Dayjs;
  autoGenerateAml: boolean;
  kycProcedureType: string;
  clientType: string;

  constructor(json: ClientDto) {
    this.id = json.id;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.email = json.email;
    this.KYCStatus = json.KYCStatus;
    this.AMLStatus = json.AMLStatus;
    this.KYCStatusLabel = json.KYCStatusLabel;
    this.AMLStatusLabel = json.AMLStatusLabel;
    this.phoneNumber = json.phoneNumber;
    this.address = json.address;
    this.birthDate = json.birthDate;
    this.autoGenerateAml = json.autoGenerateAml;
    this.kycProcedureType = json.kycProcedureType;
    this.clientType = json.clientType;
  }

}
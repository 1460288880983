import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ActiveTCTermGroupWithSectionResponseDto
} from '../../models/terms_conditions/ActiveTCTermGroupWithSectionResponseDto';
import { TermsConditionService } from '../../repository/TermsConditionService';
import StyledTextRenderer from '../StyledTextRenderer/StyledTextRenderer';
import './TermsAndConditionsComponent.scss';

export function TermsAndConditionsComponent() {
  const [termsConditions, setTermsConditions] =
    useState<ActiveTCTermGroupWithSectionResponseDto[]>();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const { id, token } = useParams();
  async function findAllTermsAndConditionsByEntityIdAndClientToken(): Promise<void> {
    await TermsConditionService.findAllByEntityIdAndClientToken(id, token).then(
      (response) => {
        setTermsConditions(response);
      }
    );
  }

  useEffect(() => {
    findAllTermsAndConditionsByEntityIdAndClientToken().then((_) => {});
  }, []);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    termId: number
  ) => {
    const isChecked = event.target.checked;
    setSelectedCheckboxes((prevSelected: number[]) => {
      if (isChecked) {
        return [...prevSelected, termId];
      } else {
        return prevSelected.filter((id) => id !== termId);
      }
    });
  };

  return (
    <Box width={"100%"} className="check-box-list">
      {termsConditions?.map((term) => (
        <div key={term.id} className="check-box-list-div">
          <Box width={"100%"}>
            {term?.sections?.map((section) => (
              <Paper elevation={1} key={section.id}>
                <FormControlLabel
                  className="list-item-paper"
                  control={
                    <Checkbox
                      className="check-box-icon"
                      onChange={(event) =>
                        handleCheckboxChange(event, term?.id)
                      }
                      icon={<CheckBoxOutlineBlank />}
                    />
                  }
                  label={
                    <div className="check-box-label">
                      <StyledTextRenderer html={section.content} />
                    </div>
                  }
                />
              </Paper>
            ))}
          </Box>
        </div>
      ))}
    </Box>
  );
}

import axios from '../axios/axios';
import { UserData } from '../models/userroles/users/UserData';
import { UserFilterData } from '../models/userroles/users/UserFilterData';
import { UserRequestDto } from '../models/userroles/users/UserRequestDto';

const UserService = {
  addUser: (data: UserData) => {
    return axios.post('/users/signUp', data);
  },
  editUser: (id: number, data: UserData) => {
    return axios.post(`/users/update/${id}`, data);
  },
  editUserProfileInfo: (id: number, data: UserData) => {
    return axios.post(`/users/update-profile-info/${id}`, data);
  },
  getAllUser: (
    pageNo: number,
    pageSize: number,
    userFilterData: UserFilterData
  ) => {
    return axios.post('/users/all-users?', userFilterData, {
      params: {
        pageNo: pageNo,
        pageSize: pageSize
      }
    });
  },
  createPassword: (
    token: string | null,
    password: string,
    confirmPassword: string
  ) => {
    return axios
      .post(
        'users/public/create-password',
        {},
        {
          headers: {
            password,
            confirmPassword
          },
          params: { token }
        }
      );
  },
  resetPassword: (
    token: string | null,
    password: string,
    confirmPassword: string
  ) => {
    return axios
      .post(
        'users/public/reset-password',
        {},
        {
          headers: {
            password,
            confirmPassword
          },
          params: { token }
        }
      );
  },

  getAllUsersByRole: (userRequestDto?: UserRequestDto) => {
    return axios.post('/users/get-users-with-role', userRequestDto);
  },

  setRoleApprover: (id: number) => {
    return axios.post(`/users/update/role-approver/${id}`);
  },
  changePasswordByLoggedUser: (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    return axios.post('/users/change-password', {}, {
      headers: {
        newPassword,
        confirmNewPassword
      }
    });
  },
  deleteUser: (id: number) => {
    return axios.post(`/users/delete/${id}`);
  },
  getUserProfileInfo: () => {
    return axios.get('/users/profile-info');
  },
  forgotPassword: (email: string) => {
    return axios.post('/users/public/forgot-password', null, {
      params: {
        email: email
      }
    });
  }

};
export default UserService;

import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TokenExtensionProps } from './TokenExtensionProps';
import { useTranslation } from 'react-i18next';
import './TokenExtension.scss';

const TokenExtension = (props: TokenExtensionProps) => {
  const { t } = useTranslation();

  return (
    <Grid id='token-extension'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className='date-picker'
          label={t('EXTEND_TO')}
          value={props.extendTo}
          format='MMM D, YYYY'
          minDate={dayjs()}
          onChange={(newValue) => props.setExtendTo(newValue!)}

        />
      </LocalizationProvider>
    </Grid>
  );
};

export default TokenExtension;
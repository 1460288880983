import React from 'react'
import ChangePassword from "./ChangePassword/ChangePassword";
import {Box} from "@mui/material";
import ProfileInfo from "./ProfileInfo/ProfileInfo";

const Profile = () => {
    return (
        <Box>
            <ProfileInfo/>
            <ChangePassword/>
        </Box>
    )
}
export default Profile;
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import * as React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props: any) {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    props.handdleChangeSelect(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    // look for the `Enter` keyCode
    props.handleKeyPress();
  };

  return (
    <FormControl
      sx={{ m: 1, width: props.customWidth }}
      focused={props.focused}
      size={props.size}
      color={props.customColor}
    >
      <InputLabel id="demo-multiple-chip-label" color={"primary"}>
        {" "}
        {props.titleName}{" "}
      </InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        color={"primary"}
        value={props.selectedValues}
        onChange={handleChange}
        // variant="standard"
        required={props.required}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map(
              (valueId: any) =>
                props.elements?.find((e: any) => e?.id === valueId) && (
                  <Chip
                    key={valueId}
                    // sx={{color:"white"}}
                    label={
                      props.elements?.find((e: any) => e?.id === valueId)?.label
                    }
                  />
                )
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {props.elements.map((e: any) => (
          <MenuItem
            key={e.id}
            value={e.id}
            //   style={getStyles(name, personName, theme)}
          >
            {e?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import RemoveIcon from '@mui/icons-material/Remove';
import { Grid, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { ShareholderRequest } from '../../../models/legal_entity/ShareholderRequest';
import { ShareholderRoleDto } from '../../../models/legal_entity/ShareholderRoleDto';
import { ShareholderTypeDto } from '../../../models/legal_entity/ShareholderTypeDto';
import ShareholderService from '../../../repository/ShareholderService';
import { ShareholderProps } from './ShareholderProps';
import { useTranslation } from 'react-i18next';
import './Shareholder.scss';

export function Shareholder(
  { shareholder, index, onChange, removeItem }: ShareholderProps
) {
  const { t } = useTranslation();
  const [shareholderRequest, setShareholderRequest] = useState<ShareholderRequest>(shareholder);
  const [shareholderTypes, setShareholderTypes] = useState<ShareholderTypeDto[]>([]);
  const [shareholderRoles, setShareholderRoles] = useState<ShareholderRoleDto[]>([]);

  useEffect(() => {
    onChange(index, shareholderRequest);
  }, [shareholderRequest]);

  useEffect(() => {
    ShareholderService.allShareholderTypes().then((res) => {
      setShareholderTypes(res);
    });
    ShareholderService.allShareholderRoles().then((res) => {
      setShareholderRoles(res);
    });
  }, []);

  const handleChangeShareholderType = (event: SelectChangeEvent) => {
    setShareholderRequest(prevState => ({ ...prevState, shareholderType: event.target.value }));
  };
  const handleChangeShareholderRole = (event: SelectChangeEvent) => {
    setShareholderRequest(prevState => ({ ...prevState, shareholderRole: event.target.value }));
  };
  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={10}>
        <Typography>{t('SHAREHOLDER') + ' ' + (index + 1)}</Typography>
      </Grid>
      <Grid item xs={2}>
        <RemoveIcon onClick={() => removeItem(index)} className={'shareholder-remove-icon'} fontSize='large'/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('FIRST_NAME')}
          fullWidth={true}
          value={shareholder.firstName}
          onChange={(event) => setShareholderRequest(prevState => ({ ...prevState, firstName: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('LAST_NAME')}
          fullWidth={true}
          value={shareholder.lastName}
          onChange={(event) => setShareholderRequest(prevState => ({ ...prevState, lastName: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('EMAIL')}
          fullWidth={true}
          value={shareholder.email}
          onChange={(event) => setShareholderRequest(prevState => ({ ...prevState, email: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('PHONE_NUMBER')}
          fullWidth={true}
          value={shareholder.phoneNumber}
          onChange={(event) => setShareholderRequest(prevState => ({ ...prevState, phoneNumber: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormControl fullWidth>
          <InputLabel>{t('SHAREHOLDER_TYPE')}</InputLabel>
          <Select
            id='shareholderType'
            value={shareholder.shareholderType}
            label={t('SHAREHOLDER_TYPE')}
            onChange={handleChangeShareholderType}
          >
            {
              shareholderTypes.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormControl fullWidth>
          <InputLabel>{t('SHAREHOLDER_ROLE')}</InputLabel>
          <Select
            id='shareholderRole'
            value={shareholder.shareholderRole}
            label={t('SHAREHOLDER_ROLE')}
            onChange={handleChangeShareholderRole}
          >
            {
              shareholderRoles.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.name}>{e.label}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
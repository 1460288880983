export class TCSectionResponseDto {
    id: number;
    content: string;
    deleted: boolean;
  
    constructor(json: TCSectionResponseDto) {
      this.id = json.id;
      this.content = json.content;
      this.deleted = json.deleted;
    }
  }
  
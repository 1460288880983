import { SxProps } from '@mui/system';
import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeRequestDetailsResponse } from '../../../models/changeRequests/ChangeRequestDetailsResponse';

const UpdateCountryRiskChangeRequestDetails = ({
  changeRequestDetails,
  changedFields
}: { changeRequestDetails: ChangeRequestDetailsResponse | undefined; changedFields: any }) => {
  const { t } = useTranslation();

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)'
  };

  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes From</Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('COUNTRY_NAME')}: {(changedFields.countryName !== undefined) ? changedFields.countryName : (changeRequestDetails?.object && 'countryName' in changeRequestDetails.object ? changeRequestDetails.object.countryName : '')}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('RISK_SCORE')}: {(changedFields.risk_score !== undefined) ? changedFields.risk_score : (changeRequestDetails?.object && 'risk_score' in changeRequestDetails.object ? changeRequestDetails.object.risk_score : '')}
            </Typography>
            <Typography variant='body1'>
              {t('RISK')}: {(changedFields.countryRisk !== undefined) ? changedFields.countryRisk : (changeRequestDetails?.object && 'countryRiskLabel' in changeRequestDetails.object ? changeRequestDetails.object.countryRiskLabel : '')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes To</Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('COUNTRY_NAME')}: {(changedFields.countryName !== undefined) ? changedFields.countryName : (changeRequestDetails?.object && 'countryName' in changeRequestDetails.object ? changeRequestDetails.object.countryName : '')}
            </Typography>
            <Typography variant='body1' sx={{ marginBottom: 1 }}>
              {t('RISK_SCORE')}: {(changedFields.risk_score !== undefined) ? changedFields.risk_score : ''}
            </Typography>
            <Typography variant='body1'>
              {t('RISK')}: {(changedFields.countryRisk !== undefined) ? changedFields.countryRisk : (changeRequestDetails?.object && 'countryRiskLabel' in changeRequestDetails.object ? changeRequestDetails.object.countryRiskLabel : '')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UpdateCountryRiskChangeRequestDetails;
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import './NotificationPopper.scss';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, Button, Grow, MenuList, Paper, Popper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../../models/notifications/Notification';
import { NotificationService } from '../../repository/NotificationService';
import { DateTimeUtils } from '../../utils/DateTimeUtils';
import { useTranslation } from 'react-i18next';

const NotificationPopper = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [numberOfUnseen, setNumberOfUnseen] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const paperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    NotificationService.getAllNotifications()
      .then((data) => {
        const numberOfUnseen = data.filter(el => !el.isSeen).length;
        setNumberOfUnseen(numberOfUnseen);
        setNotifications(data);
      });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onHandleNotificationClick = (notification: Notification) => {
    NotificationService.markNotificationAsSeen(notification.id).then(() => {
      if (notification.link) {
        navigate(notification.link);
      }
      setOpen(false);
      getAllNotifications();
    });
  };

  const markAllAsSeen = () => {
    NotificationService.markAllNotificationsAsSeen().then(() => {
      getAllNotifications();
    });
    setNumberOfUnseen(0);
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (paperRef.current && !paperRef.current.contains(event.target as Node)
        && anchorRef.current && !anchorRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleMouseDown, true);

    return () => {
      document.removeEventListener('click', handleMouseDown, true);
    };
  }, [anchorRef, paperRef]);

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const renderNotification = (notification: Notification) => {
    return <React.Fragment key={notification.id}>
      <ListItem onClick={() => onHandleNotificationClick(notification)}
                className={`notification ${notification.isSeen ? '' : 'new-notification'}`}>
        <ListItemText primary={notification.content}
                      secondary={<Typography variant={'caption'} textAlign='right'>
                        {DateTimeUtils.formatDateTime(notification.dateCreated)}
                      </Typography>}>
        </ListItemText>
      </ListItem>
      <Divider/>
    </React.Fragment>;
  };

  const renderNotifications = () => {
    return <List dense>
      {
        notifications.map(notification => renderNotification(notification))
      }
    </List>;
  };

  return (
    <div>
      <IconButton ref={anchorRef} onClick={handleToggle}>
        <Badge badgeContent={numberOfUnseen} color='secondary'>
          <CircleNotificationsIcon className='notification-icon'/>
        </Badge>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='top-start'
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            className={'asd'}
            style={{
              width: '30rem',
              height: '60vh',
              overflowY: 'auto',
              transformOrigin: placement === 'top-start' ? 'right top' : 'right top'
            }}>
            <Paper ref={paperRef}>
              <Box className='notification-popper-title'>
                <Typography variant='h6' display='flex' alignItems='center' color='primary'>
                  <NotificationsIcon/> {t('NOTIFICATIONS')}
                </Typography>
                <Button variant='outlined' onClick={markAllAsSeen}>
                  {t('MARK_ALL_AS_SEEN')}
                </Button>
              </Box>
              <MenuList
                autoFocusItem={open}
                id='composition-menu'
                aria-labelledby='composition-button'
                onKeyDown={handleListKeyDown}>
                {notifications?.length > 0 ? (
                  renderNotifications()
                ) : (
                  <Box padding={3} textAlign='center'>
                    <Typography>{t('EMPTY_NOTIFICATION_LIST')}</Typography>
                  </Box>)
                }
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
export default NotificationPopper;

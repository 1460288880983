import { Grid, Typography } from '@mui/material';
import { AmlyzeResponse } from '../../../models/procedure/AmlyzeResponse';
import { useTranslation } from 'react-i18next';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';

const AmlyzeResponseView = (props: { amlyzeResponse?: AmlyzeResponse }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography><b>{t('AMLYZE_CLIENT_ID')}:</b> {props.amlyzeResponse?.customerExternalId}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('DATE_CREATED')}:</b> {DateTimeUtils.formatDate(props.amlyzeResponse?.dateCreated!)}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('DATE_CREATED')}:</b> {DateTimeUtils.formatDate(props.amlyzeResponse?.dateModified!)}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('EVENT_TIME')}:</b> {DateTimeUtils.formatDate(props.amlyzeResponse?.eventTime!)}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('EVENT_TYPE')}:</b> {props.amlyzeResponse?.eventType}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('SANCTIONED')}:</b> {props.amlyzeResponse?.sanctioned === true ? 'True' : props.amlyzeResponse?.sanctioned === false ? 'False' : '/'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('PEP')}:</b> {props.amlyzeResponse?.pep === true ? 'True' : props.amlyzeResponse?.pep === false ? 'False' : '/'}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t(
          'IS_ADVERSE_MEDIA')}:</b> {props.amlyzeResponse?.isAdverseMedia === true ? 'True' : props.amlyzeResponse?.isAdverseMedia === false ? 'False' : '/'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('RISK_LEVEL')}:</b> {props.amlyzeResponse?.riskLevel}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('INITIAL_RISK_LEVEL')}:</b> {props.amlyzeResponse?.initialRiskLevel}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('FINAL_RISK_LEVEL')}:</b> {props.amlyzeResponse?.finalRiskLevel}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('STATUS')}:</b> {props.amlyzeResponse?.status}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('APPROVED_AT')}:</b> {DateTimeUtils.formatDate(props.amlyzeResponse?.approvedAt!)}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t('DECISION_TYPE')}:</b> {props.amlyzeResponse?.decisionType}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography><b>{t(
          'SCREENING_MATCH')}:</b> {props.amlyzeResponse?.screeningMatch === true ? 'True' : props.amlyzeResponse?.screeningMatch === false ? 'False' : '/'}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default AmlyzeResponseView;
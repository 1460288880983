import { Box, Card, CardContent, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ClientDto } from '../../models/clients/ClientDto';
import { ClientService } from '../../repository/ClientService';
import './client-details-page.scss';
import { DateTimeUtils } from '../../utils/DateTimeUtils';
import ClientsActiveProcedure from './active-procedure/ClientsActiveProcedure';
import ProcedureHistory from './history-procedure/ProcedureHistory';
import { useTranslation } from 'react-i18next';

const ClientDetailsPage = () => {
  const [selectedClient, setSelectedClient] = useState<ClientDto>();
  const [tabKey, setTabKey] = useState<number>(0);
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (params.id) {
      getSelectedClient();
      if (location.state && location.state.isHistory) {
        setTabKey(1);
      }
    }
  }, [params.id]);

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  const getSelectedClient = () => {
    ClientService.getClientById(params.id!).then((response) => {
      setSelectedClient(response);
    });
  };

  const handleChangeTab = (event: React.SyntheticEvent, value: any) => {
    setTabKey(value);
  };

  return (
    <Box>
      <Box>
        <Typography variant='h4'>{selectedClient?.firstName + ' ' + selectedClient?.lastName}</Typography>
        <Box className='contact-info'>
          <Typography variant='subtitle1' color='gray'>{t('EMAIL')}: {selectedClient?.email ?? '/'}</Typography>
          <Typography variant='subtitle1' color='gray'>{t('PHONE')}: {selectedClient?.phoneNumber ?? '/'}</Typography>
          <Typography variant='subtitle1' color='gray'>{t('BIRTH_DATE')}: {selectedClient?.birthDate ? DateTimeUtils.formatDate(
            selectedClient?.birthDate.toString()) : '/'}</Typography>
        </Box>

      </Box>
      <Tabs
        value={tabKey}
        onChange={handleChangeTab}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable terms and conditions entities'>
        <Tab label={'Active procedure'} {...a11yProps(0)} />
        <Tab label={'History'} {...a11yProps(1)} />
      </Tabs>
      <Card variant='outlined'>
        <CardContent>
          {tabKey === 0 && <ClientsActiveProcedure clientId={params.id!} clientName={selectedClient?.firstName!}
                                                   clientLastName={selectedClient?.lastName!}/>}
          {tabKey === 1 && <ProcedureHistory clientId={params.id!} clientName={selectedClient?.firstName!} clientLastName={selectedClient?.lastName!}/>}
        </CardContent></Card>
    </Box>
  );
};
export default ClientDetailsPage;

import { Box, Typography, Card, CardContent } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ClientService } from '../../repository/ClientService';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const StoppedOnboardingChart = () => {

  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();

  const [newClientsNumber, setNewClientsNumber] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    clientsByProcedureStatusNew();
  }, [dateFrom, dateTo]);

  const clientsByProcedureStatusNew = async () => {
    const formattedDateFrom = dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : null;
    const formattedDateTo = dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : null;

    ClientService.clientsByProcedureStatusNew(formattedDateFrom, formattedDateTo).then((data) => {
      setNewClientsNumber(data);
    });
  };

  const handleDateFrom = (newDate: Date | null) => {
    if (newDate != null) setDateFrom(newDate);
  };

  const handleDateTo = (newDate: Date | null) => {
    if (newDate != null) setDateTo(newDate);
  };

  const stoppedOnboardingChartStyles: SxProps = {
    mt: {sm: 5 , md: 5},
  };

  const typographyStyles: SxProps = {
    fontSize: { xs: 20, sm: 30, md: 45, lg: 30, xl: 35 }
  };

  const boxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' },
    marginTop: {xs:2 , sm: 3}
  };

  const datePickerBoxStyles: SxProps = {
    marginRight: { sm: 3 },
    width: { xs: '200px', sm: '200px', md: '300px', lg: '200px', xl: '270px' }
  };

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '2px solid rgba(0, 0, 0, 0.1)',
  };

  return (
    <>
      <Box>
      <Card sx={cardStyles}>
        <CardContent>
          <Box sx={stoppedOnboardingChartStyles}>
            <Typography sx={typographyStyles} fontWeight='bold' marginTop={{ xs: 3, sm: 0 }} textAlign={'center'}>
              {t('STOPPED_ONBOARDING')}: {newClientsNumber}
            </Typography>
          </Box>
          <Box sx={boxStyles}>
            <Box sx={datePickerBoxStyles}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label='Date from' onChange={handleDateFrom}/>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box sx={datePickerBoxStyles}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label='Date to' onChange={handleDateTo}/>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
        </CardContent>
      </Card>
      </Box>
    </>
  );
};

export default StoppedOnboardingChart;
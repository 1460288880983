import axios from '../axios/axios';
import { Notification } from '../models/notifications/Notification';

export class NotificationService {
  static async getAllNotifications(): Promise<Notification[]> {
    const response = await axios.get(`/notification/list-all`);
    return response.data;
  }

  static async markNotificationAsSeen(id: number) {
    await axios.put(`/notification/${id}/mark-as-seen`);
  }

  static async markAllNotificationsAsSeen() {
    await axios.put(`/notification/mark-all-as-seen`);
  }
}

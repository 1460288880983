import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React from 'react';
import { AutocompleteNotMultipleSelectChipProps } from './AutocompleteNotMultipleSelectChipProps';
import { useTranslation } from 'react-i18next';

interface Option {
    id: number;
    label: string;
};

const AutocompleteNotMultipleSelectChip: React.FC<AutocompleteNotMultipleSelectChipProps> = (props) => {
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<{}>, value: Option | null) => {
        if (value !== null) {
            props.handleChangeSelect(value.id)
        } else {
            props.handleChangeSelect(undefined)
        }
    };

    return (
        <FormControl fullWidth>
            <Autocomplete
                id="demo-simple-select"
                options={props.options}
                getOptionLabel={props.getOptionLabel || ((option: any) => option.label)}
                value={
                    props.selectedOptions !== undefined
                        ? props.options.find((option : any) => option.id === props.selectedOptions) || null
                        : null
                }
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        required={props.required}
                        {...params}
                        label={props.label}
                        error={props.showError && props.selectedOptions === undefined}
                        helperText={props.showError && props.selectedOptions === undefined && t('REQUIRED_FIELD')}
                        color={props.color ? props.color : 'secondary'}
                    />
                )}
            />
        </FormControl>
    );
};

export default AutocompleteNotMultipleSelectChip;

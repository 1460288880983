export enum Permission {
  FE_CAN_ACCESS_DASHBOARD = 'FE_CAN_ACCESS_DASHBOARD',
  FE_CAN_ACCESS_CLIENTS = 'FE_CAN_ACCESS_CLIENTS',
  FE_CAN_ACCESS_INVITE_CLIENTS = 'FE_CAN_ACCESS_INVITE_CLIENTS',
  FE_CAN_ACCESS_ADMINISTRATION = 'FE_CAN_ACCESS_ADMINISTRATION',
  FE_CAN_ACCESS_USERS = 'FE_CAN_ACCESS_USERS',
  FE_CAN_ACCESS_COUNTRIES = 'FE_CAN_ACCESS_COUNTRIES',
  FE_CAN_ACCESS_CLIENT_QUESTIONNAIRES = 'FE_CAN_ACCESS_CLIENT_QUESTIONNAIRES',
  FE_CAN_ACCESS_NOTIFICATIONS = 'FE_CAN_ACCESS_NOTIFICATIONS',
  FE_CAN_ACCESS_ALLOCATE_TOKENS = 'FE_CAN_ACCESS_ALLOCATE_TOKENS',
  FE_CAN_ACCESS_TERMS_AND_CONDITIONS = 'FE_CAN_ACCESS_TERMS_AND_CONDITIONS',
  FE_CAN_ACCESS_SYSTEM_APPROVERS = 'FE_CAN_ACCESS_SYSTEM_APPROVERS',
  FE_CAN_ACCESS_PROFILE = 'FE_CAN_ACCESS_PROFILE',
  FE_CAN_ACCESS_KYC_PROCEDURES = 'FE_CAN_ACCESS_KYC_PROCEDURES',
  FE_CAN_ACCESS_AML_PROCEDURES = 'FE_CAN_ACCESS_AML_PROCEDURES',
  FE_CAN_BLOCK_UNBLOCK_CLIENTS = 'FE_CAN_BLOCK_UNBLOCK_CLIENTS',
  FE_CAN_APPROVE_REJECT_PROCEDURES = 'FE_CAN_APPROVE_REJECT_PROCEDURES',
  FE_CAN_CREATE_CLIENT_PROCEDURE_COMMUNICATION = 'FE_CAN_CREATE_CLIENT_PROCEDURE_COMMUNICATION',
  FE_CAN_CREATE_EDIT_USERS = 'FE_CAN_CREATE_EDIT_USERS',
  FE_CAN_ACCESS_CHANGE_REQUESTS = 'FE_CAN_ACCESS_CHANGE_REQUESTS',
  FE_CAN_UPLOAD_COUNTRY_RISK_EXCEL_FILE = 'FE_CAN_UPLOAD_COUNTRY_RISK_EXCEL_FILE'
}

import SyncLockIcon from '@mui/icons-material/SyncLock';
import { Box, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AddButton from '../../../components/AddButton/AddButton';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import UserChangePassword from '../../../models/userroles/users/UserChangePassword';
import userService from '../../../repository/UserService';
import {
  BootstrapDialog,
  Transition
} from '../../../shared/components/BootstrapDialog/BootstrapDialog';
import BootstrapDialogTitle from '../../../shared/components/BootstrapDialog/BootstrapDialogTitle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserChangePassword>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const onFormSubmit: SubmitHandler<UserChangePassword> = (
    data: UserChangePassword
  ): void => {
    userService
      .changePasswordByLoggedUser(
        data.oldPassword,
        data.newPassword,
        data.confirmPassword
      )
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
        handleHideModal();
        navigate('/logout');
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  return (
    <>
      <Box
        sx={{
          width: {
            xs: '90%',
            sm: '70%',
            md: '60%',
            lg: '40%',
            xl: '35%'
          },
          padding: '30px'
        }}
      >
        <AddButton
          text={'Change password'}
          icon={<SyncLockIcon/>}
          onClickHandle={handleShowModal}
        />

        <BootstrapDialog
          style={{ fontFamily: 'Poppins' }}
          onClose={handleHideModal}
          aria-labelledby='customized-dialog-title'
          open={showModal}
          className='modalWrapper'
          TransitionComponent={Transition}
          maxWidth={'xs'}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <BootstrapDialogTitle
              id='customized-dialog-title'
              onClose={handleHideModal}
            >
              {t('CHANGE_PASSWORD')}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid container spacing={4} padding={2}>
                <Grid item xs={12}>
                  <TextField
                    id='oldPassword'
                    label={t('OLD_PASSWORD')}
                    color='primary'
                    variant={'standard'}
                    type={'password'}
                    {...register('oldPassword', { required: true })}
                    sx={{ width: '100%' }}
                    size='medium'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id={'newPassword'}
                    label={t('NEW_PASSWORD')}
                    color='primary'
                    variant={'standard'}
                    type={'password'}
                    {...register('newPassword', { required: true })}
                    sx={{ width: '100%' }}
                    size='medium'
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <TextField
                    label={t('CONFIRM_PASSWORD')}
                    id={'confirmPassword'}
                    color='primary'
                    type={'password'}
                    variant={'standard'}
                    sx={{ width: '100%' }}
                    {...register('confirmPassword', { required: true })}
                    size='medium'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='inherit'
                className={'modalCloseButton baseFontFamily'}
                onClick={handleHideModal}
              >
                {t('CLOSE')}
              </Button>
              <Button type='submit' variant='contained' color='primary'>
                {t('CHANGE_PASSWORD')}
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Box>
    </>
  );
};
export default ChangePassword;

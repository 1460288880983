import { AuthUtils } from '../../../utils/AuthUtils';
import { ProtectedComponentProps } from './ProtectedComponentProps';

function ProtectedComponent(props: ProtectedComponentProps) {
  const hasPermission = AuthUtils.hasPermission(props.permission);

  return <>
    { hasPermission ? props.children : <></> }
  </>;
}

export default ProtectedComponent;

import axios from "../axios/axios";
import { TCSectionDto } from "../models/terms_conditions/TCSectionDto";

export class SectionService {

    static async deleteActiveGroupSection(id: number): Promise<void> {
       await axios.delete<void>(`/terms-condition/tc-section/delete/${id}`)
    }

    static async createTCSection(tcSectionDto: TCSectionDto): Promise<void> {
        await axios.post('/terms-condition/tc-section/create', tcSectionDto)
    }

    static async editTCSection(id: number, tcSectionDto: TCSectionDto): Promise<void> {
        await axios.put(`/terms-condition/tc-section/edit/${id}`, tcSectionDto)
    }
};

import { Box, Grid } from '@mui/material';
import React from 'react';
import './Home.scss';
import ClientTypeChart from '../../components/Chart/ClientTypeChart';
import CountriesChart from '../../components/Chart/CountriesChart';
import MonthlyOnboardChart from '../../components/Chart/MonthlyOnboardChart';
import ProcedureStatusChart from '../../components/Chart/ProcedureStatusChart';
import StoppedOnboardingChart from '../../components/Chart/StoppedOnboardingChart';
import TotalClientsChart from '../../components/Chart/TotalClientsChart';

const Home: React.FC = () => {
  return (
    <Box className='container' sx={{overflowX: 'hidden'}}>
      <Grid container display='flex' justifyContent='center' spacing={3}>
        <Grid item xs={12} lg={6}>
          <TotalClientsChart/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StoppedOnboardingChart/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ClientTypeChart />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ProcedureStatusChart />
        </Grid>
        <Grid item xs={12}>
          <CountriesChart />
        </Grid>
        <Grid item xs={12}>
            <MonthlyOnboardChart />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;

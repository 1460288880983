import CloseIcon from "@mui/icons-material/Close";
import { FormControl, FormLabel, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SubmitHandler, useForm } from "react-hook-form";
import SwalAlert from "../../../components/SwalAlert/SwalAlert";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { TCSectionDto } from "../../../models/terms_conditions/TCSectionDto";
import { TCSectionResponseDto } from "../../../models/terms_conditions/TCSectionResponseDto";
import { SectionService } from "../../../repository/SectionService";
import {
  BootstrapDialog,
  Transition,
} from "../../../shared/components/BootstrapDialog/BootstrapDialog";
import { useTranslation } from 'react-i18next';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  isAddMode?: boolean;
  termGroupSectionData?: TCSectionResponseDto;
  termGroupForSectionId?: number | string;
  termGroupSectionId?: number | string;
  updateAll?: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const TermGroupSectionAddModal = (props: any) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [editorStateHtmlText, setEditorStateHtmlText] = useState<string>("");
  const { t } = useTranslation();

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<TCSectionDto>();

  const resetFormData = () => {
    setValue("content", "");
    setEditorState(EditorState.createEmpty());
  };
  watch("tcTermGroupId");
  useEffect(() => {
    if (props.open && props.isAddMode) {
      resetFormData();
    } else {
      if (props.termGroupSectionData) {
        setValue("tcTermGroupId", props.termGroupForSectionId);
        setValue("deleted", props.termGroupSectionData.deleted);
        setValue("content", props.termGroupSectionData.content);
        if (props.termGroupSectionData.content) {
          try {
            const blocksFromHTML = convertFromHTML(
              props.termGroupSectionData?.content
            );

            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            console.log(blocksFromHTML);
            console.log(state);
            const updatedEditorState = EditorState.createWithContent(state);

            setEditorState(updatedEditorState);
          } catch (error) {
            // Handle the case when the description is not valid JSON
            console.error(error);
            // Set a default editor state or handle the error condition as needed
          }
        }
      }
    }
  }, [props.open, props.termGroupSectionData]);

  const addSection = (data: TCSectionDto): void => {
    data.tcTermGroupId = props.termGroupForSectionId;
    data.deleted = false;
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const contentState = convertFromRaw(rawContentState);
    data.content = editorStateHtmlText;
    SectionService.createTCSection(data)
      .then(() => {
        SwalAlert.successfulAlert(
          t('SUCCESS'),
          t('SUCCESSFULLY_CREATED_NEW_SECTION')
        );
        props.handleClose();
        props.updateAll();
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  const editSection = (data: TCSectionDto): void => {
    console.log(editorState.getCurrentContent());
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const contentState = convertFromRaw(rawContentState);
    data.content = editorStateHtmlText;
    SectionService.editTCSection(props.termGroupSectionId, data)
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFULLY_EDITED_NEW_SECTION'));
        props.handleClose();
        props.updateAll();
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  const onSubmit: SubmitHandler<TCSectionDto> = (data: TCSectionDto): void => {
    props.isAddMode ? addSection(data) : editSection(data);
  };

  const allFontOptions = [
    "Arial",
    "Arial Black",
    "Comic Sans MS",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
  ];

  return (
    <div>
      <BootstrapDialog
        style={{ fontFamily: "Poppins" }}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modalWrapper"
        TransitionComponent={Transition}
        maxWidth={"md"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.handleClose}
          >
            {props.isAddMode
              ? "Create new active group section"
              : "Edit active group section"}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4} sx={{ padding: "0px 15px 0px 15px" }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                    htmlFor="editor"
                    style={{ marginBottom: "10px", color: "#0000099" }}
                  ></FormLabel>

                  <TextEditor
                    editorState={editorState}
                    setEditorState={setEditorState}
                    editorStateHtmlText={editorStateHtmlText}
                    setEditorStateHtmlText={setEditorStateHtmlText}
                  />

                  {errors.content && <span>{t('REQUIRED_FIELD')}</span>}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="inherit"
              className={"modalCloseButton baseFontFamily"}
              onClick={props.handleClose}
            >
              {t('CLOSE')}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {props.isAddMode ? t('CREATE_SECTION') : t('EDIT_SECTION')}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default TermGroupSectionAddModal;

import { TranslationKey } from "./../../lib/idscan/idscan-jcs";

export const textTranslation: Partial<Record<TranslationKey, string>> = {
  [TranslationKey.FLOW_STATE_IDENTITY_FRONT]:
    "Please Upload an Image of the Front of your Identification",
  [TranslationKey.FLOW_STATE_IDENTITY_BACK]:
    "Please Upload an Image of the Back of your Identification",
  [TranslationKey.FILESYSTEM_DROP_IMAGE]:
    "Alternatively drag & drop an image here",
  [TranslationKey.GATEWAY_CANCEL]: "Cancel",
  [TranslationKey.VIEW_RETRY]: "Change File",
};

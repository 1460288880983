import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../auth/Permission';
import { useAuth } from '../shared/hooks/UseAuth';
import { AuthUtils } from '../utils/AuthUtils';

//protected Route state
type ProtectedRouteType = {
  permission?: Permission;
}

const ProtectedRoutes = (props: ProtectedRouteType) => {
  const { auth, role } = useAuth();

  //if the role required is there or not
  if (props.permission) {
    const isAut = AuthUtils.hasPermission(props.permission);

    return auth ? (

      isAut ? (
        <Outlet/>
      ) : (
        <Navigate to='/accessDenied'/>
      )
    ) : (
      <Navigate to='/login'/>
    );
  } else {
    return auth ? <Outlet/> : <Navigate to='/login'/>;
  }
};

export default ProtectedRoutes;

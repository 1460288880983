import React from 'react';
import {Grid, TextField} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import FileQuestionModel from "../../../models/clientQuestionnaires/AMLQuestionFileDTO";

interface propsTypes{
    handleRemoveAnswer:(index:number)=> void,
    id:number,
    fileQuestion:FileQuestionModel | undefined,
    handleChangeFileQuestion:(e:React.ChangeEvent<HTMLInputElement>,index:number)=>void
}

const FileQuestion = (props:propsTypes) => {

    const handleRemoveAnswer = () => {
        props.handleRemoveAnswer(props.id);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChangeFileQuestion(e,props.id);
    }

    return (
        props.fileQuestion?.deleted !==true ?

            ( <Grid container spacing={3} width={'90%'} margin={'10px'} sx={{
            border: '1px solid rgb(204, 204, 204)',
            paddingRight: '20px',
            marginTop: '30px',
            borderRadius: '10px',
            marginX: 'auto',
            marginLeft: '50px',
            justifyContent:'space'
        }}>
            <Grid item xs={5} sx={{paddingTop: "0px"}}>
                <TextField
                    id="fileQuestionTitle"
                    type={'text'}
                    label="Title of file"
                    name={'title'}
                    size={'small'}
                    variant="standard"
                    value={props.fileQuestion?.title}
                    onChange={handleChange}
                    fullWidth={true}
                    color={'primary'}
                    required={true}
                />
            </Grid>
            <Grid item xs={5} sx={{marginBottom: '30px'}}>
                <TextField
                    id="fileQuestionDescription"
                    type={'text'}
                    name={'description'}
                    size={'small'}
                    label="Description of file"
                    variant="standard"
                    onChange={handleChange}
                    value={props.fileQuestion?.description}
                    fullWidth={true}
                    color={'primary'}
                />
            </Grid>
            <Grid item xs={1}  sx={{display:'flex',marginLeft:'auto', justifyContent:'end'}}>
                <RemoveIcon sx={{
                    marginRight:'10px',
                    backgroundColor: "#d23862",
                    color: "white",
                    borderRadius: '50%',
                    cursor: 'pointer',
                    marginTop:'65%',
                    textAlign:'end'
                }} onClick={handleRemoveAnswer}/>
            </Grid>
        </Grid>
            ) :
            (<></>)
    )
}
export default FileQuestion;
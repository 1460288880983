import FileDTO from './FileDTO';

export default class AMLQuestionAnswerDTO {
  amlQuestionId: number | string;
  nomenclatureEntryIds: string[];
  freeTextAnswer: string | null;
  files: FileDTO[] | null;
  countries: number[] | null;

  constructor(
    amlQuestionId: number,
    nomenclatureEntryIds: string[],
    freeTextAnswer: string | null,
    files: FileDTO[] | null,
    countries: number[] | null
  ) {
    this.amlQuestionId = amlQuestionId;
    this.nomenclatureEntryIds = nomenclatureEntryIds;
    this.freeTextAnswer = freeTextAnswer;
    this.files = files;
    this.countries = countries;
  }
}

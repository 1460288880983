import React from "react"
import {Grid, TextField} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import QuestionAnswer from "../../../models/clientQuestionnaires/QuestionAnswer";

interface propsTypes{
    handleRemoveAnswer:(index:number)=> void,
    id:number,
    possibleAnswer:QuestionAnswer | undefined,
    handleChangeQuestion:(e:React.ChangeEvent<HTMLInputElement>,index:number)=>void,
    selectedQuestionType: string | undefined;
}

const PossibleAnswer = (props:propsTypes) => {

    const handleRemoveAnswer = () => {
            props.handleRemoveAnswer(props.id);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChangeQuestion(e,props.id);
    }

    return (
        props.possibleAnswer?.deleted !==true ?

            ( <Grid container spacing={3} width={'90%'} margin={'10px'} sx={{
            border: '1px solid rgb(204, 204, 204)',
            paddingRight: '20px',
            marginTop: '30px',
            borderRadius: '10px',
            marginX: 'auto',
            marginLeft: '50px',
            justifyContent:'space'
        }}>

              {(props.selectedQuestionType !== "TEXT" && props.selectedQuestionType !== "NUMBER") && (
                <Grid item xs={4} sx={{ paddingTop: "0px" }}>
                  <TextField
                    id="AnswerName"
                    type="text"
                    label="Name of answer"
                    name="name"
                    size="small"
                    variant="standard"
                    value={props.possibleAnswer?.name || ""}
                    onChange={handleChange}
                    fullWidth
                    color="primary"
                    required
                  />
                </Grid>
              )}
            <Grid item xs={4} sx={{marginBottom: '30px'}}>
                <TextField
                    id="answerDescription"
                    type={'text'}
                    name={'description'}
                    size={'small'}
                    label="Description of answer"
                    variant="standard"
                    onChange={handleChange}
                    value={props.possibleAnswer?.description || ""}
                    fullWidth={true}
                    color={'primary'}
                />
            </Grid>
              <Grid item xs={3} sx={{marginBottom: '30px'}}>
                <TextField
                  id="riskScore"
                  type={'number'}
                  name={'riskScore'}
                  size={'small'}
                  label="Risk Score"
                  variant="standard"
                  onChange={handleChange}
                  value={props.possibleAnswer?.riskScore || ""}
                  fullWidth={true}
                  color={'primary'}
                  required={true}
                  inputProps={{ min: 0 }}
                />
              </Grid>

              {props.selectedQuestionType !== "NUMBER" && (
            <Grid item xs={1}  sx={{display:'flex',marginLeft:'auto', justifyContent:'end'}}>
                <RemoveIcon sx={{
                    marginRight:'10px',
                    backgroundColor: "#d23862",
                    color: "white",
                    borderRadius: '50%',
                    cursor: 'pointer',
                    marginTop:'65%',
                    textAlign:'end'
                }} onClick={handleRemoveAnswer}/>
            </Grid>
                )}
        </Grid>) : (<></>)
    )
}
export default PossibleAnswer
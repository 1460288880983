import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ChangeRequestDetailsResponse } from '../../../models/changeRequests/ChangeRequestDetailsResponse';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';

const UpdatePersonRiskChangeRequestDetails = ({
  changeRequestDetails
}: { changeRequestDetails: ChangeRequestDetailsResponse | undefined; }) => {
  const { t } = useTranslation();
  const changedFields = changeRequestDetails ? JSON.parse(changeRequestDetails.changedFields) : {};
  const cardStyles = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)'
  };

  const renderField = (label: string, value: string | undefined) => {
    if (!value) return null;
    return (
      <Typography variant='body1' sx={{ marginBottom: 1 }}>
        {label}: {value}
      </Typography>
    );
  };

  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes From</Typography>
            {renderField(t('FIRST_NAME'), changedFields?.firstName?.from)}
            {renderField(t('LAST_NAME'), changedFields?.lastName?.from)}
            {renderField(t('PHONE_NUMBER'), changedFields?.phoneNumber?.from)}
            {renderField(t('BIRTH_DATE'), changedFields?.birthDate?.from ? DateTimeUtils.formatDate(changedFields.birthDate.from) : '')}
            {renderField(t('ADDRESS'), changedFields?.address?.from)}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <Card sx={cardStyles}>
          <CardContent>
            <Typography variant='h6' gutterBottom>Changes To</Typography>
            {renderField(t('FIRST_NAME'), changedFields?.firstName?.to)}
            {renderField(t('LAST_NAME'), changedFields?.lastName?.to)}
            {renderField(t('PHONE_NUMBER'), changedFields?.phoneNumber?.to)}
            {renderField(t('BIRTH_DATE'), changedFields?.birthDate?.to ? DateTimeUtils.formatDate(changedFields.birthDate.to) : '')}
            {renderField(t('ADDRESS'), changedFields?.address?.to)}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UpdatePersonRiskChangeRequestDetails;
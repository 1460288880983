import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import AMLQuestionGroup from '../../../models/clientQuestionnaires/AMLQuestionGroup';
import { AMLQuestionnaireRequest } from '../../../models/clientQuestionnaires/AMLQuestionnaireRequest';
import ClientQuestionnairesDTO from '../../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import QuestionAnswer from '../../../models/clientQuestionnaires/QuestionAnswer';
import QuestionsType from '../../../models/clientQuestionnaires/QuestionsType';
import { NomenclatureEntryDto } from '../../../models/nomenclatures/NomenclatureEntryDto';
import QuestionsService from '../../../repository/QuestionsService';
import QuestionModal from '../QuestionModal/QuestionModal';
import AMLQuestion from './AMLQuestion';

const AMLQuestionnairesEditDraft = () => {
  const [allQuestionsList, setAllQuestionsList] = useState<ClientQuestionnairesDTO[]>([]);
  const [questionTypesList, setQuestionTypeList] = useState<QuestionsType[]>([]);
  const [activeQuestionGroup, setActiveQuestionGroup] = useState<AMLQuestionGroup>();
  const [selectedQuestionForEdit, setSelectedQuestionForEdit] = useState<ClientQuestionnairesDTO>();
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const key = location.state.key;
  // 0 individual, 1 legal_entity

  useEffect(() => {
    getAllQuestionType();
    getActiveAMLQuestionsByGroup();
  }, []);

  useEffect(() => {
    getAllQuestionsByGroup();
  }, [activeQuestionGroup]);


  const getAllQuestionType = () => {
    QuestionsService.getAllQuestionsType().then((data) => {
      setQuestionTypeList(data.data);
    }).catch(()=>{});
  };

  const getActiveAMLQuestionsByGroup = () => {
    if(key == 0){
      QuestionsService.getActiveAMLQuestionsByGroup("INDIVIDUAL").then(
        (data) => {
          setActiveQuestionGroup(data.data);
        }
      ).catch(()=>{});
    }
    else if(key == 1){
      QuestionsService.getActiveAMLQuestionsByGroup("LEGAL_ENTITY").then(
        (data) => {
          setActiveQuestionGroup(data.data);
        }
      ).catch(()=>{});
    }
  };


  const getAllQuestionsByGroup = () => {
    if (activeQuestionGroup != null) {
      QuestionsService.getAllAMLQuestionsByGrope(id).then(
        (data) => {
          setAllQuestionsList(data.data);
        }
      ).catch(()=>{});
    }
  };

  const handleCancel = () => {
    Swal.fire({
      title: t("DELETE_DRAFT_CONFIRMATION"),
      showCancelButton: true,
      confirmButtonText: t('YES'),
    }).then((result) => {
      if (result.isConfirmed && id) {
        const questionnaireId = parseInt(id, 10);
        QuestionsService.deleteDraftQuestionnaire(questionnaireId)
          .then(() => {
            Swal.fire({
              title: t("DRAFT_DELETED_SUCCESSFULLY"),
              icon: "success",
            });
            navigate('/client-questionnaires');
          })
          .catch(error => {
            Swal.fire({
              title: t("FAILED_TO_DELETE_DRAFT"),
              text: error,
              icon: "error",
            });
          });
      }
    });
  };

  const handleShowModal = () => {
    setShowQuestionModal(true);
  };

  const handleCloseModal = () => {
    setSelectedQuestionForEdit(undefined);
    setShowQuestionModal(false);
  };

  const handleClickOpenEditQuestion = (question: ClientQuestionnairesDTO) => {
    setSelectedQuestionForEdit(question);
    handleShowModal();
  };

  const handleAddQuestion = (newQuestion: ClientQuestionnairesDTO) => {
    setAllQuestionsList(prevQuestions => [...prevQuestions, newQuestion]);
  };

  const handleEditQuestion = (updatedQuestion: ClientQuestionnairesDTO) => {
    setAllQuestionsList(prevQuestions => {
      return prevQuestions.map(question => {
        return question.amlQuestionDTO.id === updatedQuestion.amlQuestionDTO.id
          ? updatedQuestion
          : question;
      });
    });
  };

  const handleDeleteQuestion = (questionId: number) => {
    Swal.fire({
      title: t("CONFIRM_DELETE_QUESTION"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t("YES"),
      cancelButtonText: t("CANCEL"),
    }).then((result) => {
      if (result.isConfirmed) {
        const newQuestionsList = [...allQuestionsList];
        const index = newQuestionsList.findIndex(question => question.amlQuestionDTO.id === questionId);

        if (index !== -1) {
          if (newQuestionsList[index].amlQuestionDTO.id === undefined) {
            newQuestionsList.splice(index, 1);
            setAllQuestionsList(newQuestionsList);
            Swal.fire(
              t("QUESTION_DELETED_SUCCESSFULLY"),
              'The question has been deleted.',
              'success'
            );
          } else {
            QuestionsService.deleteQuestion(questionId).then(() => {
              newQuestionsList.splice(index, 1);
              setAllQuestionsList(newQuestionsList);
              Swal.fire(
                t("QUESTION_DELETED_SUCCESSFULLY"),
                'The question has been deleted.',
                'success'
              );
            });
          }
        } else {
          Swal.fire(
            'Error!',
            'Question not found.',
            'error'
          );
        }
      }
    });
  };

  const handleAddQuestionModalOpen = () => {
    setShowQuestionModal(true);
    setSelectedQuestionForEdit(undefined);
  };

  const mapQuestionAnswerToNomenclatureEntry = (questionAnswers: QuestionAnswer[]) : NomenclatureEntryDto[] =>{
    if(questionAnswers == null){return []}
    return questionAnswers.map(questionAnswer => ({
      id: questionAnswer.id || 0,
      nomenclatureRequest: { id: 0, name: questionAnswer.name },
      name: questionAnswer.name,
      description: questionAnswer.description || '',
      value: questionAnswer.value || '',
      deleted: questionAnswer.deleted || false,
      riskScore: questionAnswer.riskScore
    }))
  }

  const onSubmit = () => {
    let groupType = key === 0 ? 'INDIVIDUAL' : 'LEGAL_ENTITY'

    if (activeQuestionGroup && id) {
      const questionData: AMLQuestionnaireRequest = {
        amlCreationRequests: allQuestionsList.map(question => ({
          amlQuestionDTO: question.amlQuestionDTO,
          nomenclatureEntryDtoList: mapQuestionAnswerToNomenclatureEntry(question.nomenclatureEntryDtoList),
          amlQuestionFileDtoList: question.amlQuestionFileDtos,
          questionnaire: groupType
        })),
        type: groupType
      };

      QuestionsService.updateQuestionnaire(id, questionData)
        .then(() => {
          Swal.fire({
            title: "Success!",
            text: "A change request has been created",
            icon: "success",
          });
          navigate('/client-questionnaires');
        })
        .catch(error => {
          console.error("Error deleting questionnaire:", error);
          Swal.fire({
            title: "Error!",
            text: "Somthing went wrong",
            icon: "error",
          });
        });
    }
  }

  return (
    <>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography
          variant="h5"
          sx={{textAlign: "center", marginRight: "auto",marginBottom:'30px'}}
        >
          {" "}
          <b style={{fontWeight: 600}}>{t('LIST_OF_AML_QUESTIONS')} Draft</b>{" "}
        </Typography>
        <Button
          type='button'
          variant='contained'
          color='primary'
          sx={{marginLeft: '10px'}}
          onClick={handleAddQuestionModalOpen}>Add
        </Button>
        <Button type='submit' variant='contained' color='primary' sx={{marginLeft: '10px'}}
                onClick={onSubmit}>Submit</Button>
        <Button type='submit' variant='contained' color='error' sx={{marginLeft: '10px'}}
                onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
      <Box
        sx={{
          marginLeft: "auto",
        }}>
        <Box sx={{display: "flex", width: "100%"}}>
          <Grid container spacing={0} width={"100%"}>
            <Grid item lg={12} md={12} sm={12} xs={12} width={'400px'}>
              {allQuestionsList.length >0 ? (
                allQuestionsList.map((question, index) => {
                  const id = question.amlQuestionDTO.id || `new-${index}`;
                  return(
                    <AMLQuestion questionTypesList={questionTypesList}
                                 handleClickOpenEditQuestion={handleClickOpenEditQuestion}
                                 getAllQuestionsByGrope={getAllQuestionsByGroup}
                                 handleDeleteQuestion={handleDeleteQuestion}
                                 isCopy={true}
                                 canEdit={true}
                                 question={question}
                                 key={id}/>
                  )
                })
              ):(
                <Box  display={'flex'} justifyContent={'center'} color={'grey'}> <i>{t('EMPTY_QUESTION_LIST')} </i></Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <QuestionModal getAllQuestionsByGrope={getAllQuestionsByGroup}
                     showModal={showQuestionModal}
                     handleCloseModal={handleCloseModal}
                     handleAddQuestion={handleAddQuestion}
                     handleEditQuestion={handleEditQuestion}
                     selectedQuestionForEdit={selectedQuestionForEdit}/>
    </>
  );
};
export default AMLQuestionnairesEditDraft;

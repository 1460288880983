import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTable from '../../../components/Table/PageTable';
import { UserDto } from '../../../models/userroles/users/UserDto';

const AproversAndDeclinersTable = ({ users} : {users: UserDto[]}) => {
  const { t } = useTranslation();

  const headerRows = [
    {
      id: 'firstName',
      label: t('FIRST_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'lastName',
      label: t('LAST_NAME'),
      search: false,
      type: 'text',
      variant: 'standard'
    }
  ];

  const rows = users.map((user) => ({
    id: user.id,
    values: [
      { type: 'text', name: 'firstName', value: user.firstName },
      { type: 'text', name: 'lastName', value: user.lastName }
    ]
  }));

  return (
    <PageTable
      headerRows={headerRows}
      rows={rows}
      clickable={false}
    />
  );
};

export default AproversAndDeclinersTable;

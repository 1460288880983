export class FileDTO {
  id?: string | number;
  name: string;
  mimeType: string;
  base64Content: string;
  index?: number;

  constructor(json: FileDTO) {
    this.id = json.id;
    this.name = json.name;
    this.mimeType = json.mimeType;
    this.base64Content = json.base64Content;
    this.index = json.index;
  }
}
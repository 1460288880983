import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Button,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import ActionButton from "../../../components/ActionButton/ActionButton";
import { UserDto } from "../../../models/userroles/users/UserDto";
import { useForm } from "react-hook-form";
import SwalAlert from "../../../components/SwalAlert/SwalAlert";
import UserService from "../../../repository/UserService";
import {UserData} from "../../../models/userroles/users/UserData";

const ProfileInfo = () => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [user, setUser] = useState<UserData>({} as UserData);
    const [currentUser, setCurrentUser] = useState<UserDto>({} as UserDto);
    const {t} = useTranslation();

    const {
        register,
        setValue,
        reset,
        formState: { errors },
    } = useForm<UserData>();

    useEffect(() => {
        getUserProfileInfo();
    }, []);

    useEffect(() => {
        if (isEditMode) {
            setValue("address", user.address);
            setValue("firstName", user.firstName);
            setValue("lastName", user.lastName);
            setValue("birthDate", currentUser.birthDate);
            setValue("phoneNumber", user.phoneNumber);
        } else {
            resetFormData();
        }
    }, [isEditMode, user]);

    const resetFormData = () => {
        reset();
    };

    const handleInputChange = (field: string, value: any) => {
        setUser((prevUser) => ({
            ...prevUser,
            [field]: value,
        }));
    };

    const handleSaveChanges = async () => {
        try {
            await UserService.editUserProfileInfo(currentUser.id, user);
            SwalAlert.successfulAlert(t('SUCCESS'), t('CHANGE_REQUEST_CREATED'));
            setIsEditMode(false);
        } catch (error) {
            console.error("Error saving user profile changes:", error);
            SwalAlert.errorAlert("Oops...", "Something went wrong!");
        }
    };

    const resetFormFields = () => {
        setUser({
            ...user,
            address: currentUser.address,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            birthDate: currentUser.birthDate,
            phoneNumber: currentUser.phoneNumber,
        });
    };


    const cancelEdit = () => {
        setIsEditMode(false);
        resetFormFields();
    }

    const getUserProfileInfo = async () => {
        try {
            const response = await UserService.getUserProfileInfo();
            const userData: UserData = response.data;
            const currentUser: UserDto = response.data;
            setUser(userData);
            setCurrentUser(currentUser);
        } catch (error) {
            console.error("Error fetching user profile info:", error);
        }
    };

    return (
        <Card
            sx={{
                width: {
                    xs: "90%",
                    sm: "70%",
                    md: "60%",
                    lg: "40%",
                    xl: "35%",
                },
                height: "100%",
            }}
        >
            <CardContent>
                <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
                    User Details
                </Typography>
                <Box
                    sx={{
                        padding: "30px",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: true,
                                }}
                                value={user?.firstName || ''}
                                onChange={(e) => handleInputChange("firstName", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: true,
                                }}
                                value={user?.lastName}
                                onChange={(e) => handleInputChange("lastName", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: true,
                                    disabled: true,
                                }}
                                value={user?.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: true,
                                }}
                                value={user?.address}
                                onChange={(e) => handleInputChange("address", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: false,
                                }}
                                value={user?.phoneNumber}
                                onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="birthDate"
                                label="Birth Date"
                                color={"primary"}
                                {...register("birthDate", { required: true })}
                                type="date"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: false,
                                }}
                                fullWidth={true}
                                value={user?.birthDate}
                                onChange={(e) => handleInputChange("birthDate", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Role"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    readOnly: !isEditMode,
                                    disableUnderline: true,
                                    disabled: true,
                                }}
                                value={user?.userRoles?.map((role) => role.label)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {isEditMode ? (
                                <>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveChanges}
                                        sx={{ marginRight: 1 }}
                                    >
                                        Save Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        className="modalCloseButton baseFontFamily"
                                        onClick={cancelEdit}
                                    >
                                        Cancel
                                    </Button>

                                </>
                            ) : (
                                <ActionButton
                                    type="edit"
                                    action={() => setIsEditMode(true)}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProfileInfo;

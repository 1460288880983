import { Box } from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { useParams } from 'react-router-dom';
import { FileDTO } from '../../../models/aml/FileDTO';
import { TermsConditionService } from '../../../repository/TermsConditionService';

const PdfViewer = () => {

  const [selectedFileForRender, setSelectedFileForRender] = useState<Uint8Array>();
  const params = useParams();
  const selectedFileName = params?.fileName;

  useEffect(() => {
    getAllTermsAndConditionalsFileForRender();
  }, []);

  const getAllTermsAndConditionalsFileForRender = () => {
    TermsConditionService.getFilesForTermsAndConditions().then((response: FileDTO[]) => {
      const filteredListFile = response.filter((e) => e.name === selectedFileName);
      if (filteredListFile.length > 0) {
        const fileConvert = convertBase64ToUint8Array(filteredListFile[0].base64Content);
        setSelectedFileForRender(fileConvert);
      }
    });
  };

  const convertBase64ToUint8Array = (base64:string) => {
    const raw = atob(base64);
    const uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  };

    return (
        <Box>
            <Box className={'pdf-container'} height={'100%'} sx={{ overflowY: 'auto' }}>
                <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                    {selectedFileForRender &&
                      <Viewer fileUrl={selectedFileForRender}></Viewer>}
                </Worker>
            </Box>
        </Box>
    );
};
export default PdfViewer;

import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { forEach } from 'lodash';
import React, { useState } from 'react';
import FileUploadInput from '../../../components/FormFIelds/FIleUploadInput';
import SwalAlert from '../../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../../models/aml/FileDTO';
import { KycProcedureLegalEntityAnswersRequest } from '../../../models/legal_entity/KycProcedureLegalEntityAnswersRequest';
import KYCProcedureService from '../../../repository/KYCProcedureService';
import ApplicationService from '../../../utils/ApplicationService';
import { ErrorHandler } from '../../../utils/ErrorHandler';
import { LegalEntityFormProps } from './LegalEntityFormProps';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './LegalEntityForm.css';

export function LegalEntityForm({
  email,
  token,
  onSave
}: LegalEntityFormProps) {
  const { t } = useTranslation();
  const [request, setRequest] = useState<KycProcedureLegalEntityAnswersRequest>({
    name: '',
    form: '',
    registrationNumber: '',
    dateOfIncorporation: null,
    registeredAddress: '',
    businessAddress: '',
    phoneNumber: '',
    email: email ? email : '',
    companyWebsite: '',
    taxIdentificationNumber: '',
    isUsaTaxResident: false,
    certificatesOfIncorporation: [],
    memorandumAndArticlesOfAssociation: [],
    companySearch: []
  });

  const disableButton =
    request.name.trim() === '' ||
    request.form.trim() === '' ||
    request.registrationNumber.trim() === '' ||
    request.dateOfIncorporation === null ||
    request.registeredAddress.trim() === '' ||
    request.businessAddress.trim() === '' ||
    request.phoneNumber.trim() === '' ||
    request.companyWebsite.trim() === '' ||
    request.email.trim() === '' ||
    request.taxIdentificationNumber.trim() === '' ||
    request.certificatesOfIncorporation.length === 0 ||
    request.memorandumAndArticlesOfAssociation.length === 0 ||
    request.companySearch.length === 0;

  const saveLegalEntityAnswers = () => {
    KYCProcedureService.createLegalEntityAnswers(token!, request).then((res) => {
      onSave();
    }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      let customMessage = t('ERROR_SAVING_LEGAL_ENTITY_ANSWERS');
      SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
    });
  };
  const handleChangeCertificatesOfIncorporation = async (files: File[]) => {
    ApplicationService.filesToFilesDto(files).then((res) => {
      setRequest(prevState => ({ ...prevState, certificatesOfIncorporation: res }));
    });
  };
  const handleChangeMemorandumAndArticlesOfAssociation = async (files: File[]) => {
    ApplicationService.filesToFilesDto(files).then((res) => {
      setRequest(prevState => ({ ...prevState, memorandumAndArticlesOfAssociation: res }));
    });
  };
  const handleChangeCompanySearch = async (files: File[]) => {
    ApplicationService.filesToFilesDto(files).then((res) => {
      setRequest(prevState => ({ ...prevState, companySearch: res }));
    });
  };

  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('NAME')}
          fullWidth={true}
          value={request.name}
          onChange={(event) => setRequest(prevState => ({ ...prevState, name: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('FORM')}
          fullWidth={true}
          value={request.form}
          onChange={(event) => setRequest(prevState => ({ ...prevState, form: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('REGISTRATION_NUMBER')}
          fullWidth={true}
          value={request.registrationNumber}
          onChange={(event) => setRequest(prevState => ({ ...prevState, registrationNumber: event.target.value }))}/>
      </Grid>
      {/* TODO::check if the date of incorporation is sending properly */}
      <Grid item xs={12} sm={12} lg={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className='date_of_incorporation'
            label={t('DATE_OF_INCORPORATION')}
            value={request.dateOfIncorporation}
            format='MMM D, YYYY'
            onChange={(newValue) => setRequest(prevState => ({ ...prevState, dateOfIncorporation: newValue }))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('REGISTERED_ADDRESS')}
          fullWidth={true}
          value={request.registeredAddress}
          onChange={(event) => setRequest(prevState => ({ ...prevState, registeredAddress: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('BUSINESS_ADDRESS')}
          fullWidth={true}
          value={request.businessAddress}
          onChange={(event) => setRequest(prevState => ({ ...prevState, businessAddress: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('PHONE_NUMBER')}
          fullWidth={true}
          value={request.phoneNumber}
          onChange={(event) => setRequest(prevState => ({ ...prevState, phoneNumber: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('EMAIL')}
          fullWidth={true}
          value={request.email}
          type='email'
          onChange={(event) => setRequest(prevState => ({ ...prevState, email: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('TAX_IDENTIFICATION_NUMBER')}
          fullWidth={true}
          value={request.taxIdentificationNumber}
          onChange={(event) => setRequest(prevState => ({ ...prevState, taxIdentificationNumber: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <TextField
          label={t('COMPANY_WEBSITE')}
          fullWidth={true}
          value={request.companyWebsite}
          onChange={(event) => setRequest(prevState => ({ ...prevState, companyWebsite: event.target.value }))}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={request.isUsaTaxResident}
              onChange={(event) => setRequest(prevState => ({ ...prevState, isUsaTaxResident: event.target.checked }))}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('IS_USA_TAX_RESIDENT')}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <FileUploadInput
          label={t('CERTIFICATES_OF_INCORPORATION')}
          selectedFiles={request.certificatesOfIncorporation}
          onFileChange={handleChangeCertificatesOfIncorporation}
          index={0}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <FileUploadInput
          label={t('MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION')}
          selectedFiles={request.memorandumAndArticlesOfAssociation}
          onFileChange={handleChangeMemorandumAndArticlesOfAssociation}
          index={0}/>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <FileUploadInput
          label={t('COMPANY_SEARCH')}
          selectedFiles={request.companySearch}
          onFileChange={handleChangeCompanySearch}
          index={0}/>
      </Grid>
      <Grid
        item
        xs={12}
        className='legal-entity-next-button'
        display='flex'>
        <Button
          disabled={disableButton}
          variant='contained'
          onClick={saveLegalEntityAnswers}>
          {t('NEXT')}
        </Button>
      </Grid>
    </Grid>
  );
}
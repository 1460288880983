export default class QuestionAnswer {
    id: number | undefined;
    name: string;
    description: string;
    value?: string | null;
    deleted:boolean;
    riskScore?: number | undefined

    constructor(json: QuestionAnswer) {
        this.id = json.id
        this.name = json.name;
        this.description = json.description;
        this.value = json.value;
        this.deleted=json.deleted;
        this.riskScore=json.riskScore;
    }
}

import Logout from '@mui/icons-material/Logout';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './account-menu.scss';
import jwtDecode from 'jwt-decode';
import { Authentication } from './Authentication';
import { useTranslation } from 'react-i18next';

/**
 * @todo: change css to scss and use colors styles
 */

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const jwtToken = localStorage.getItem('auth_token');

  const decodedToken: Authentication | null = jwtToken
    ? jwtDecode(jwtToken)
    : null;

  const getAvatarContent = () => {
    if (decodedToken) {
      return decodedToken.fullName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    }
    return '';
  };

  return (
    <React.Fragment>
      <Box>
        <Tooltip title='Account settings'>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}>
            <Avatar className='avatar-menu'>{getAvatarContent()}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Link to={'/profile'} className={'link-element'}>
          <MenuItem onClick={handleClose}>
            <Avatar/> {t('PROFILE')}
          </MenuItem>
        </Link>
        <MenuItem onClick={handleClose}>
          <Avatar/> {t('MY_ACCOUNT')}
        </MenuItem>
        <Divider/>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize='small'/>
          </ListItemIcon>
          {t('ADD_ANOTHER_ACCOUNT')}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize='small'/>
          </ListItemIcon>
          {t('SETTINGS')}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={'/logout'} className='acountMenu_link'>
            <ListItemIcon>
              <Logout fontSize='small'/>
            </ListItemIcon>
            {t('LOGOUT')}
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

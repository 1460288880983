import axios from "../axios/axios";
import { AMLQuestionnaireRequest } from '../models/clientQuestionnaires/AMLQuestionnaireRequest';

const QuestionsService = {
    getAllQuestionsType: () => {
        return axios.get("/metamodel/aml-question-type/public/get-all");
    },
    createAMLQuestion: (AMLCreationDTO: any) => {
        return axios.post("/metamodel/aml-question/create", AMLCreationDTO);
    },
    getAllAMLQuestionsByGrope: (questionGroupId: any) => {
        return axios.post(
          "/metamodel/aml-question/aml-questions",
          {},
          {
              headers: {
                  questionGroupId,
              },
          }
        );
    },
    getActiveAMLQuestionsByGroup: (type: string) => {
        return axios.post(
          "/metamodel/aml-questionnaire/get-active-group",
          {},
          {
              headers: {
                  type,
              },
          }
        );
    },
    deleteQuestion: (id: number) => {
        return axios.delete(`metamodel/aml-question/delete/${id}`);
    },
    editAMLQuestions: (AMLCreationDTO: any, id: number) => {
        return axios.post(`/metamodel/aml-question/update/${id}`, AMLCreationDTO);
    },
    createOrGetCopyAMLGroup: (questionGroupId: number) => {
        return axios.post(`/metamodel/aml-question-group/get-edit-group`, {}, {headers: {"questionGroupId": questionGroupId}});
    },
    sentQuestionGropeForApproval: (questionGroupId: number | string) => {
        return axios.post(`/change-request/create`, {}, {headers: {questionGroupId}})
    },
    setChangeRequestStatus:(changeRequestId:number, decision:string)=>{
        return axios.post(`/change-request-approver/create`, {}, {headers: {changeRequestId,decision}})
    },

    createDraftQuestionnaire: (questionGroupId: number) => {
        return axios.post(`/metamodel/aml-questionnaire/copy/${questionGroupId}`);
    },
    updateQuestionnaire: (questionGroupId: string, questionData: AMLQuestionnaireRequest) => {
        return axios.post(`/metamodel/aml-questionnaire/update/${questionGroupId}`, questionData);
    },
    deleteDraftQuestionnaire: (questionGroupId: number) => {
        return axios.post(`/metamodel/aml-questionnaire/delete/${questionGroupId}`);
    },
    getDraftId: (type: string) => {
        return axios.post("/metamodel/aml-questionnaire/get-draft-id", null, {
            headers: {
                'type': type
            }
        });
    },
};
export default QuestionsService;

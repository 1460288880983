import axios from '../axios/axios';
import { FileDTO } from '../models/aml/FileDTO';

export class FileService {
  static downloadDocuments(id: number, fileName: string) {
    const FileSaver = require('file-saver');
    fetch(process.env.REACT_APP_HOST_ENV + `file/download/${id}`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('auth_token')
      }
    })
      .then((res) => res.json()) // Assuming the response is JSON
      .then((data) => {
        const base64Content = data.base64Content;

        // Decode base64 to binary
        const binaryData = atob(base64Content);

        // Create a Blob from binary data
        const arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

        // Use FileSaver to save the file
        FileSaver.saveAs(blob, fileName);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  }

  static async getBytesFromDocument(id: number): Promise<FileDTO> {
    const response = await axios.get(`/file/download/${id}`);
    return response.data;
  };
};

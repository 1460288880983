import { Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AMLProcedure } from '../../../models/procedure/AMLProcedure';
import KYCProcedure from '../../../models/procedure/KYCProcedure';
import { ProcedureService } from '../../../repository/ProcedureService';
import ClientProcedureProps from '../active-procedure/ClientActiveProcedureProps';
import AMLProcedureHistoryTable from './AMLProcedureHistoryTable';
import KYCProcedureHistoryTable from './KYCProcedureHistoryTable';
import { useTranslation } from 'react-i18next';

const ProcedureHistory = (props: ClientProcedureProps) => {
  const [allKYCProcedure, setAllKYCProcedure] = useState<KYCProcedure[]>([]);
  const [allAMLProcedure, setAllAMLProcedure] = useState<AMLProcedure[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAllKYCProcedure();
    getAllAMLProcedure();
  }, []);

  const getAllKYCProcedure = () => {
    ProcedureService.getAllKYCProcedureForClient(props.clientId).then((data) => setAllKYCProcedure(data));
  };

  const getAllAMLProcedure = () => {
    ProcedureService.getAllAMLProcedureForClient(props.clientId).then((data) => setAllAMLProcedure(data));
  };

  return (
    <>
      <Card sx={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant='h5'><b>{t('KYC_PROCEDURES')}</b></Typography>
        <KYCProcedureHistoryTable allKYCProcedure={allKYCProcedure} clientName={props.clientName}/>
      </Card>
      <Card sx={{ padding: '20px' }}>
        <Typography variant='h5'><b>{t('AML_PROCEDURES')}</b></Typography>
          <AMLProcedureHistoryTable allAMLProcedure={allAMLProcedure} clientName={props.clientName}/>
      </Card>
    </>
  );
};
export default ProcedureHistory;

import decode from 'jwt-decode';
import { JWT } from '../../models/userroles/users/JWT';
import { AUTH_TOKEN } from '../utility';

export function useAuth() {

  const token: any = localStorage.getItem(AUTH_TOKEN);

  try {
    let decodedToken: JWT = decode(token);
    return {
      auth: true,
      role: decodedToken.roles
    };

  } catch (error) {
    return {
      auth: false,
      role: null
    };
  }
}

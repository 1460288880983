import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ActionButtonProps } from "./ActionButtonProps";

const ActionButton = (props: ActionButtonProps) => {
  const onHandleClick = () => {
    if (props.action) {
      props.action(props.element);
    }
  };

  return (
    <>
      {props.type === "edit" && (
        <IconButton
          className={"edit-icon-wrapper"}
          edge="end"
          aria-label="edit"
          sx={{ marginRight: "5px" }}
          onClick={onHandleClick}
        >
          <EditIcon className={"edit-icon-white"} color={"secondary"} />
        </IconButton>
      )}
      {props.type === "delete" && (
        <IconButton
          className={"delete-icon-wrapper-purple"}
          edge="end"
          aria-label="edit"
          onClick={onHandleClick}
        >
          <DeleteIcon className={"delete-icon-white"} color={"secondary"} />
        </IconButton>
      )}
      {props.type === "add" && (
        <IconButton
          className={"add-icon-wrapper-purple"}
          edge="end"
          aria-label="edit"
          onClick={onHandleClick}
        >
          <AddIcon className={"add-icon-white"} color={"secondary"} />
        </IconButton>
      )}
    </>
  );
};
export default ActionButton;

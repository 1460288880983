import {Container, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import CountriesGroup from '../../components/Countries/CountriesGroup';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import {CountryDto} from '../../models/admin/CountryDto';
import {CountryGroupDto} from '../../models/admin/CountryGroupDto';
import CountryGroupService from '../../repository/CountryGroupService';
import CountryService from '../../repository/CountryService';
import './Countries.scss';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useTranslation} from 'react-i18next';
import {Permission} from "../../auth/Permission";
import ProtectedComponent from 'shared/components/protected-component/ProtectedComponent';

const Countries: React.FC = () => {
  const [countriesList, setCountriesList] = useState<CountryDto[]>([]);
  const [countryGroupsList, setCountryGroupsList] = useState<CountryGroupDto[]>(
    []
  );
  const [continent, setContinent] = useState<string>('Europe');
  const [searchCountry, setSearchCountry] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleClickFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    const formData = new FormData();
    formData.append('file', fileUploaded!);
    CountryService.importFileForCountry(formData).then((data) => {
      refreshData();
      SwalAlert.successfulAlert(t('SUCCESS'), t('CHANGE_REQUEST_CREATED'));
    }).catch(() => SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG')));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setContinent(event.target.value);
  };

  const handleSearchChange = (event: any) => {
    setSearchCountry(event.target.value);
  };

  const getAllCountries = (searchCountry: string) => {
    // setIsLoading(true);
    CountryService.getAllCountries(searchCountry)
      .then((data) => {
        setCountriesList(data.data);
        // setIsLoading(false);
      })
      .catch(() => {
      });
  };

  const getAllCountryGroups = () => {
    CountryGroupService.getAllCountryGroups()
      .then((data) => {
        setCountryGroupsList(data.data);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    getAllCountries(searchCountry);
  }, [searchCountry]);

  useEffect(() => {
    getAllCountryGroups();
  }, []);

  const refreshData = () => {
    getAllCountries(searchCountry);
  };

  return (
    <div style={{ margin: '20px' }}>
      <div>
        <h1 className='headerPage'>{t('COUNTRIES_SETTINGS')}</h1>
        <p>{t('SELECT_COUNTRY_TO_DISABLE')}</p>
      </div>
      <div className='search-container'>
        <Container maxWidth='md' sx={{ m: 0 }}>
          <TextField
            id='search'
            type='search'
            focused
            color={'primary'}
            label='Search country by name'
            value={searchCountry}
            onChange={handleSearchChange}
            sx={{ width: 600 }}/>
        </Container>
      </div>
      <div>
        <div className='country-container'>
          <div className={'country-options-wrapper'}>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Select
                value={continent}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className='continentSelect'>
                {countryGroupsList.map((countryGroup) => {
                  return (
                    <MenuItem value={countryGroup.label} key={countryGroup.id}>
                      {countryGroup.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div>
              <ProtectedComponent permission={Permission.FE_CAN_UPLOAD_COUNTRY_RISK_EXCEL_FILE}>
              <Button
                  variant='outlined'
                  className='button-upload'
                  startIcon={<FileUploadIcon/>}
                  onClick={handleClickFileUpload}
              >
                <b>{t('UPLOAD_FILE')}</b>
              </Button>
              </ProtectedComponent>
              <input
                type='file'
                className={'hidden-button'}
                onChange={handleChangeFile}
                ref={hiddenFileInput}/>
            </div>
          </div>
          <CountriesGroup
            continent={continent}
            countriesList={countriesList}
            reloadDataCallback={refreshData}/>
        </div>
      </div>
    </div>
  );
};
export default Countries;
import { Box, TextField } from '@mui/material';
import React from 'react';
import { QuestionFileProps } from './QuestionFileProps';

const QuestionFileComponents = (props: QuestionFileProps) => {
    return (
        <Box alignItems={'center'} marginTop={"10px"} marginBottom={"10px"}>
            <Box marginBottom={"5px"}>
                {props.file.title} {props.file.title && ":"}
            </Box>
            <Box alignItems={'center'} width={"500px"} >
              <TextField
                disabled
                type={'file'}
                label=""
                size={"small"}/>
            </Box>
        </Box>
    )


}
export default QuestionFileComponents;

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from '../../../components/AddButton/AddButton';
import CopyTextField from '../../../components/CopyTextField/CopyTextField';
import { ProcedureTypeShortName } from '../../../models/procedure/ProcedureTypeShortName';
import { DateTimeUtils } from '../../../utils/DateTimeUtils';
import { ProcedureLinkProps } from './ProcedureLinkProps';

const ProcedureLink = (props: ProcedureLinkProps) => {
  const { t } = useTranslation();

  const renderEmptyProcedureInfo = () => {
    return <Box className={'no-initiate-procedure-info'}>
      <i>{t('NOT_INITIATED_PROCEDURE')}!</i>
    </Box>;
  };

  return (
    <Box marginBottom={2} padding={2}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingRight={5}>
        <Typography variant={'h6'} marginBottom={2} marginTop={2} color={'primary'}>
          {props.procedureType === ProcedureTypeShortName.KYC ? t('KYC_PROCEDURE_DETAILS') : t('AML_PROCEDURE_DETAILS')}:
        </Typography>
        {(props.showInitiateProcedureButton === undefined || props.showInitiateProcedureButton) && props.isLinkExpired && ((props.procedureType === ProcedureTypeShortName.KYC) || (props.procedureType === ProcedureTypeShortName.AML && props.hasKycProcedure)) &&
          <AddButton text={t('INITIATE_NEW_PROCEDURE')}
                     size={'small'}
                     onClickHandle={() => props.initiateProcedure(props.procedureType)}/>
        }
      </Box>
      {props.tokenDetails ?
        <>
          <CopyTextField width={'100%'} label={props.procedureType === ProcedureTypeShortName.KYC ? t('KYC_LINK') : t('AML_LINK')}
                         value={props.tokenDetails?.link!}/>
          <Box className={'link-info-wrapper'} display={'flex'} justifyContent={'space-between'}
               flexWrap={'wrap'}
               marginY={1} alignItems={'center'}
               alignContent={'center'}
               paddingRight={5}>
            <Box display={'flex'}>
              <Typography variant={'body1'} color={'primary'} display={'flex'} alignItems={'center'}>
                {props.isLinkExpired ? t('LINK_EXPIRED') : t('LINK_ACTIVE')}
              </Typography>
              <Typography variant={'body1'} display={'flex'} alignItems={'center'}>
                &nbsp;{DateTimeUtils.formatDateTime(props.tokenDetails?.expirationDate)}&nbsp;{props.isLinkExpired ?
                <DangerousIcon color={'error'}/> : <CheckCircleIcon color={'success'}/>}
              </Typography>
            </Box>
          </Box>
        </> : renderEmptyProcedureInfo()
      }
    </Box>
  );
};
export default ProcedureLink;
import { Box, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/notification/notificationSlice';
import LightTooltip from '../LightTooltip/LightTooltip';
import { CopyTextFieldProps } from './CopyTextFieldProps';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyTextField = ({ label, value, width }: CopyTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCopyClick = () => {
    dispatch(showMessage('Author added successfully'));
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <Box display={'flex'} alignItems={'center'} width={width ? width : '100%'}>
      <TextField
        label={label}
        value={value}
        variant='outlined'
        fullWidth
        focused={true}
        size={'small'}
        inputRef={inputRef}
        InputProps={{
          readOnly: true
        }}/>
      <LightTooltip title={t('Copy')}>
        <IconButton onClick={handleCopyClick} aria-label='Copy'>
          <ContentCopyIcon color={'primary'}/>
        </IconButton>
      </LightTooltip>
    </Box>
  );
};
export default CopyTextField;
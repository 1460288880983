import axios from '../axios/axios';
import { ChangeRequestDetailsResponse } from '../models/changeRequests/ChangeRequestDetailsResponse';
import { ChangeRequestFilter } from '../models/changeRequests/ChangeRequestFilter';
import { ChangeRequestResponse } from '../models/changeRequests/ChangeRequestResponse';
import { Page } from '../pagination/Page';

export class ChangeRequestsService {
  static async listChangeRequests(
    changeRequestFilter: ChangeRequestFilter,
    pageNo: number,
    pageSize: number
  ): Promise<Page<ChangeRequestResponse>> {
    const response = await axios.post<Page<ChangeRequestResponse>>(
      '/change-request/list-all',
      changeRequestFilter,
      {
        params: {
          pageNo,
          pageSize
        }
      }
    );
    const data = response.data;
    return new Page<ChangeRequestResponse>(
      data.content.map((changeRequest) => new ChangeRequestResponse(changeRequest)),
      data.totalElements
    );
  }

  static async getChangeRequestById(id: string): Promise<ChangeRequestResponse> {
    const response = await axios.get(`/change-request/get/${id}`);
    return response.data;
  }

  static async checkAndApproveChangeRequests(id: string, status: boolean): Promise<void> {
    await axios.post(`/change-request/check/${id}`, null, {
      params: { status }
    });
  }

  static async getChangeRequestDetails(id: string): Promise<ChangeRequestDetailsResponse> {
    const response = await axios.get(`/change-request/get-details/${id}`);
    return response.data;
  }
}
import axios from '../axios/axios';
import { ShareholderRoleDto } from '../models/legal_entity/ShareholderRoleDto';
import { ShareholderTypeDto } from '../models/legal_entity/ShareholderTypeDto';
import { ShareholderDto } from '../models/procedure/ShareholderDto';

const ShareholderService = {
  async allShareholderTypes(): Promise<ShareholderTypeDto[]> {
    const response = await axios.get('/shareholder/all-types');
    return response.data;
  },
  async allShareholderRoles(): Promise<ShareholderRoleDto[]> {
    const response = await axios.get('/shareholder/all-roles');
    return response.data;
  },
  async allShareholdersByKycProcedure(id: number): Promise<ShareholderDto[]> {
    const response = await axios.get('/shareholder/all-by-kyc-procedure/' + id);
    return response.data;
  }
};

export default ShareholderService;
export class MMEntityResponseDto {
    id?: number;
    name?: string;
    label?: string;
  
    constructor(json: MMEntityResponseDto) {
      this.id = json.id;
      this.name = json.name;
      this.label = json.label;
    }
  }
  
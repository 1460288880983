import { Box, Typography, Card, CardContent } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ClientsByProcedureStatus } from '../../models/chart/ClientsByProcedureStatus';
import { ClientService } from '../../repository/ClientService';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const ProcedureStatusChart = () => {
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [clientsByProcedureStatusList, setClientsByProcedureStatusList] = useState<ClientsByProcedureStatus[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    clientsByProcedureStatus();
  }, [dateFrom, dateTo]);

  const clientsByProcedureStatus = async () => {
    const formattedDateFrom = dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : null;
    const formattedDateTo = dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : null;

    ClientService.clientsByProcedureStatusNotNew(formattedDateFrom, formattedDateTo).then((data) => {
      setClientsByProcedureStatusList(data);
    });
  };

  const handleDateFrom = (newDate: Date | null) => {
    if (newDate != null) setDateFrom(newDate);
  };

  const handleDateTo = (newDate: Date | null) => {
    if (newDate != null) setDateTo(newDate);
  };
  const colors = ['#0088FE', '#00C49F', '#9C27B0', '#cb7717'];

  const chartTitleStyles: SxProps = {
    textAlign: 'center',
    variant: 'h5',
    marginBottom: 3,
    fontWeight: 'bold',
    marginTop: { xs: 5, sm: 0 }
  };

  const chartBoxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center'
  };

  const datePickerBoxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' },
    marginTop: {xs:2 , sm: 3}
  };

  const datePickerStyles: SxProps = {
    marginRight: { sm: 3 },
    marginBottom: { md: 10, lg: 0 },
    width: { xs: '200px', sm: '200px', md: '300px', lg: '200px', xl: '270px' }
  };

  const legendCircleStyles: SxProps = {
    width: 10,
    height: 10,
    marginRight: 1,
    borderRadius: '50%'
  };

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)',
  };

  return (
    <>
      <Box>
        <Card sx={cardStyles}>
          <CardContent>
            <Box sx={chartTitleStyles}>
              <Typography textAlign='center' variant='h5' marginBottom={3} fontWeight='bold' marginTop={{ xs: 5, sm: 0 }}>
                {t('PROCEDURE_STATUS_CHART')}
              </Typography>
            </Box>
            <Box sx={chartBoxStyles}>
              {clientsByProcedureStatusList.length > 0 ? (
                <Box display='flex'>
                  <Chart
                    options={{
                      chart: { type: 'pie' },
                      labels: clientsByProcedureStatusList.map((client) => client.status),
                      responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { enabled: false, show: false } } }],
                      legend: { show: false },
                      colors: colors
                    }}
                    series={clientsByProcedureStatusList.map((client) => client.count)}
                    type='donut'
                    width='350'
                  />
                  <Box sx={{ mb: 3, mt: 2 }}>
                    {clientsByProcedureStatusList.map((client, index) => (
                      <Box key={client.status} display='flex' alignItems='center' marginTop={1}>
                        <Box sx={{ ...legendCircleStyles, backgroundColor: colors[index] }}/>
                        <Typography variant='body2'>{`${client.status}: ${client.count}`}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box height='25.5vh' display='flex' justifyContent='center' alignItems='center'>
                  <Typography>No data available</Typography>
                </Box>
              )}
            </Box>
            <Box sx={datePickerBoxStyles}>
              <Box sx={datePickerStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label='Date from' onChange={handleDateFrom}/>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box sx={datePickerStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label='Date to' onChange={handleDateTo}/>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ProcedureStatusChart;

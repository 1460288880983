import { Box, Typography, Card, CardContent } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ClientsByType } from '../../models/chart/ClientsByType';
import { ClientService } from '../../repository/ClientService';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const ClientTypeChart = () => {

  const [clientsByType, setClientsByType] = useState<ClientsByType>({ individuals: 0, legalEntities: 0 });

  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const { t } = useTranslation();

  useEffect(() => {
    clientTypeStats();
  }, [dateFrom, dateTo]);

  const clientTypeStats = async () => {
    const formattedDateFrom = dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : null;
    const formattedDateTo = dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : null;

    ClientService.clientTypeStats(formattedDateFrom, formattedDateTo).then((data) => {
      setClientsByType(data);
    });
  };

  const handleDateFrom = (newDate: Date | null) => {
    if (newDate != null) setDateFrom(newDate);
  };

  const handleDateTo = (newDate: Date | null) => {
    if (newDate != null) setDateTo(newDate);
  };

  const chartTitleStyles: SxProps = {
    textAlign: 'center',
    variant: 'h5',
    marginBottom: 3,
    fontWeight: 'bold',
    marginTop: { xs: 5, sm: 0 }
  };

  const chartBoxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center'
  };

  const datePickerBoxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' },
    marginTop: {xs:2 , sm: 3}
  };

  const datePickerStyles: SxProps = {
    marginRight: { sm: 3 },
    width: { xs: '200px', sm: '200px', md: '300px', lg: '200px', xl: '270px' }
  };

  const legendCircleStyles: SxProps = {
    width: 10,
    height: 10,
    marginRight: 1,
    borderRadius: '50%'
  };

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)',
  };

  return (
    <>
      <>
        <Box>
          <Card sx={cardStyles}>
            <CardContent>
              <Box sx={chartTitleStyles}>
                <Typography textAlign='center' variant='h5' marginBottom={3} fontWeight='bold' marginTop={{ xs: 5, sm: 0 }}>
                  {t('CLIENT_TYPE_CHART')}
                </Typography>
              </Box>
              <Box sx={chartBoxStyles}>
                {clientsByType.individuals == 0 && clientsByType.legalEntities == 0 ? (
                  <Box height='25.5vh' display='flex' justifyContent='center' alignItems='center'>
                    <Typography>No data available</Typography>
                  </Box>
                ) : (
                  <Box display='flex'>
                    <Chart
                      options={{
                        chart: { type: 'pie' },
                        labels: ['Individuals', 'Legal  entities'],
                        responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { enabled: false, position: 'top' } } }],
                        legend: { show: false }
                      }}
                      series={[clientsByType?.individuals, clientsByType?.legalEntities]}
                      type='donut'
                      width='350'
                    />
                    <Box marginBottom={3} marginTop={2}>
                      <Box display='flex' alignItems='center' marginRight={2}>
                        <Box sx={{ ...legendCircleStyles, backgroundColor: '#0088FE' }}/>
                        <Typography variant='body2'>{t("INDIVIDUALS")}: {clientsByType.individuals}</Typography>
                      </Box>
                      <Box display='flex' alignItems='center' marginTop={1}>
                        <Box sx={{ ...legendCircleStyles, backgroundColor: '#00C49F' }}/>
                        <Typography variant='body2'>{t("LEGAL_ENTITIES")}: {clientsByType.legalEntities}</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={datePickerBoxStyles}>
                <Box sx={datePickerStyles}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker label='Date from' onChange={handleDateFrom}/>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box sx={datePickerStyles}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker label='Date to' onChange={handleDateTo}/>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </>
    </>
  );
};
export default ClientTypeChart;
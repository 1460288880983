import React from 'react';
import { Box, Table, TableHead, TablePagination } from '@mui/material';
import PageTableHead from './PageTableHead';
import PageTableBody from './PageTableBody';
import { useLayoutEffect } from 'react';
import './Table.scss';

/**
 *
 * @todo: change css to scss and use colors styles
 * @todo: fix table pagination, display either in center or right
 */
const PageTable = (props: any) => {
  useLayoutEffect(() => {}, [props.searchRows]);

  function handleOpen(id: number) {
    props.handleOpen(id);
  }

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
        <Table
          stickyHeader
          aria-labelledby='tableTitle'
          className={props.className}
        >
          <TableHead className='tableHead'>
            <PageTableHead
              headerRows={props.headerRows}
              searchRows={props.searchRows}
            />
          </TableHead>
          <PageTableBody
            rows={props.rows}
            link={props.link}
            headerRows={props.headerRows}
            handleOpen={handleOpen}
            clickable={props.clickable}
            data={props.data}
            // modalComponent={props.modalComponent}
            // modalFunction={props.modalFunction}
          />
        </Table>

        {props?.rows?.length > 0 && props.rowsPerPage && (
          <TablePagination
            className='table-color pagination-div'
            component='div'
            rowsPerPageOptions={[2, 5, 10, 100]}
            count={props.totalElements}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};
export default PageTable;

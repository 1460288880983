import { Box } from "@material-ui/core";
import { Grid, Paper } from "@mui/material";
import "./AMLProcedureWrapper.css";

type WrapperProps = {
  children?: React.ReactNode;
};
export function AMLProcedureWrapper(props: WrapperProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="verification-aml-page"
    >
      <Paper elevation={12} className="paper-wrapper">
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {props.children}
        </Grid>
      </Paper>
    </Box>
  );
}

import { Box } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useMemo } from 'react';
import ConvertDataService from '../../../shared/ConvertDataService';

const PDFViewer = (props: any) => {

  const fileUrl = useMemo(() => {
    if (!props.fileForReview) {
      return null;
    }
    const blob = ConvertDataService.base64toBlob(props.fileForReview);
    return URL.createObjectURL(blob);
  }, [props.fileForReview]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Box>
      <Box className={'pdf-container'} height={'100%'} sx={{ overflowY: 'auto' }}>
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
          {fileUrl && <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]}></Viewer>}
        </Worker>
      </Box>
    </Box>
  );
};
export default PDFViewer;

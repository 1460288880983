import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Option } from '../../models/shared/Option';
import CountryService from '../../repository/CountryService';
import CountriesDetails from './CountriesDetails';
import { CountriesGroupProps } from './CountriesGroupProps';

const CountriesGroup = ({ continent, countriesList, reloadDataCallback }: CountriesGroupProps) => {
  const [countriesRiskList, setCountriesRiskList] = useState<Option[]>([]);

  useEffect(() => {
    getAllCountriesRisks();
  }, []);

  const getAllCountriesRisks = () => {
    CountryService.getAllCountriesRisks().then((data: Option[]) => {
      setCountriesRiskList(data);
    });
  };

  const handleItemChange = () => {
    reloadDataCallback();
  };

  return (
    <Grid container sx={{ margin: '20px 0px' }}>
      <Stack direction='row' useFlexGap flexWrap='wrap' sx={{ width: '100%' }}>
        {countriesList.map((country) => {
          if (country.continentName === continent) {
            return (
              <Grid xs={12} md={6} lg={4} item key={country.countryId}>
                <CountriesDetails country={country} key={country.countryId} onChange={handleItemChange}
                                  countriesRiskList={countriesRiskList}/>
              </Grid>
            );
          }
          return <React.Fragment key={country.countryId}/>
        })}
      </Stack>
    </Grid>
  );
};
export default CountriesGroup;
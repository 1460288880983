import axios from '../axios/axios';

export class ClientTokenService {
  static async validateClientToken(
    tokenParam: string | null
  ): Promise<any | string> {
    const response = await axios.post(
      '/clientToken/public/validate-client-token?tokenParam=' + tokenParam
    );
    return response;
  };

  static async isTokenExpired(
    token: string
  ): Promise<boolean> {
    const response = await axios.get(
      '/clientToken/public/is-token-expired?token=' + token
    );

    return response.data;
  }
}

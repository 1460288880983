export class ErrorHandler {
  static parseErrorMessage(e: any): string {
    const response = e?.response?.data;
    return (
      response ??
      response?.localizedMessage ??
      response?.message ??
      response?.error ??
      e.message ??
      ""
    );
  }
}

import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AMLQuestionnaires from './AMLQuestionnaires/AMLQuestionnaires';
import { useLocation } from 'react-router-dom';

const ClientQuestionnaires = () => {
  const [tabKey, setTabKey] = useState<number>(0);
  const { state } = useLocation();

  useEffect(() => {
    if (state != null) {
      if (state.activeTab === 'Legal Entity Group') {
        setTabKey(1);
      }
    }
  }, [state]);

  const handleChangeTab = (event: React.SyntheticEvent, value: any) => {
    setTabKey(value);
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  return (
    <Box>
      <Tabs
        value={tabKey}
        onChange={handleChangeTab}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable terms and conditions entities'
      >
        <Tab label={'INDIVIDUAL'} {...a11yProps(0)} />
        <Tab label={'LEGAL ENTITY'} {...a11yProps(1)} />
      </Tabs>
      <Card variant='outlined'>
        <CardContent>
          <AMLQuestionnaires
            tabKey={tabKey}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
export default ClientQuestionnaires;

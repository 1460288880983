import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '../../auth/Permission';
import ActionButton from '../../components/ActionButton/ActionButton';
import AddButton from '../../components/AddButton/AddButton';
import LightTooltip from '../../components/LightTooltip/LightTooltip';
import './Users.scss';
import PageTable from '../../components/Table/PageTable';
import UserFilter from '../../components/UserFilter/UserFilter';
import { UserDto } from '../../models/userroles/users/UserDto';
import { UserFilterData } from '../../models/userroles/users/UserFilterData';
import UserService from '../../repository/UserService';
import ProtectedComponent from '../../shared/components/protected-component/ProtectedComponent';
import { AuthUtils } from '../../utils/AuthUtils';
import UserAddModal from './modals/UserAddModal';
import Swal from 'sweetalert2';
import {ErrorHandler} from "../../utils/ErrorHandler";

const Users: React.FC = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(200);
  const [usersList, setUsersList] = useState<UserDto[]>([]);
  const [openAddUserModal, setOpenAddUserModal] = React.useState(false);
  const [userFilterData, setUserFilterData] = useState<UserFilterData>({
    firstName: '',
    lastName: '',
    email: '',
    role_id: []
  });
  const [selectedUserForEdit, setSelectedUserForEdit] = useState<UserDto>();
  const { t } = useTranslation();

  const handleClickOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };
  const handleCloseAddUserModal = () => {
    setSelectedUserForEdit(undefined);
    setOpenAddUserModal(false);
  };

  useEffect(() => {
    getAllUserFiltred();
  }, [userFilterData, page, rowsPerPage]);

  const getAllUserFiltred = (): void => {
    UserService.getAllUser(page, rowsPerPage, userFilterData)
      .then((data) => {
        setUsersList(data.data.content);
        setTotalElements(data.data.totalElements);
      })
      .catch(() => {});
  };

  const handleUserFilter = (
    firstName: string | undefined,
    lastName: string | undefined,
    email: string | undefined,
    role_id: number[] | undefined
  ): void => {
    setUserFilterData({ firstName, lastName, email, role_id });
  };

  const onHandleEditActionButton = (element: UserDto) => {
    setSelectedUserForEdit(element);
    handleClickOpenAddUserModal();
  };

  const onHandleDeleteActionButton = (element: UserDto) => {
    Swal.fire({
      title: t('ARE_YOU_SURE_DELETE'),
      showCancelButton: true,
      confirmButtonText: t('YES')
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteUser(element.id)
          .then(() => {
            Swal.fire(t('DELETED'), t('USER_DELETED_SUCCESSFULLY'), 'success');
            getAllUserFiltred();
          })
          .catch((error) => {
            const errorMessage = ErrorHandler.parseErrorMessage(error);
            Swal.fire(t('ERROR'), errorMessage);
          });
      }
    });
  };
  const headerRows = [
    {
      id: 'email',
      label: t('EMAIL'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'firstName',
      label: t('FIRST_NAME'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'lastName',
      label: t('LAST_NAME'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'phoneNumber',
      label: t('PHONE_NUMBER'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'roles',
      label: t('ROLES'),
      search: true,
      type: 'boolean'
    },
    {
      id: 'address',
      label: t('ADDRESS'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'dataCreated',
      label: t('DATE_CREATED'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'dataModified',
      label: t('DATE_MODIFIED'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'active',
      label: t('ACTIVE'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'status',
      label: t('STATUS'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    ...(AuthUtils.hasPermission(Permission.FE_CAN_CREATE_EDIT_USERS) ? [{
      id: 'action',
      label: '',
      search: true,
      type: 'text',
      variant: 'standard'
    }] : [])
  ];

  const rows = usersList.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: 'text',
          name: 'email',
          value: (
            <LightTooltip className={'tableTooltip'} title={element.email}>
              <span>{element.email}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'First name',
          value: (
            <LightTooltip title={element?.firstName}>
              <span>{element?.firstName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Last name',
          value: (
            <LightTooltip title={element?.lastName}>
              <span>{element?.lastName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Phone number',
          value: (
            <LightTooltip
              className={'tableTooltip'}
              title={element?.phoneNumber}
            >
              <span>{element?.phoneNumber}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Role',
          value: (
            <>
              {element.userRoles.map((role, indexOf) => {
                return (
                  <span key={role.id}>
                    {role.name !== 'CHANGE_APPROVER' ? role.label : null}
                    {role.name !== 'CHANGE_APPROVER'
                      ? indexOf !== element.userRoles.length - 1
                        ? ' '
                        : ''
                      : null}
                  </span>
                );
              })}
            </>
          )
        },
        {
          type: 'text',
          name: 'Address',
          value: (
            <LightTooltip className={'tableTooltip'} title={'Ilindenska 109'}>
              <span>{element.address}</span>
            </LightTooltip>
          )
        },
        {
          type: 'date',
          name: 'Date created',
          value: element?.dateCreated && (
            <LightTooltip
              className={'tableTooltip'}
              title={element?.dateCreated}
            >
              <span>{element?.dateCreated.toString().split('T')[0]}</span>
            </LightTooltip>
          )
        },
        {
          type: 'date',
          name: 'Date modify',
          value: element.dateModified && (
            <LightTooltip
              className={'tableTooltip'}
              title={element?.dateModified}
            >
              <span>{element.dateModified.toString().split('T')[0]}</span>
            </LightTooltip>
          )
        },
        {
          type: 'boolean',
          name: 'Active',
          value: element?.blocked
        },
        {
          type: 'boolean',
          name: 'Enabled',
          value: element?.enabled
        },
        ...(AuthUtils.hasPermission(Permission.FE_CAN_CREATE_EDIT_USERS) ? [{
          type: 'text',
          name: '',
          value: element?.dateCreated && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <ActionButton
                type={'edit'}
                action={onHandleEditActionButton}
                element={element}
              />
              <ActionButton
                type={'delete'}
                action={onHandleDeleteActionButton}
                element={element}
              />
            </Box>
          )
        }] : [])
      ]
    };
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  function handleOpen(id: number) {
    // navigate(Routes.USER_WITH_ID.replace(":id", id.toString()));
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <div className='user_header-wrapper'>
        <ProtectedComponent permission={Permission.FE_CAN_CREATE_EDIT_USERS}>
          <AddButton
            onClickHandle={handleClickOpenAddUserModal}
            text={t('ADD')}
            icon={<AddIcon className='buttonIcon'/>}
          />
        </ProtectedComponent>
      </div>

      <UserFilter onFilterSubmit={handleUserFilter}/>
      <div className='tableContainer'>
        <PageTable
          headerRows={headerRows}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          totalElements={totalElements}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOpen={handleOpen}
          clickable={true}
        />
      </div>
      <UserAddModal
        open={openAddUserModal}
        handleClose={handleCloseAddUserModal}
        selectedUserForEdit={selectedUserForEdit}
        getAllUserFiltred={getAllUserFiltred}
      />
    </div>
  );
};
export default Users;

import axios from '../axios/axios';
import { CryptoCurrencyDto } from '../models/icoToken/CryptoCurrencyDto';
import { FiatCurrencyDto } from '../models/icoToken/FiatCurrencyDto';
import { IcoTokenRequest } from '../models/icoToken/IcoTokenRequest';
import { IcoTokenResponse } from '../models/icoToken/IcoTokenResponse';

const IcoTokenService = {
  async findAllByClient(id: string): Promise<IcoTokenResponse[]> {
    const response = await axios.get('/ico-token/all-by-client/' + id);
    return response.data;
  },
  async findById(id: number): Promise<IcoTokenResponse> {
    const response = await axios.get('/ico-token/' + id);
    return response.data;
  },
  async delete(id: number) {
    await axios.delete('/ico-token/delete/' + id);
  },
  async allFiat(): Promise<FiatCurrencyDto[]> {
    const response = await axios.get('/currency/all-fiat');
    return response.data;
  },
  async allCrypto(): Promise<CryptoCurrencyDto[]> {
    const response = await axios.get('/currency/all-crypto');
    return response.data;
  },
  async createOrUpdate(icoTokenRequest: IcoTokenRequest, id: number) {
    await axios.post('/ico-token/create-or-update/' + id, icoTokenRequest);
  }
};

export default IcoTokenService;
import { AxiosResponse } from 'axios';
import axios from '../axios/axios';
import { UserDetails } from '../models/auth/UserDetails';

const LoginService = {
    loginUser: (request:string) => {
        return axios.post('login', request, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + request,
            }
        })
    },

    getLoggedUserDetails: (): Promise<AxiosResponse<UserDetails>> => axios.get('user-details')
}

export default LoginService;

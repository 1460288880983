import { Permission } from '../auth/Permission';
import { PERMISSIONS } from '../shared/utility';

export class AuthUtils {

  static hasPermission(permission: Permission): boolean {
    const permissionString = localStorage.getItem(PERMISSIONS) ?? '[]';
    const permissions: Permission[] = JSON.parse(permissionString);
    return !!permissions.find(el => el === permission);
  }
}

import { combineReducers } from 'redux';
import notificationReducer from './notification/notificationSlice';
import questionReducer from './questions/questionsSlice'
import tableReducer from './table/tableSlice';

const rootReducer = combineReducers({
  notification: notificationReducer,
  question: questionReducer,
  table: tableReducer
});

export default rootReducer;

import { Box, CircularProgress } from '@mui/material';
import "./Spinner.scss";
import { SpinnerProps } from './SpinnerProps';

const Spinner = (props:SpinnerProps) => {

  return (
    <Box hidden={!props.showSpinner}>
      <Box className={'spinner-container'}>
        <CircularProgress color='secondary' style={{
          position: 'absolute', opacity: '100%', left: '50%', top: '50%', width: '3rem', height: '3rem', zIndex: 1011
        }}/>
      </Box>
    </Box>

  );
};
export default Spinner;
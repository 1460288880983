import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';
import { TextEditorProps } from './TextEditorProps';

const TextEditor = (props:TextEditorProps) => {
    const [editorState,setEditorState]=useState(EditorState.createEmpty())

    const onEditorStateChange = (editorState: EditorState) => {
        console.log(  draftToHtml(convertToRaw(editorState.getCurrentContent())))
        props.setEditorStateHtmlText(  draftToHtml(convertToRaw(editorState.getCurrentContent())))
        props.setEditorState(editorState);
    };

    return(
        <div>
            <Editor
                editorState={props.editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'image', 'emoji'],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                    blockType: {
                        options: ['Normal', 'H1', 'H2', 'H3','H4', 'H5'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                    // colorPicker: {
                    //   className: 'color-picker-class',
                    // },

                    fontSize: {
                        options: [8, 10, 12, 14, 16, 18, 24, 30],
                    },
                }}
            />
        </div>
    )
}
export  default TextEditor;

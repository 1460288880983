import { Box, Typography, Card, CardContent } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ClientsByCountry } from '../../models/chart/ClientsByCountry';
import { ClientService } from '../../repository/ClientService';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const CountriesChart = () => {

  const [countryClients, setCountryClients] = useState<ClientsByCountry[]>([]);

  const [dateFromCountryFilter, setDateFromCountryFilter] = useState<Date>();
  const [dateToCountryFilter, setDateToCountryFilter] = useState<Date>();
  const { t } = useTranslation();

  useEffect(() => {
    clientByCountry();
  }, [dateFromCountryFilter, dateToCountryFilter]);

  const handleDateFrom = (newDate: Date | null) => {
    const formattedDate = dayjs(newDate).format('DD-MM-YYYY');
    if (newDate != null) {
      setDateFromCountryFilter(newDate);
    }
  };

  const handleDateTo = (newDate: Date | null) => {
    const formattedDate = dayjs(newDate).format('DD-MM-YYYY');
    if (newDate != null) {
      setDateToCountryFilter(newDate);
    }
  };

  const clientByCountry = () => {
    const formattedDateFrom = dateFromCountryFilter ? dayjs(dateFromCountryFilter).format('YYYY-MM-DD') : null;
    const formattedDateTo = dateToCountryFilter ? dayjs(dateToCountryFilter).format('YYYY-MM-DD') : null;

    ClientService.clientsByCountry(formattedDateFrom, formattedDateTo).then((data) => {
      setCountryClients(data);
    });
  };

  const countriesChartContainerStyles: SxProps = {
    maxWidth: '99%'
  };

  const chartTitleStyles: SxProps = {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 3
  };

  const chartBoxStyles: SxProps = {
    maxWidth: { xs: '350px', sm: 'none' }
  };

  const datePickerBoxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' },
    marginBottom: 5
  };

  const datePickerStyles: SxProps = {
    width: { xs: '300px' },
    ml: { sm: 10 }
  };

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)',
  };

  return (
    <>
      <Box>
      <Card sx={cardStyles}>
        <CardContent>
          <Box sx={countriesChartContainerStyles}>
            <Typography variant={'h5'} sx={chartTitleStyles}>{t('CUSTOMERS_ACCORDING_COUNTRY')}</Typography>
            <Box sx={chartBoxStyles}>
              <Chart
                options={{
                  chart: { height: 500, type: 'bar' },
                  plotOptions: { bar: { borderRadius: 10, dataLabels: { position: 'top' } } },
                  dataLabels: { enabled: true, offsetY: -20, style: { fontSize: '12px', colors: ['#304758'] } },
                  xaxis: {
                    categories: countryClients.map((client) => client.countryName), position: 'top', axisBorder: { show: false }, axisTicks: { show: false },
                    crosshairs: {
                      fill: {
                        type: 'gradient',
                        gradient: { colorFrom: '#D8E3F0', colorTo: '#BED1E6', stops: [0, 100], opacityFrom: 0.4, opacityTo: 0.5 }
                      }
                    },
                    tooltip: { enabled: true }
                  },
                  yaxis: {
                    axisBorder: { show: false },
                    axisTicks: { show: false },
                    labels: {
                      show: false, formatter: function(val: number, opts?: any): string | string[] {
                        return Math.floor(val).toString();
                      }
                    }
                  }
                }}
                series={[{ name: 'Number of customers', data: countryClients.map((client) => client.count) }]} type='bar' height={350}
              />
            </Box>
          </Box>
          <Box sx={datePickerBoxStyles}>
            <Box sx={datePickerStyles}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label='Date from' onChange={handleDateFrom}/>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box sx={datePickerStyles}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label='Date to' onChange={handleDateTo}/>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
        </CardContent>
      </Card>
      </Box>
    </>
  );
};

export default CountriesChart
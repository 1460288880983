import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Buffer } from 'buffer';
import './Login.scss';
import SwalAlert from 'components/SwalAlert/SwalAlert';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoginData } from '../../models/userroles/users/LoginData';
import LoginService from '../../repository/LoginService';
import { AUTH_TOKEN, PERMISSIONS } from '../../shared/utility';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

/**
 *
 * @todo: change css to scss and use colors styles
 */
const Login = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<LoginData>();

  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      navigate('/');
    }
  }, []);

  const handleLogin: SubmitHandler<LoginData> = (data) => {
    let data2 = data.email + ':' + data.password;
    const request = Buffer.from(data2).toString('base64');
    LoginService.loginUser(request)
      .then((data) => {
        localStorage.setItem(AUTH_TOKEN, data.data);
        getUserDetails();
      })
      .catch(() => {
        SwalAlert.errorAlert(
          t('UNSUCCESSFUL_LOGIN'),
          t('UNSUCCESSFUL_LOGIN_INFO')
        );
      });
  };

  const getUserDetails = () => {
    LoginService.getLoggedUserDetails()
      .then(result => {
        const permissions = result.data.permissions;
        localStorage.setItem(PERMISSIONS, JSON.stringify(permissions));
        navigate('/');
      });
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Grid
      container
      className='grid'
      sx={{ height: '100vh' }}
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        xs={7}
        xl={3}
        md={5}
        lg={4}
        sm={7}
        item
        className='form'
        sx={{
          borderRadius: '1%',
          borderWidth: '3px',
          borderStyle: 'solid',
          borderColor: 'primary'
        }}
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          item
          width='100%'
          justifyContent='center'
          alignItems='center'
          sx={{
            mt: '40px',
            mb: '10px'
          }}
        >
          <Box className='text' width='200px' justifyContent='center'>
            <Typography
              textAlign='center'
              variant='h6'
              sx={{ fontWeight: 'italic' }}
            >
              {t('ACCOUNT_LOGIN')}
            </Typography>
          </Box>
        </Grid>

        <form style={{ width: '100%' }} onSubmit={handleSubmit(handleLogin)}>
          <Grid item width='100%'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                mb: '15px',
                ml: '35px',
                mr: '10px'
              }}
            >
              <AccountCircleIcon
                className='login-icons'
                sx={{
                  color: 'primary',
                  mr: 1,
                  my: 0.5
                }}
              />
              <TextField
                sx={{
                  width: '77%',
                  ml: '10px'
                }}
                {...register('email', { required: true })}
                id='input-with-sx-mail'
                label={t('USERNAME')}
                variant='standard'
                color='primary'
              />
            </Box>
          </Grid>
          <Grid item width='100%'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                my: '10px',
                ml: '35px',
                mr: '10px'
              }}
            >
              <EnhancedEncryptionIcon
                className='login-icons'
                sx={{
                  color: 'primary',
                  mr: 1,
                  my: 0.5
                }}
              />
              <TextField
                sx={{
                  width: '77%',
                  ml: '10px'
                }}
                {...register('password', { required: true })}
                type={showPassword ? 'text' : 'password'}
                id='input-with-sx-password'
                label={t('PASSWORD')}
                variant='standard'
                color='primary'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        sx={{
                          padding: 0,
                          color: 'primary'
                        }}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <a className={'login-links'} href='/forgot-password'>
              {t('FORGOT_PASSWORD')} ?
            </a>
          </Grid>
          <Grid item width='100%'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                ml: '70px',
                mr: '15px'
              }}
            >
              <Button
                type='submit'
                variant='contained'
                sx={{
                  width: '87%',
                  mb: '50px',
                  mt: '35px',
                  background: 'primary'
                }}
              >
                {t('LOGIN')}
              </Button>
            </Box>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { Box, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '../../../../auth/Permission';
import { FullPageLoadingCircle } from '../../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../../../models/aml/FileDTO';
import { ProcedureConversationFile } from '../../../../models/procedure/ProcedureConversationFile';
import { ProcedureConversationMessage } from '../../../../models/procedure/ProcedureConversationMessage';
import { ClientService } from '../../../../repository/ClientService';
import { FilePrivateService } from '../../../../repository/FilePrivateService';
import { FileService } from '../../../../repository/FileService';
import ProtectedComponent from '../../../../shared/components/protected-component/ProtectedComponent';
import { DateTimeUtils } from '../../../../utils/DateTimeUtils';
import { ProcedureType } from '../../enums/ProcedureType';
import { ClientConversationProps } from './ClientConversationProps';
import './mlro-client-conversation.scss';

const MlroClientConversation = (props: ClientConversationProps) => {
  const [conversationForProcedure, setConversationForProcedure] = useState<ProcedureConversationMessage[]>();
  const [message, setMessage] = useState<string>('');
  const [showSinner, setShowSpinner] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    getConversationsForProcedure();
  }, []);

  const initiateCommunication = () => {
    SwalAlert.confirmationAlert(
      t('SEND_MESSAGE_TO_CLIENT'),
      t('ARE_YOU_SURE_YOU_WANT_TO_SEND_MESSAGE_TO_CLIENT')
    ).then((result: any) => {
      const isConfirmed = result.isConfirmed;
      if (isConfirmed) {
        setShowSpinner(true);
        ClientService.initiateCommunication(props.procedureType === ProcedureType.KYC_PROCEDURE ? props.procedureId : null,
          props.procedureType === ProcedureType.AML_PROCEDURE ? props.procedureId : null, message)
          .then(() => {
            getConversationsForProcedure();
            setMessage('');
            setShowSpinner(false);
          }).catch(() => setShowSpinner(false));
      }
    });
  };

  const getConversationsForProcedure = () => {
    ClientService.getConversationForProcedure(props.procedureId, props.procedureType?.toUpperCase()!)
      .then((data: ProcedureConversationMessage[]) => {
        setConversationForProcedure(data);
      });
  };

  const handleCloseSpinner = () => {
    setShowSpinner(false);
  };

  const downloadDocument = (file: ProcedureConversationFile) => {
    if (file.mimeType.includes('image') || file.mimeType.includes('application/pdf')) {
      FilePrivateService.getBytesFromDocument(file.id).then((data: FileDTO) => {
        if (props.handleChangeSelectedFileForEdit) {
          data.mimeType = file.mimeType;
          props.handleChangeSelectedFileForEdit(data);
        }
      });
    } else {
      FilePrivateService.downloadDocuments(file.id, file.name);
    }
  };

  return (
    <Box padding={3} paddingTop={2} className='conversation-wrapper'>
      <Box>
        {conversationForProcedure?.map((message: ProcedureConversationMessage, index: number) => {
          return (
            <Box className={'mlro-client-conversation-message-wrapper'} marginY={2} padding={1} key={index}>
              <Box display={'flex'} alignItems={'center'}>
                <Avatar className={'conversation-message-avatar'}>
                  {message.userName !== null ? (props.clientName && props.clientName === message.userName ?
                    <PersonIcon/> : <ManageAccountsIcon/>) : <PersonIcon/>}
                </Avatar>
                <b>{message.userName !== null ? message.userName : props.clientName}</b>
                <Box display={'flex'} marginLeft={'auto'}>
                  <small>
                    {DateTimeUtils.formatDateObject(new Date(message.dateCreate))}
                  </small>
                </Box>
              </Box>
              <Box paddingLeft={5} marginLeft={1.2} marginTop={1}>
                {message.message} {message.messageFiles.map((file: ProcedureConversationFile) => {
                return (
                  <Box key={file.id} marginTop={1}
                       className={'action-pointer procedure-question-file'}
                       onClick={() => downloadDocument(file)}
                       title={`download ${file.name}`}>
                    <SimCardDownloadOutlinedIcon className={'procedure-file-icon'}/>{file.name}
                  </Box>);
              })}
              </Box>
            </Box>
          );
        })}
      </Box>
      <ProtectedComponent permission={Permission.FE_CAN_CREATE_CLIENT_PROCEDURE_COMMUNICATION}>
        <Box className='send-message'>
          <TextField
            id='procedureConversation'
            label={t('MESSAGE')}
            multiline
            fullWidth={true}
            color='primary'
            value={message}
            maxRows={4}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}/>
          <SendIcon className={'send-btn'} onClick={initiateCommunication}/>
        </Box>
      </ProtectedComponent>
      <FullPageLoadingCircle loading={showSinner} handleClose={handleCloseSpinner}/>
    </Box>
  );
};
export default MlroClientConversation;

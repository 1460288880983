import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from '../../repository/UserService';
import Swal from 'sweetalert2';
import './ForgotPassword.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  let navigate = useNavigate();
  const { t } = useTranslation();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    UserService.forgotPassword(email)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: t('SUCCESS'),
          text: t('RESET_LINK_SENT')
        }).then(() => {
          navigate('/login');
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: t('ERROR'),
          text: t('SOMETHING_WENT_WRONG')
        });
        console.error(error);
      });
  };

  return (
    <Grid
      container
      className='grid'
      sx={{ height: '100vh' }}
      justifyContent='center'
      alignItems='center'
      id='forgot-password'
    >
      <Grid
        xs={7}
        xl={3}
        md={5}
        lg={4}
        sm={7}
        item
        className='form'
        sx={{
          borderRadius: '1%',
          borderWidth: '3px',
          borderStyle: 'solid',
          borderColor: 'primary',
          padding: '20px'
        }}
        justifyContent='center'
        alignItems='center'
      >

        <Grid
          item
          width='100%'
          justifyContent='center'
          alignItems='center'
          sx={{
            mt: '40px',
            mb: '10px'
          }}
        >
          <Box className='text' width='200px' justifyContent='center'>
            <Typography
              textAlign='center'
              variant='h6'
              sx={{ fontWeight: 'italic' }}
            >
              {t('FORGOT_PASSWORD')}
            </Typography>
          </Box>
        </Grid>
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Grid item width='100%'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                mb: '15px',
                ml: '35px',
                mr: '10px'
              }}
            >
              <AccountCircleIcon
                className='login-icons'
                sx={{
                  color: 'primary',
                  mr: 1,
                  my: 0.5
                }}
              />
              <TextField
                sx={{
                  width: '77%',
                  ml: '10px'
                }}
                id='input-with-sx-mail'
                name='email'
                label={t('EMAIL')}
                variant='standard'
                color='primary'
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid container paddingY={4} spacing={3} marginRight={'10px'} display={'flex'}
                justifyContent={'space-evenly'}>
            <Grid item sm={12} xs={12} xl={6} md={6} lg={6}>
              <Button fullWidth variant='contained' className='back-button' onClick={() => navigate('/login')}>
                {t('BACK')}
              </Button>
            </Grid>
            <Grid item sm={12} xs={12} xl={6} md={6} lg={6}>
              <Button fullWidth type='submit' color={'primary'} variant='contained'>
                {t('RESET_PASSWORD')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
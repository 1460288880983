import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeRequestFilter } from '../../../models/changeRequests/ChangeRequestFilter';
import { ChangeRequestResponse } from '../../../models/changeRequests/ChangeRequestResponse';
import { TableState } from '../../../components/Table/interface/TableState';
import {ChangeRequestsService} from '../../../repository/ChangeRequestsService';
import ChangeRequestsTable from '../ChangeRequestTable/ChangeRequestsTable';
import { ChangeRequestConstants } from '../constants/ChangeRequestConstants';

const ChangeRequests: React.FC = () => {
  const [tableState, setTableState] = useState<TableState>({ page: 0, size: 5, totalElement: 0 });
  const [changeRequestsList, setChangeRequestsList] = useState<ChangeRequestResponse[]>([]);
  let [value, setValue] = useState(0);
  const { t } = useTranslation();
  const [changeRequestFilterData, setChangeRequestFilterData] = useState<ChangeRequestFilter>({
    createdBy: '',
    changeRequestType: '',
    status: 'PENDING'
  })

  useEffect(() => {
    listAllChangeRequestsPaginated();
  },[changeRequestFilterData, tableState.page, tableState.size]);

  const listAllChangeRequestsPaginated = () => {
    ChangeRequestsService.listChangeRequests(changeRequestFilterData, tableState.page,tableState.size)
      .then((data) => {
        setChangeRequestsList(data.content);
        setTableState({
          ...tableState,
          totalElement: data.totalElements
        });
      })
      .catch(() => {
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let status: any = null;
    switch (newValue) {
      case 0:
        status = 'PENDING';
        break;
      case 1:
        status = 'APPROVED';
        break;
      case 2:
        status = 'DECLINED';
        break;
      default:
        break;
    }
    setChangeRequestFilterData((prevFilterData) => ({
      ...prevFilterData,
      createdBy: '',
      changeRequestType: '',
      status: status,
    }));
  };

  const tabsData = [
    { id: 1, label: 'Pending' },
    { id: 2, label: 'Approved' },
    { id: 3, label: 'Declined' },
  ];

  return (
    <Box className={'term-content'}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable terms and conditions entities'
      >
        {tabsData.map(tab => {
          return <Tab key={tab.id} label={tab.label}/>;
        })}
      </Tabs>
      <Card variant='outlined'>
        <CardContent>
            <div className='user_header-wrapper'>
              {
                value === ChangeRequestConstants.PENDING && <h1 className='headerPage'>{t('PENDING_CHANGE_REQUESTS')}</h1>
              }
              {
                value === ChangeRequestConstants.APPROVED && <h1 className='headerPage'>{t('APPROVED_CHANGE_REQUESTS')}</h1>
              }
              {
                value === ChangeRequestConstants.DECLINED && <h1 className='headerPage'>{t('DECLINED_CHANGE_REQUESTS')}</h1>
              }
            </div>
          <Box className='tableContainer'>
            <ChangeRequestsTable
              tableState={tableState}
              setTableState={setTableState}
              changeRequestsList={changeRequestsList}/>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default ChangeRequests;
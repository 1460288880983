export enum Roles {
  ROLE_ADMIN = "ROLE_ADMIN",
  MLRO = "ROLE_MLRO",
  CHANGE_APPROVER = "CHANGE_APPROVER",
}

export const ChangeRequestStatus = {
  CHANGE_REQUEST_ACCEPTED_STATUS: "ACCEPTED",
  CHANGE_REQUEST_DECLINED_STATUS: "DECLINED",
  CHANGE_REQUEST_PENDING_STATUS: "PENDING",
};
export const QuestionType = {
  FREE_TEXT: "FREE_TEXT",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  SINGLE_CHOICE: "SINGLE_CHOICE",
};



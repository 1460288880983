import axios from '../axios/axios';
import { CountryDto } from '../models/admin/CountryDto';
import { CountryFilter } from '../models/admin/CountryFilter';
import { CountryRiskDTO } from '../models/admin/CountryRiskDTO';
import { Option } from '../models/shared/Option';
import { Page } from '../pagination/Page';
import { ClientDto } from '../models/clients/ClientDto';
import { ClientFilter } from '../models/clients/ClientFilter';

export class CountryService {
  static getAllCountries(search: string) {
    return axios.post('/country/list-all', {
      name: search
    });
  }

  static changeDisabledStatus(countryDto: CountryDto) {
    return axios.put(
      '/country/change-disabled-status/' + countryDto.countryId,
      countryDto
    );
  }

  static changeRiskScore(country: CountryRiskDTO) {
    return axios.post('/country/change-risk-score', country);
  }

  static getAllPublicCountries() {
    return axios.get('/country/public/list-all');
  }

  static async getAllPaged(
    page: number,
    size: number,
    name?: string
  ): Promise<Page<CountryDto>> {
    let filter: CountryFilter = { name: null };
    if (name) {
      filter.name = name;
    }

    const response = await axios.post<Page<CountryDto>>(
      `/country/public/all-paged`,
      filter,
      {
        params: { page, size }
      }
    );
    const data = response.data;
    return new Page<CountryDto>(
      data.content.map((country) => new CountryDto(country)),
      data.totalElements
    );
  }

  static async getById(id: number): Promise<CountryDto> {
    const response = await axios.get('/country/public/get/' + id);
    const countryDto = response.data;
    return new CountryDto(countryDto);
  }

  static async importFileForCountry(file: FormData): Promise<void> {
    await axios.post('/country/import-country-risk',file);
  }

  static async getAllCountriesRisks(): Promise<Option[]> {
    const response = await axios.get('/country/list-all-countries-risk');
    return response.data;
  }
}
export default CountryService;
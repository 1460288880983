import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '../../auth/Permission';
import ClientFilterData from '../../components/ClientFilter/ClientFilterData';
import '../../styles/procedure-status.scss';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import LightTooltip from '../../components/LightTooltip/LightTooltip';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import PageTable from '../../components/Table/PageTable';
import { BlockingActionRequest } from '../../models/clients/BlockingActionRequest';
import { BlockingActionResponse } from '../../models/clients/BlockingActionResponse';
import { ClientDto } from '../../models/clients/ClientDto';
import { ClientFilter } from '../../models/clients/ClientFilter';
import { ClientService } from '../../repository/ClientService';
import { AuthUtils } from '../../utils/AuthUtils';
import { DateTimeUtils } from '../../utils/DateTimeUtils';
import '../Clients/Clients.scss';
import { ClientProps } from './ClientProps';
import { ClientConstants } from './constants/ClientConstants';

const Clients = (props: ClientProps) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(200);
  const [clientsList, setClientsList] = useState<ClientDto[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [blockingActionResponse, setBlockingActionResponse] = useState<BlockingActionResponse[]>([]);
  const [currentClientId, setCurrentClientId] = useState<number>();
  const [currentClient, setCurrentClient] = useState<ClientDto>();
  const [reason, setReason] = useState<string>('');
  const { t } = useTranslation();

  let [value, setValue] = useState(0);
  const [clientFilterData, setClientFilterData] = useState<ClientFilter>({
    clientName: '',
    email: '',
    phoneNumber: '',
    status: 'PENDING',
    blocked: false,
    clientType: props.clientType,
    kycProcedureType: ''
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let status: any = null;
    let blocked = false;
    switch (newValue) {
      case 0:
        status = 'PENDING';
        blocked = false;
        break;
      case 1:
        status = 'ACCEPTED';
        blocked = false;
        break;
      case 2:
        status = 'DECLINED';
        blocked = false;
        break;
      case 3:
        blocked = true;
        status = null;
        break;
      case 4:
        status = 'AUTO_DECLINED';
        blocked = false;
        break;
      default:
        break;
    }
    setClientFilterData((prevFilterData) => ({
      ...prevFilterData,
      clientName: '',
      email: '',
      phoneNumber: '',
      status: status,
      blocked: blocked
    }));
  };
  useEffect(() => {
    listAllClients();
  }, [clientFilterData, page, rowsPerPage]);

  useEffect(() => {
    setClientFilterData(prevState => ({ ...prevState, clientType: props.clientType }));
  }, [props.clientType]);

  function getBlockingActionHistory(id: number) {
    ClientService.getBlockingActionHistory(id).then((res) => {
      setBlockingActionResponse(res);
    });
  }

  function listAllClients() {
    ClientService.listClients(clientFilterData, page, rowsPerPage)
      .then((data) => {
        setClientsList(data.content);
        setTotalElements(data.totalElements);
      })
      .catch(() => {
      });
  };

  const blockActionShowDialog = (id: string) => {
    setOpenDialog(true);
    getBlockingActionHistory(+id);
    setCurrentClientId(+id);
    setReason('');
    ClientService.getClientById(id).then((res) => {
      setCurrentClient(res);
    });
  };

  function cancelDelete() {
    setOpenDialog(false);
  }

  const blockClient = () => {
    const blockingActionRequest: BlockingActionRequest = {
      clientId: currentClientId,
      reason: reason
    };
    ClientService.blockClient(blockingActionRequest).then(() => {
      SwalAlert.successfulAlert(t('SUCCESS'), t('USER_SUCCESSFULLY_BLOCKED'));
      listAllClients();
    }).catch(() => {
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
    setOpenDialog(false);
  };
  const unblockClient = () => {
    const blockingActionRequest: BlockingActionRequest = {
      clientId: currentClientId,
      reason: reason
    };
    ClientService.unblockClient(blockingActionRequest).then(() => {
      SwalAlert.successfulAlert(t('SUCCESS'), t('USER_SUCCESSFULLY_UNBLOCKED'));
      listAllClients();
    }).catch(() => {
      SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
    });
    setOpenDialog(false);
  };
  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };
  const handleUserFilter = (
    clientName: string | undefined,
    clientLastName: string | undefined,
    email: string | undefined,
    phoneNumber: string | undefined,
    status: string | undefined,
    blocked: boolean | undefined,
    clientType: string,
    kycProcedureType: string | undefined
  ): void => {
    setClientFilterData({
      clientName,
      clientLastName,
      email,
      phoneNumber,
      status,
      blocked,
      clientType,
      kycProcedureType
    });
  };

  const getClassesForColorForProcedureStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'procedure-pending';
      case 'ACCEPTED':
        return 'procedure-accepted';
      case 'DECLINED':
        return 'procedure-rejected';
      case 'AUTO_DECLINED':
        return 'procedure-auto-declined';
      default:
        return '';
    }
  };

  const headerRows = [
    {
      id: 'email',
      label: t('EMAIL'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'firstName',
      label: t('FIRST_NAME'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'lastName',
      label: t('LAST_NAME'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'phoneNumber',
      label: t('PHONE_NUMBER'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'kycStatus',
      label: t('KYC_STATUS'),
      search: true,
      type: 'text',
      variant: 'standard'
    },
    {
      id: 'amlStatus',
      label: t('AML_STATUS'),
      search: true,
      type: 'text'
    },
    {
      id: 'clientType',
      label: t('KYC_PROCEDURE_TYPE'),
      search: true,
      type: 'text'
    },
    ...(AuthUtils.hasPermission(Permission.FE_CAN_BLOCK_UNBLOCK_CLIENTS) ? [{
      id: 'action',
      label: t('ACTION'),
      search: false,
      type: 'text'
    }] : [])
  ];

  const rows = clientsList.map((element) => {
    return {
      id: element.id,
      link: `/clients/${element.id}`,
      values: [
        {
          type: 'text',
          name: 'Email',
          value: (
            <LightTooltip title={element?.email}>
              <span>{element.email}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'First name',
          value: (
            <LightTooltip title={element?.firstName}>
              <span>{element.firstName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Last name',
          value: (
            <LightTooltip title={element?.lastName}>
              <span>{element.lastName}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Phone number',
          value: (
            <LightTooltip title={element?.phoneNumber}>
              <span>{element.phoneNumber}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'KYC Status',
          value: (
            <LightTooltip title={element?.KYCStatusLabel}>
              <span className={`${getClassesForColorForProcedureStatus(element?.KYCStatus!)}`}>{element.KYCStatusLabel}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'AML Status',
          value: (
            <LightTooltip title={element?.AMLStatusLabel}>
              <span className={`${getClassesForColorForProcedureStatus(element?.AMLStatus!)}`}>{element.AMLStatusLabel}</span>
            </LightTooltip>
          )
        },
        {
          type: 'text',
          name: 'Client type',
          value: (
            <LightTooltip title={element?.kycProcedureType}>
              <span>{element?.kycProcedureType}</span>
            </LightTooltip>
          )
        },
        ...(AuthUtils.hasPermission(Permission.FE_CAN_BLOCK_UNBLOCK_CLIENTS) ? [{
          type: 'button',
          name: 'Action',
          align: 'center',
          buttons: [
            {
              id: '1',
              value: value === ClientConstants.BLOCKED ? t('UNBLOCK') : t('BLOCK'),
              fun: blockActionShowDialog
            }
          ]
        }] : [])
      ]
    };
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  function handleOpen(id: number) {

  }

  const tabsData = [
    { id: 1, label: 'Pending' },
    { id: 2, label: 'Approved' },
    { id: 3, label: 'Rejected' },
    { id: 4, label: 'Blocked' },
    { id: 5, label: 'Auto Declined' }
  ];

  function previewBlockingActionHistory() {
    return <>
      {blockingActionResponse.length === 0 && <p className='blocking-action-history'> {t('USER_NEVER_BLOCKED')} </p>}
      {blockingActionResponse.length !== 0 && value === ClientConstants.BLOCKED && <p className='blocked-user'> {t('BLOCKED_CLIENT')} </p>}
      {blockingActionResponse.length !== 0 && <p className='blocking-action-history'> {t('PREVIOUS_BLOCKING_ACTIONS')}: </p>}
      {blockingActionResponse.map((elem, index: any) => {
        return (
          <p className='blocking-action-history' key={index}>{DateTimeUtils.formatDate(
            elem.dateCreated.toString()) + ' (' + elem.action + ')' + ': ' + elem.reason}</p>
        );
      })}
      {value === ClientConstants.BLOCKED ? <p>{t('SURE_TO_UNBLOCK')} <b>{currentClient?.email}</b> ?</p> :
        <p>{t('SURE_TO_BLOCK')} <b>{currentClient?.email}</b> ?</p>}
    </>;
  }

  return (
    <>

      <Box className={'terms-content'}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable terms and conditions entities'
        >
          {tabsData.map(tab => {
            return <Tab key={tab.id} label={tab.label}/>;
          })}
        </Tabs>
        <Card variant='outlined'>
          <CardContent>
            <div>
              <div className='user_header-wrapper'>
                {
                  value === ClientConstants.PENDING && <h1 className='headerPage'>{t('PENDING_CLIENTS')}</h1>
                }
                {
                  value === ClientConstants.APPROVED && <h1 className='headerPage'>{t('APPROVED_CLIENTS')}</h1>
                }
                {
                  value === ClientConstants.REJECTED && <h1 className='headerPage'>{t('REJECTED_CLIENTS')}</h1>
                }
                {
                  value === ClientConstants.BLOCKED && <h1 className='headerPage'>{t('BLOCKED_CLIENTS')}</h1>
                }
                {
                  value === ClientConstants.AUTO_DECLINED && <h1 className='headerPage'>{t('AUTO_DECLINED_CLIENTS')}</h1>
                }
              </div>
              <ClientFilterData onFilterSubmit={handleUserFilter} status={clientFilterData.status} blocked={clientFilterData.blocked}
                                clientType={props.clientType}/>
              <div className='tableContainer'>
                <PageTable
                  headerRows={headerRows}
                  rows={rows}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalElements={totalElements}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleOpen={handleOpen}
                  clickable={true}
                />
              </div>
              <ConfirmationDialog open={openDialog} title={value === ClientConstants.BLOCKED ? t('UNBLOCK_CLIENT') : t('BLOCK_CLIENT')}
                                  handleClose={() => cancelDelete()}
                                  handleConfirm={() => value === ClientConstants.BLOCKED ? unblockClient() : blockClient()}
                                  content={previewBlockingActionHistory()}
                                  actionButtonName={'Confirm'} hasInputField={true} inputFieldName='Reason' handleChange={handleTextFieldChange}
                                  disableConfirmButton={reason === ''}></ConfirmationDialog>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default Clients;

import { StyleSheet } from 'aphrodite/no-important';

const loaderKeyframes = {
	'0%': {
		transform: 'rotate(0deg)'
	},
	'100%': {
		transform: 'rotate(360deg)'
	}
};

export default StyleSheet.create({
	page: {
		color: '#464646',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		padding: 10,
		'@media (max-width: 600px)': {
			fontSize: '12px',
			lineHeight: '24px',
		},
		'@media (max-width: 1025px)': {
			minHeight:"80vh"
		},
		'@media (max-width: 400px)': {
			fontSize: '10px'
		}
	},
	heading: {
		display: 'flex',
		fontSize: '2em',
		padding: '15px',
		justifyContent: 'center',
		textAlign: 'center',
		'@media (max-width: 900px)': {
			fontSize:"15px"
		}
	},
	title: {
		display: 'flex',
		fontSize: '1.5em',
		width: '100%',
		padding: '15px',
		justifyContent: 'center',
		textAlign: 'center'
	},
	reactLogo: {
		height: '32px',
		padding: '0 15px',
		display:"flex",
		alignSelf:"center",
		'@media (max-width: 600px)': {
			height: '24px'
		}
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		width: '100%',
		textAlign: 'center',
		overflow: 'hidden'
	},
	loaderContainer: {
		position: 'fixed',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: 99,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		pointerEvents: 'none'
	},
	loader: {
		border: '16px solid #f3f3f3',
		borderTop: '16px solid #3498db',
		borderRadius: '50%',
		width: '120px',
		height: '120px',
		animationName: [loaderKeyframes],
		animationDuration: '2s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear'
	},
	btn: {
		color: '#000',
		fontSize: '1.1em',
		minWidth: '150px',
		cursor: 'pointer',
		background: 'none',
		border: '2px solid',
		lineHeight: 1,
		margin: '0.5em',
		padding: '1em 2em',
		transition: '0.25s',
		':disabled': {
			backgroundColor: '#DDDADA',
			borderColor: '#fff',
			color: '#fff'
		},
		'@media (max-width: 600px)': {
			width: '80%'
		}
	},
	btnPrimaryAction: {
		color: '#0BAD36',
		borderColor: '#0BAD36',
		':hover:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #0BAD36, inset 6em 3.5em 0 0 #0BAD36'
		},
		':focus:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #0BAD36, inset 6em 3.5em 0 0 #0BAD36'
		}
	},
	btnSecondaryAction: {
		color: '#0D768F',
		borderColor: '#0D768F',
		':hover:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #0D768F, inset 6em 3.5em 0 0 #0D768F'
		},
		':focus:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #0D768F, inset 6em 3.5em 0 0 #0D768F'
		}
	},
	btnTertiaryAction: {
		color: '#E77A0F',
		borderColor: '#E77A0F',
		':hover:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #E77A0F, inset 6em 3.5em 0 0 #E77A0F'
		},
		':focus:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #E77A0F, inset 6em 3.5em 0 0 #E77A0F'
		}
	},
	btnCancel: {
		color: '#E7280F',
		borderColor: '#E7280F',
		':hover:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #E7280F, inset 6em 3.5em 0 0 #E7280F'
		},
		':focus:enabled': {
			color: '#FFF',
			boxShadow: '0 0 0 0 #E7280F, inset 6em 3.5em 0 0 #E7280F'
		}
	},
	imageView: {
		margin: '4px auto',
		width: '100%',
		maxWidth: '640px'
	},
	cameraViewport: {
		width: '100%',
		maxWidth: '640px',
		borderRadius: 8
	},
	hideCanvas: {
		display: 'none'
	},
	cameraSelect: {
		width: '75%',
		maxWidth: '350px',
		padding: '4px',
		margin: '4px',
		borderRadius: '4px',
		backgroundColor: '#DDD'
	}
});

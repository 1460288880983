import {parse, format} from "date-fns";
import dayjs, { Dayjs } from "dayjs";

export class DateTimeUtils {
    static parseTimeHHmm(value: string): Date {
        return parse(value, 'HH:mm', new Date());
    }

    static parseTimeWithOffset(value: string): Date {
        return parse(value, 'HH:mmxxxxx', new Date());
    }

    static formatTimeWithOffset(value: Date): string {
        return format(value, 'HH:mmxxxxx');
    }


    static formatDate(dateString: string, defaultValue = ''){
        if(!dateString) {
            return defaultValue;
        }
        return format(new Date(dateString), 'dd.MM.yyyy');
    }

    static formatDateTime(dateString: string, defaultValue = ''){
        if(!dateString) {
            return defaultValue;
        }
        return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
    }

    static formatDateObject(date: Date): string {
        return format(date, "dd.MM.yyyy HH:mm");
    }

    static formatTime(date: string): string {
        return DateTimeUtils.formatTimeObject(DateTimeUtils.parseTimeWithOffset(date));
    }

    static formatTimeObject(time: Date): string {
        return format(time, "HH:mm");
    }

    static formatDateDayJs(date: Dayjs | null | undefined): string | null {
        if (!date) {
            return null;
        }
        return dayjs(date.toDate()).format("YYYY-MM-DD")
    }

    static formatDateDayJsWithSeconds(date: Dayjs | null): string | null {
        if (!date) {
            return null;
        }
        return dayjs(date.toDate()).format("YYYY-MM-DD-ss")
    }

    static convertSectoDay(n: number | undefined){
        if(n!== undefined){
            const day = Math.trunc(n / (24 * 3600));

            n = n % (24 * 3600);
            const hour = Math.trunc(n / 3600);

            n %= 3600;
            const minutes = Math.trunc(n / 60);

            n %= 60;
            const seconds = n;

            return `${day} Days ${hour} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
    }
    static convertSecToHours(n: number | undefined){
        if(n!== undefined){
            n = n % (24 * 3600);
            const hour = Math.trunc(n / 3600);

            n %= 3600;
            const minutes = Math.trunc(n / 60);

            n %= 60;
            const seconds = n;

            return `${hour} h ${minutes} m ${seconds} s`;
        }
    }
}

import { AutocompleteValue, Button, FormControl, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { PublicCountryResponseDto } from '../../models/country/PublicCountryResponseDto';
import CountryService from '../../repository/CountryService';
import GBGTokenService from '../../repository/GBGTokenService';
import FileUploadInput from '../FormFIelds/FIleUploadInput';
import './ProofOfAddress.scss';
import { ProofOfAddressProps } from './ProofOfAddressProps';
import { useTranslation } from 'react-i18next';

export function ProofOfAddress({
  fullName,
  email,
  gbgBirthDate,
  token,
  onSave,
  phoneNumber
}: ProofOfAddressProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [countries, setCountries] = useState<PublicCountryResponseDto[]>([]);
  const [country, setCountry] = useState<number | null>(null);
  const [countryOfResidence, setCountryOfResidence] = useState<number | null>(null);
  const [citizenship, setCitizenship] = useState<number | null>(null);
  const [addressLine1, setAddressLine1] = useState<string | null>('');
  const [addressLine2, setAddressLine2] = useState<string | null>('');
  const [birthDate, setBirthDate] = useState<Dayjs>(dayjs(gbgBirthDate !== null ? gbgBirthDate.toString() : null));
  const [city, setCity] = useState<String>('');
  const [zipCode, setZipCode] = useState<String>('');

  const { t } = useTranslation();

  const disableButton: boolean = selectedFiles.length === 0 || countries.length === 0 || country === null || addressLine1 === null || addressLine2 === null || addressLine1 === '' || addressLine2 === '' || citizenship === null || countryOfResidence === null || birthDate === undefined || city === '' || zipCode === '';

  const getAllPublicCountries = () => {
    CountryService.getAllPublicCountries().then((response) => {
      setCountries(response.data);
    });
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChangeCountry = (option: AutocompleteValue<any, any, any, any>) => {
    if (option) {
      setCountry(option?.id);
    }
  };

  const handleChangeCountryOfResidence = (option: AutocompleteValue<any, any, any, any>) => {
    if (option) {
      setCountryOfResidence(option?.id);
    }
  };

  const handleChangeCitizenship = (option: AutocompleteValue<any, any, any, any>) => {
    if (option) {
      setCitizenship(option?.id);
    }
  };

  const handleFileChange = (files: File[], fileIndex: number) => {
    setSelectedFiles(files);
  };

  useEffect(() => {
    getAllPublicCountries();
  }, []);

  const saveProofOfAddress = () => {
    const data = {
      countryId: country,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      files: []
    };
    const filePromises = selectedFiles.map((file) => getFileDto(file));
    Promise.all(filePromises).then((fileDtos) => {
      const data = {
        countryId: country,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        files: fileDtos,
        citizenshipId: citizenship,
        countryOfResidenceId: countryOfResidence,
        birthDate: birthDate!.format('YYYY-MM-DD'),
        city: city,
        zipCode: zipCode
      };
      GBGTokenService.updateProofOfAddress(data, token!).then((response) =>
        onSave()
      );
    });
  };

  const getFileDto = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          name: file.name,
          mimeType: file.type,
          base64Content: reader.result
        });
    });
  };

  return (
    <Grid container spacing={2} padding={4}>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <TextField
            label={t('FULL_NAME_IDENTITY_DOCUMENT')}
            fullWidth={true}
            InputProps={{ readOnly: true }}
            value={fullName}
            className='read-only-field'/>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <TextField
            label={t('EMAIL')}
            fullWidth={true}
            InputProps={{ readOnly: true }}
            value={email}
            className='read-only-field'/>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <TextField
            label={t('MOBILE_NUMBER')}
            fullWidth={true}
            InputProps={{ readOnly: true }}
            className='read-only-field'
            value={phoneNumber}
          />
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className='date-picker'
            label={t('BIRTH_DATE')}
            value={birthDate}
            format='MMM D, YYYY'
            onChange={(newValue) => setBirthDate(newValue!)}

          />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <FormControl fullWidth className='proof-of-address-field'>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disablePortal
              id='country'
              onChange={(event, value) => handleChangeCountry(value)}
              options={countries.map((country) => {
                return {
                  label: country.name, id: country.id
                };
              })}
              renderInput={(params) => <TextField {...params} label={t('COUNTRY_OF_BIRTH')}/>}/>
          </FormControl>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <FormControl fullWidth className='proof-of-address-field'>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disablePortal
              id='citizenship'
              onChange={(event, value) => handleChangeCitizenship(value)}
              options={countries.map((country) => {
                return {
                  label: country.name, id: country.id
                };
              })}
              renderInput={(params) => <TextField {...params} label={t('CITIZENSHIP')}/>}/>
          </FormControl>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <TextField
            label={t('CITY')}
            fullWidth={true}
            value={city}
            onChange={(event) => setCity(event.target.value)}/>
        </div>
      </Grid>
      <Grid item lg={6} xs={12} sm={12}>
        <div>
          <TextField
            label={t('ZIP_CODE')}
            fullWidth={true}
            value={zipCode}
            onChange={(event) => setZipCode(event.target.value)}/>
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div>
          <TextField
            label={t('ADDRESS_LINE_ONE')}
            fullWidth={true}
            className='proof-of-address-field'
            value={addressLine1}
            onChange={(event) => setAddressLine1(event.target.value)}/>
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12} sm={12}>
            <div>
              <TextField
                label={t('ADDRESS_LINE_TWO')}
                fullWidth={true}
                className='proof-of-address-field'
                value={addressLine2}
                onChange={(event) => setAddressLine2(event.target.value)}/>
            </div>
          </Grid>
          <Grid item lg={4} xs={12} sm={12}>
            <div>
              <FormControl fullWidth className='proof-of-address-field'>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disablePortal
                  id='countryOfResidence'
                  onChange={(event, value) => handleChangeCountryOfResidence(value)}
                  options={countries.map((country) => {
                    return {
                      label: country.name, id: country.id
                    };
                  })}
                  renderInput={(params) => <TextField {...params} label={t('COUNTRY_OF_RESIDENCE')}/>}/>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} sm={12}>
            <FileUploadInput
              label={t('ADDRESS_PROOF_FILES')}
              selectedFiles={selectedFiles}
              onFileChange={handleFileChange}
              index={0}
              name={'proof-of-addres'}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: '10px', mt: '10px' }}
        display='flex'
        justifyContent='center'>
        <Button
          disabled={disableButton}
          variant='contained'
          onClick={saveProofOfAddress}
          className={'proof-of-address-nex-button'}>
          {t('NEXT')}
        </Button>
      </Grid>
    </Grid>
  );
}
import React, {useEffect, useState} from "react";
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddButton from "../../../components/AddButton/AddButton";
import { addQuestion } from '../../../redux/questions/questionsSlice';
import QuestionsService from "../../../repository/QuestionsService";
import ClientQuestionnairesDTO from "../../../models/clientQuestionnaires/ClientQuestionnairesDTO";
import QuestionsType from "../../../models/clientQuestionnaires/QuestionsType";
import Swal from "sweetalert2";
import AMLQuestionGroup from "../../../models/clientQuestionnaires/AMLQuestionGroup";
import AMLQuestion from "./AMLQuestion";
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from "react-router-dom";
import { AmlQuestionnairesProps } from './AmlQuestionnairesProps';
import { useTranslation } from 'react-i18next';

const AMLQuestionnaires = (props: AmlQuestionnairesProps) => {
  const [allQuestionsList, setAllQuestionsList] = useState<ClientQuestionnairesDTO[]>([]);
  const [questionTypesList, setQuestionTypeList] = useState<QuestionsType[]>([]);
  const [activeQuestionGroup, setActiveQuestionGroup] = useState<AMLQuestionGroup>();
  const [draftId, setDraftId] = useState<number | string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setDraftId("");
    getActiveAMLQuestionsByGroup();
    getAllQuestionType();
    getDraftId();
  }, [props.tabKey]);

  useEffect(() => {
    getActiveAMLQuestionsByGroup();
  }, [props.tabKey]);

  useEffect(() => {
    getAllQuestionsByGroup();
  }, [activeQuestionGroup]);

  const getDraftId = () => {
    const groupType = props.tabKey ? "LEGAL_ENTITY" : "INDIVIDUAL";
    QuestionsService.getDraftId(groupType)
      .then(response => {
        setDraftId(response.data);
      })
      .catch(error => {
        console.error("Error fetching draft ID:", error);
      });
  };

  const getActiveAMLQuestionsByGroup = () => {
    if (props.tabKey) {
      QuestionsService.getActiveAMLQuestionsByGroup("LEGAL_ENTITY").then(
        (data) => {
          setActiveQuestionGroup(data.data);
        }
      ).catch(()=>{});
    } else {
      QuestionsService.getActiveAMLQuestionsByGroup("INDIVIDUAL").then((data) => {
        setActiveQuestionGroup(data.data);
      }).catch(()=>{});
    }
  };

  const getAllQuestionsByGroup = () => {
    if (activeQuestionGroup != null) {
      QuestionsService.getAllAMLQuestionsByGrope(activeQuestionGroup.id).then(
        async (data) => {
          setAllQuestionsList(data.data);
          await dispatch(addQuestion(data.data));
        }
      ).catch(()=>{});
    }
  };

  const getAllQuestionType = () => {
    QuestionsService.getAllQuestionsType().then((data) => {
      setQuestionTypeList(data.data);
    }).catch(()=>{});
  };

  const handleCreateDraft = () => {
    Swal.fire({
      title: t('CREATE_DRAFT_CONFIRMATION'),
      showCancelButton: true,
      confirmButtonText: t('YES'),
    }).then((result) => {
      if (result.isConfirmed) {
        QuestionsService.createDraftQuestionnaire(activeQuestionGroup?.id!).then((data) => {
          Swal.fire({
            icon: 'success',
            title: t('SUCCESS'),
            text: t('DRAFT_SUCCESSFULLY_CREATED'),
          });
          navigate(`/client-questionnaires/${data.data}`,{state: {key: props.tabKey}});
        }).catch((error) => {
          if (error.response) {
            Swal.fire({
              icon: 'error',
              title: t('ERROR'),
              text: t('ONE_DRAFT_PER_QUESTION_GROUP'),
            });
          }
        });
      }
    });
  };

  const handleContinueDraft = () => {
    navigate(`/client-questionnaires/${draftId}`, {state: {key: props.tabKey}});
  };

  return (
    <>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography
          variant="h5"
          sx={{textAlign: "center", marginRight: "auto",marginBottom:'30px'}}
        >
          {" "}
          <b style={{fontWeight: 600}}>{t('LIST_OF_AML_QUESTIONS')}</b>{" "}
        </Typography>
        {draftId !== "" ? (
          <AddButton
            onClickHandle={handleContinueDraft}
            text={t('EDIT_DRAFT')}
            icon={<EditIcon sx={{ fontSize: '18px' }} className="buttonIcon" />}
          />
        ) : (
          <AddButton
            onClickHandle={handleCreateDraft}
            text={t('CREATE_DRAFT')}
            icon={<EditIcon sx={{ fontSize: '18px' }} className="buttonIcon" />}
          />
        )}
      </Box>
      <Box
        sx={{
          marginLeft: "auto",
        }}
      >
        <Box sx={{display: "flex", width: "100%"}}>
          <Grid container spacing={0} width={"100%"}>
            <Grid item lg={12} md={12} sm={12} xs={12} width={'400px'}>
              {allQuestionsList.length >0 ? (
                allQuestionsList.map((question) => {
                  const labelId = `checkbox-list-label-${question.amlQuestionDTO.id}`;
                  return(
                    <AMLQuestion questionTypesList={questionTypesList}
                                 getAllQuestionsByGrope={getAllQuestionsByGroup}
                                 isCopy={false}
                                 canEdit={false}
                                 question={question}
                                 key={question.amlQuestionDTO.id}/>
                  )
                })
              ):(
                <Box  display={'flex'} justifyContent={'center'} color={'grey'}> <i>{t('EMPTY_QUESTION_LIST')} </i></Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default AMLQuestionnaires;

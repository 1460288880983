import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileName } from '../enums/FileName';
import { TermsAndConditionsProps } from './TermsAndConditionsProps';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = (props: TermsAndConditionsProps) => {

  const [numberOfSelectedTermsAndConditions, setNumberOfSelectedTermsAndConditions] = useState(0);
  const { t } = useTranslation();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setNumberOfSelectedTermsAndConditions(numberOfSelectedTermsAndConditions + 1);
    } else {
      setNumberOfSelectedTermsAndConditions(numberOfSelectedTermsAndConditions - 1);
    }
  };

  return (
    <>
      <Grid item>
        <Typography className='card-title'>
          <b>{t('TERMS_AND_CONDITIONS')}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container item>
          <Grid
            item
            sm={12}
            xl={12}
            md={12}
            lg={12}
            className={'privacy-wrapper'}
            marginY={1}
            alignItems={'center'}
            display={'flex'}
            paddingX={1.7}>
            <Box>
              <Checkbox
                required
                onChange={handleCheckboxChange}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 }
                }}/>
            </Box>
            <Box>
              {t('CORRECT_PERSONAL_INFO')}
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            xl={12}
            md={12}
            lg={12}
            display={'flex'}
            marginY={1}
            alignItems={'center'}
            className={'privacy-wrapper'}
            paddingX={1.7}>
            <Box>
              <Checkbox
                required
                onChange={handleCheckboxChange}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 }
                }}/>
            </Box>
            <Box>
              {t('S2T_ABLE_TO_CONTACT')}
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            xl={12}
            md={12}
            lg={12}
            display={'flex'}
            marginY={1}
            alignItems={'center'}
            className={'privacy-wrapper'}
            paddingX={1.7}>
            <Box>
              <Checkbox
                required
                onChange={handleCheckboxChange}
                sx={{
                  height: '100%',
                  '& .MuiSvgIcon-root': { fontSize: 28 }
                }}/>
            </Box>
            <Box>
              {t('INFO_SEND_TO_THIRD_PART')} &nbsp;
              <Link
                to={`/view-pdf/${FileName.PRIVACY_POLICY}/view`}
                className={'link link-element privacy-link'} target={'_blank'}>
                Safe2Transact Privacy Policy</Link>,<Link to={`/view-pdf/${FileName.COOKIES_POLICY}/view`}
                                                          className={'link link-element privacy-link'}
                                                          target={'_blank'}> Cookie
              policy</Link> and <Link to={`/view-pdf/${FileName.TOKEN_PRE_SALE}/view`}
                                      className={'link link-element privacy-link'}
                                      target={'_blank'}>Token Sale
              policy.</Link>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            xl={12}
            md={12}
            lg={12}
            className={'privacy-wrapper'}
            display={'flex'}
            marginY={1}
            alignItems={'center'}
            paddingX={1.7}>
            <Box>
              <Checkbox
                required
                onChange={handleCheckboxChange}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 }
                }}/>
            </Box>
            <Box>
              {t('PEP_CONFIRM')} ( <Link
              to={`/view-pdf/${FileName.POLITICAL_EXPOSED_PERSON_INFO}/view`}
              className={'link link-element privacy-link'}
              target={'_blank'}>Political
              Exposed Persons</Link> ).
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: '10px' }}
        display='flex'
        justifyContent='center'>
        <Button
          disabled={numberOfSelectedTermsAndConditions !== 4}
          variant='contained'
          onClick={props.submitVerification}
          className='basic-button'>
          {t('SUBMIT_VERIFICATION')}
        </Button>
      </Grid>
    </>
  );
};
export default TermsAndConditions;
import { ConfirmProvider } from 'material-ui-confirm';
import React, { useEffect } from 'react';
import './App.scss';
import { themeOptions } from './config/KycTheme';
import store from './redux/store';
import LoginService from './repository/LoginService';
import RoutesComponents from './routes/RoutesComponents';
import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import './i18n';
import { PERMISSIONS } from './shared/utility';

const theme = createTheme(themeOptions);

function App() {
  
  return (
    <ConfirmProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RoutesComponents/>
        </Provider>
      </ThemeProvider>
    </ConfirmProvider>
  );
}

export default App;

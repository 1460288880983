import axios from '../axios/axios';
import { RepresentativeAnswersResponse } from '../models/legal_entity/RepresentativeAnswersResponse';

const RepresentativeAnswersService = {
  async getByKycProcedureToken(token: string): Promise<RepresentativeAnswersResponse> {
    const response = await axios.get('/representative-answers/public/by-kyc-procedure-token', { params: { token } });
    return response.data;
  }
};

export default RepresentativeAnswersService;
import { ThemeOptions } from "@mui/material/styles/createTheme";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#0e387a",
      light: "#585bfc",
      dark: "#000280",
    },
    secondary: {
      main: "#f5f5f5",
      light: "#f2f2f2",
      dark: "#e8e8e8",
    },
    text: {
      primary: "#010101",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
    fontWeightMedium: 400,
    h6: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "3rem",
    },
    h1: {
      fontSize: "4rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    caption: {
      fontSize: "0.75rem",
      color: "#A4ABB3",
    },
    button: {
      textTransform: "none",
    },
  },
};

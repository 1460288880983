import axios from '../axios/axios';

const SystemApproversService= {
    setMinimumSystemApprovers:(minimumApproversNeeded:number | string)=>{
        return axios.post('/config/set-minimum-approvers',{},{headers:{minimumApproversNeeded}})
    },
    getMinimumSystemApprovers:()=>{
        return axios.get('/config/get-minimum-approvers',)
    }

}
export default SystemApproversService;

import { Box, Typography, Card, CardContent } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { MonthlyOnboardStats } from '../../models/chart/MonthlyOnboardStats';
import { ClientService } from '../../repository/ClientService';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const MonthlyOnboardChart = () => {

  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [month, setMonth] = useState<any>(dayjs(new Date()));
  const { t } = useTranslation();

  const [monthlyOnboardStatsList, setMonthlyOnboardStatsList] = useState<MonthlyOnboardStats>();

  useEffect(() => {
    monthlyOnboardStats();
  }, [dateFrom, dateTo, month]);

  const monthlyOnboardStats = async () => {
    const formattedDateFrom = dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : null;
    const formattedDateTo = dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : null;
    const formattedMonth = month ? dayjs(month).format('YYYY-MM-DD') : null;
    ClientService.monthlyOnboardStats(formattedMonth, formattedDateFrom, formattedDateTo).then((data) => {
      const transformedData: MonthlyOnboardStats = {
        dailyOnboardStats: Object.entries(data.dailyOnboardStats).map(([date, value]) => ({
          date: dayjs(date).format('YYYY-MM-DD'),
          value: value as number
        })),
        totalClients: data.totalClients
      };
      setMonthlyOnboardStatsList(transformedData);
    });
  };

  const handleDateFrom = (newDate: Date | null) => {
    if (newDate != null) setDateFrom(newDate);
  };

  const handleDateTo = (newDate: Date | null) => {
    if (newDate != null) setDateTo(newDate);
  };

  const handleMonth = (newDate: Date | null) => {
    if (newDate != null) setMonth(newDate);
  };

  const chartTitleStyles: SxProps = {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 3

  };

  const chartBoxStyles: SxProps = {
    maxWidth: { xs: '350px', sm: 'none' }
  };

  const totalClientsStyles: SxProps = {
    variant: 'h5',
    fontWeight: 'bold',
    textAlign: 'center',
    mb: 3,
    mt: 1
  };

  const datePickerBoxStyles: SxProps = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' },
    marginBottom: 7
  };

  const datePickerStyles: SxProps = {
    width: { xs: '250px', md: '250px', xl: '270px' }
  };

  const cardStyles: SxProps = {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.1)',
    paddingBottom: 2
  };

  return (
    <>
      <Box>
      <Card sx={cardStyles}>
        <CardContent>
          <Box>
            <Typography variant={'h5'} sx={chartTitleStyles}>{t('MONTHLY_ONBOARD_STATISTICS')}</Typography>
            <Box sx={chartBoxStyles}>
              <Chart
                options={{
                  chart: { height: 350, type: 'line', zoom: { enabled: false } },
                  dataLabels: { enabled: false },
                  stroke: { curve: 'straight' },
                  grid: { row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 } },
                  xaxis: { categories: monthlyOnboardStatsList?.dailyOnboardStats.map((stats) => stats.date)! }
                }}
                series={[{ name: 'Clients', data: monthlyOnboardStatsList?.dailyOnboardStats.map((stats) => stats.value)! }]}
                type='line'
                height={350}
              />
            </Box>
            <Typography sx={totalClientsStyles}>Total clients for the chosen dates: {monthlyOnboardStatsList?.totalClients}</Typography>
            <Box sx={datePickerBoxStyles}>
              <Box sx={datePickerStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label='Date from' onChange={handleDateFrom}/>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box sx={datePickerStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label='Date to' onChange={handleDateTo}/>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box sx={datePickerStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label={'month'} views={['month']} value={month} onChange={handleMonth}/>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      </Box>
    </>
  );
};
export default MonthlyOnboardChart;
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import AddButton from "../../components/AddButton/AddButton";
import AutocompleteNotMultipleSelectChip from "../../components/AutocompleteNotMultipleSelectChip/AutocompleteNotMultipleSelectChip";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import SwalAlert from "../../components/SwalAlert/SwalAlert";
import { Roles } from "../../models/constants";
import { UserDto } from "../../models/userroles/users/UserDto";
import { UserRequestDto } from "../../models/userroles/users/UserRequestDto";
import SystemApproversService from "../../repository/SystemApproversServis";
import UserService from "../../repository/UserService";
import { BootstrapDialog } from "../../shared/components/BootstrapDialog/BootstrapDialog";
import ActionButton from "./../../components/ActionButton/ActionButton";
import "./SystemApprovers.scss";
import { useTranslation } from 'react-i18next';

const SystemApprovers: React.FC = () => {
  const [systemApprovers, setSystemApprovers] = useState<UserDto[]>([]);
  const [allUsers, setAllUsers] = useState<UserDto[]>([]);
  const [removeApprover, setRemoveApprover] = useState<boolean>(false);
  const [approverId, setApproverId] = useState<number>(0);
  const [addNewSystemApprover, setAddNewSystemApprover] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    undefined
  );
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);
  const [addDeleteApprover, setAddDeleteApprover] = useState<boolean>(false);
  const [minimumApprovers, setMinimumApprovers] = useState<number | string>();
  const { t } = useTranslation();

  async function getAllSystemApprovers(all?: boolean): Promise<void> {
    if (all) {
      let userRequestDto: UserRequestDto;
      userRequestDto = {
        role: "",
      };
      await UserService.getAllUsersByRole(userRequestDto).then((response) => {
        setAllUsers(response.data);
      });
    } else {
      let userRequestDto: UserRequestDto;
      userRequestDto = {
        role: Roles.CHANGE_APPROVER,
      };
      await UserService.getAllUsersByRole(userRequestDto).then((response) => {
        setSystemApprovers(response.data);
      });
    }
  }

  const getSystemMinimumApprovers = () => {
    SystemApproversService.getMinimumSystemApprovers()
      .then((data) => {
        setMinimumApprovers(data.data.value);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAllSystemApprovers().then((_) => {});
    getSystemMinimumApprovers();
  }, []);

  function removeSystemApprover(id: number) {
    setRemoveApprover(true);
    setApproverId(id);
  }

  function cancelDelete() {
    setRemoveApprover(false);
    setApproverId(0);
  }

  function openModalForSystemApprovers() {
    setAddNewSystemApprover(true);
    getAllSystemApprovers(true);
  }

  function closeModalForSystemApprovers() {
    setAddNewSystemApprover(false);
    setSelectedUser(undefined);
    setAddButtonClicked(false);
  }

  const handleUserChange = (selectedOptions: number | undefined) => {
    setSelectedUser(selectedOptions);
  };

  const handleSetMinApprovals = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinimumApprovers(e.target.value);
  };

  async function addSystemApprover() {
    if (selectedUser) {
      setAddNewSystemApprover(false);
      try {
        await UserService.setRoleApprover(selectedUser);
        SwalAlert.successfulAlert(
          t('SUCCESS'),
          t('SUCCESSFULLY_ADDED_SYSTEM_APPROVER')
        );
        getAllSystemApprovers();
        setAddButtonClicked(false);
      } catch (error) {
        SwalAlert.errorAlert(
          t('ERROR'),
          t('SOMETHING_WENT_WRONG')
        );
      }
    }
    setSelectedUser(undefined);
  }

  async function removeSystemApproverFromList() {
    if (approverId) {
      setRemoveApprover(false);
      try {
        await UserService.setRoleApprover(approverId);
        SwalAlert.successfulAlert(
          t('SUCCESS'),
          t('SUCCESSFULLY_REMOVED_SYSTEM_APPROVER')
        );
        getAllSystemApprovers();
      } catch (error) {
        SwalAlert.errorAlert(
          t('ERROR'),
          t('SOMETHING_WENT_WRONG')
        );
      }
    }
    setApproverId(0);
  }

  const onSubmitChangeMinimumApprovers = () => {
    SystemApproversService.setMinimumSystemApprovers(minimumApprovers!)
      .then(() => {
        SwalAlert.successfulAlert(t('SUCCESS'), t('SUCCESSFUL_ACTION'));
      })
      .catch(() => {
        SwalAlert.errorAlert(t('OPS_MESSAGE'), t('SOMETHING_WENT_WRONG'));
      });
  };

  const isAllUsersApprovers = allUsers.every((user) =>
    systemApprovers.some((approver) => approver.id === user.id)
  );

  return (
    <>
      <div>
        <div className="approvers_header-wrapper">
          <Grid container>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className="headerPage">{t('CHANGE_APPROVAL_SETTINGS')}</h1>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    className={"custom-border-shadow-card"}
                    sx={{
                      padding: "10px 40px 10px 50px",
                      marginBottom: "30px",
                    }}
                  >
                    <h3 className="headerPage">{t('MINIMUM_NUMBER_OF_APPROVES')}:</h3>
                    <TextField
                      id="minimum-approvers"
                      type="number"
                      color={"primary"}
                      focused
                      size={"medium"}
                      label={t('NUMBER_OF_APPROVES')}
                      value={minimumApprovers}
                      onChange={handleSetMinApprovals}
                      sx={{ width: 500, marginBottom: "20px" }}
                    />
                    <Box
                      marginBottom={"10px"}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <AddButton
                        text={t('SAVE_CHANGE')}
                        onClickHandle={onSubmitChangeMinimumApprovers}
                      />
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  paddingBottom={"20px"}
                >
                  <Card
                    sx={{ padding: "10px 40px 10px 50px" }}
                    className={"custom-border-shadow-card"}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h2 className="headerPage">
                        {t('USERS_WITH_RIGHT_TO_APPROVE_SYSTEM_CHANGES')}:
                      </h2>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Chip
                        className="chip"
                        avatar={<Avatar className="chip-avatar" />}
                        label={
                          <div>
                            <div>
                              <strong>{t('ADD_NEW_USER')}...</strong>
                            </div>
                          </div>
                        }
                        deleteIcon={
                          <ActionButton
                            type={"add"}
                            action={() => openModalForSystemApprovers()}
                          />
                        }
                        onDelete={() => setAddDeleteApprover(true)}
                      />
                    </Grid>
                    {systemApprovers &&
                      systemApprovers.length > 0 &&
                      systemApprovers.map((approver, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            key={index}
                            marginY={"10px"}
                          >
                            <Chip
                              className="chip"
                              avatar={<Avatar className="chip-avatar" />}
                              label={
                                <div className="chip-content">
                                  <div>
                                    <strong>{approver?.firstName + " " + approver?.lastName}</strong>
                                  </div>
                                  <div>
                                    <span>{approver.email}</span>
                                  </div>
                                </div>
                              }
                              deleteIcon={
                                <ActionButton
                                  type={"delete"}
                                  element={approver}
                                  action={() =>
                                    removeSystemApprover(approver.id)
                                  }
                                />
                              }
                              onDelete={() => setAddDeleteApprover(true)}
                            />
                          </Grid>
                        );
                      })}
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <ConfirmationDialog
        open={removeApprover}
        title={t('REMOVE_SYSTEM_APPROVER')}
        handleClose={() => cancelDelete()}
        handleConfirm={() => removeSystemApproverFromList()}
        content={
          t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_APPROVER')
        }
        actionButtonName={t('CONFIRM')}
      ></ConfirmationDialog>
      <BootstrapDialog
        className="modalWrapper"
        style={{ fontFamily: "Poppins" }}
        aria-labelledby="customized-dialog-title"
        open={addNewSystemApprover}
        maxWidth={"sm"}
        fullWidth
      >
        {!isAllUsersApprovers && (
          <DialogTitle>
            {t('NEW_USER_AS_SYSTEM_APPROVER')}
            <IconButton
              aria-label="close"
              onClick={closeModalForSystemApprovers}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        {isAllUsersApprovers && (
          <IconButton
            aria-label="close"
            onClick={closeModalForSystemApprovers}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent dividers>
          {!isAllUsersApprovers && allUsers && allUsers.length > 0 && (
            <Grid className="autocomplete">
              <AutocompleteNotMultipleSelectChip
                options={allUsers.filter(
                  (user) =>
                    !user.userRoles.some(
                      (role) => role.name === Roles.CHANGE_APPROVER
                    )
                )}
                selectedOptions={selectedUser}
                label={"User"}
                required={true}
                showError={addButtonClicked}
                getOptionLabel={(option: any) =>
                  `${option.fullName} (${option.email})`
                }
                handleChangeSelect={handleUserChange}
              />
            </Grid>
          )}
          {isAllUsersApprovers && (
            <Box
              component="div"
              display={"flex"}
              justifyContent={"center"}
              fontSize={"large"}
              color={"gray"}
              className="allUsersApprovers"
            >
              <p>
                <i>{t('ALL_USERS_SYSTEM_APPROVERS')}.</i>
              </p>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            className={"modalCloseButton baseFontFamily"}
            onClick={closeModalForSystemApprovers}
          >
            {t('CLOSE')}
          </Button>
          {!isAllUsersApprovers && (
            <Button
              variant="contained"
              color="inherit"
              className={"modalSuccesButton baseFontFamily"}
              onClick={() => {
                setAddButtonClicked(true);
                addSystemApprover();
              }}
            >
              {t('ADD')}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
export default SystemApprovers;
